import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'
import enUS from 'date-fns/locale/en-US'
import classnames from 'classnames'

import { useLongPress } from '@hooks'

import { useEphemeralStore, useStore } from '@store/useStore'

registerLocale('en-US', enUS)

export const OTimeTravelWrapper = ({
  containerClassName,
  calendarClassName,
  inputClassName,
  onChange = () => {},
  children,
}) => {
  const [componentDate, setComponentDate] = useState(new Date())
  const [showCalendar, setShowCalendar] = useState(false)
  const datePickerRef = useRef()
  const onLongPress = useLongPress()

  const user = useStore(state => state.user)
  const dateNow = useEphemeralStore(state => state.dateNow)
  const updateDateNow = useEphemeralStore(state => state.updateDateNow)
  const updateLastDateNow = useStore(state => state.updateLastDateNow)

  const minDate = useMemo(() => {
    return typeof user?.didJoinOn === 'string'
      ? DateTime.fromISO(user.didJoinOn).toJSDate()
      : DateTime.now().startOf('day').toJSDate()
  }, [])

  const handleLongPress = useCallback(() => {
    setShowCalendar(!showCalendar)
  }, [])

  const handleClick = () => {
    setShowCalendar(!showCalendar)
  }

  const handleOutsideClick = event => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setShowCalendar(false)
    }
  }

  const handleDateChange = useCallback(date => {
    const pickedDateJSDate = DateTime.fromJSDate(date).startOf('day').plus({ seconds: 1 })

    updateDateNow(pickedDateJSDate)
    updateLastDateNow(pickedDateJSDate)

    setComponentDate(pickedDateJSDate)

    setShowCalendar(false)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (dateNow) {
      setComponentDate(dateNow.toJSDate())
    }
  }, [dateNow])

  return (
    <div className="relative" ref={datePickerRef}>
      <div {...onLongPress(handleLongPress)}>{children}</div>

      {showCalendar && (
        <div className="origin-top-right absolute right-0 mt-4 max-w-64 rounded-md  z-50">
          <DatePicker
            selected={componentDate}
            onChange={handleDateChange}
            locale="en-US"
            inline
            calendarClassName={classnames('bg-white rounded-md shadow-lg border', {
              [calendarClassName]: !!calendarClassName,
            })}
            minDate={minDate}
          />
        </div>
      )}
    </div>
  )
}

OTimeTravelWrapper.propTypes = {
  containerClassName: PropTypes.string,
  calendarClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
}
