import React from 'react'
import classnames from 'classnames'

const AIconWrapper = ({ children, className }) => (
  <div className={classnames('flex justify-center items-center p-1 rounded-full', className)}>
    {children}
  </div>
)

export const AIconCompleted = ({ className }) => (
  <AIconWrapper className={className}>
    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0247 0C12.3313 2.61346 9.21816 5.61933 6.43291 8.31876L1.818 4.5275L0 6.59238L5.59383 11.1961L6.58148 12L7.49048 11.1199C10.4716 8.22728 14.0144 4.80956 17 1.91255L15.0247 0Z"
        fill="white"
      />
    </svg>
  </AIconWrapper>
)

export const AIconLocked = ({ className }) => (
  <AIconWrapper className={className}>
    <svg
      className="fill-current"
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.71429 0C3.29713 0 2.14286 1.15427 2.14286 2.57143V4.28571H1.71429C0.771986 4.28571 0 5.0577 0 6V10.2857C0 11.228 0.771986 12 1.71429 12H7.71429C8.65659 12 9.42857 11.228 9.42857 10.2857V6C9.42857 5.0577 8.65659 4.28571 7.71429 4.28571H7.28571V2.57143C7.28571 1.15427 6.13144 0 4.71429 0ZM4.71429 0.857143C5.67141 0.857143 6.42857 1.6143 6.42857 2.57143V4.28571H3V2.57143C3 1.6143 3.75716 0.857143 4.71429 0.857143ZM1.71429 5.14286H7.71429C8.19656 5.14286 8.57143 5.51773 8.57143 6V10.2857C8.57143 10.768 8.19656 11.1429 7.71429 11.1429H1.71429C1.23202 11.1429 0.857143 10.768 0.857143 10.2857V6C0.857143 5.51773 1.23202 5.14286 1.71429 5.14286Z"
        fill="current"
      />
      <path
        d="M4.71443 6.42859C4.00942 6.42859 3.42871 7.0093 3.42871 7.7143C3.42871 8.26946 3.78855 8.74737 4.28585 8.92552V9.85716C4.28585 9.97082 4.33101 10.0798 4.41138 10.1602C4.49175 10.2406 4.60076 10.2857 4.71443 10.2857C4.82809 10.2857 4.9371 10.2406 5.01747 10.1602C5.09784 10.0798 5.143 9.97082 5.143 9.85716V8.92552C5.64031 8.74737 6.00014 8.26946 6.00014 7.7143C6.00014 7.0093 5.41943 6.42859 4.71443 6.42859ZM4.71443 7.28573C4.95619 7.28573 5.143 7.47253 5.143 7.7143C5.143 7.95607 4.95619 8.14287 4.71443 8.14287C4.47266 8.14287 4.28585 7.95607 4.28585 7.7143C4.28585 7.47253 4.47266 7.28573 4.71443 7.28573Z"
        fill="current"
      />
    </svg>
  </AIconWrapper>
)
