export const MChatAudioBtn = ({ playbackState, playbackTimeToFinish, onClick = () => {} }) => (
  <button
    onClick={onClick}
    className="w-10 h-10 flex justify-center items-center bg-green rounded-full focus:ring-offset-1 focus:outline-none focus:ring-1 focus:ring-green-dark cursor-pointer"
  >
    {playbackState === 'buffering' && (
      <svg
        className="animate-spin h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="#2A395A"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    )}
    {(playbackState === 'ready' || playbackState === 'pause') && (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="#2A395A"
        stroke="#2A395A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polygon points="5 3 19 12 5 21 5 3"></polygon>
      </svg>
    )}
    {playbackState === 'playing' && (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="#2A395A"
        stroke="#2A395A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x="6" y="4" width="4" height="16"></rect>
        <rect x="14" y="4" width="4" height="16"></rect>
      </svg>
    )}
  </button>
)
