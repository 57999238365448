import { useCallback, useMemo, useState, useEffect } from 'react'
import { useGoalProvider } from '@edwin/react-sdk'
import { DateTime } from 'luxon'

import { Edit } from 'react-feather'
import { AButton } from '../atoms/AButton'

export const MChatDisplayGoal = ({ goalId }) => {
  const [isEditable, setIsEditable] = useState(true)
  const [isEditing, setIsEditing] = useState(false)

  const { getGoal = () => {}, updateGoal = () => {} } = useGoalProvider()

  const goal = useMemo(() => {
    return getGoal(goalId)
  }, [getGoal, goalId])

  const [editingData, setEditingData] = useState({
    prompt: '',
    description: '',
  })

  const disableSave = useMemo(() => {
    return editingData?.prompt === goal?.prompt && editingData?.description === goal?.description
  }, [editingData?.description, editingData?.prompt, goal?.description, goal?.prompt])

  const clearEditingData = useCallback(() => {
    setEditingData({
      prompt: '',
      description: '',
    })
  }, [])

  const handleEditingChanges = useCallback(() => {
    const changes = {
      id: goal?.id,
      ...editingData,
    }
    updateGoal(goal?.id, editingData, true, { payload: changes })

    setIsEditing(false)
    clearEditingData()
  }, [clearEditingData, editingData, goal?.id, updateGoal])

  const handleCancelEditing = useCallback(() => {
    clearEditingData()
    setIsEditing(false)
  }, [clearEditingData])

  return (
    <div className="py-3 px-1.5 min-w-[200px]">
      {!!goal ? (
        <>
          <div className="flex items-center justify-between">
            <span className="py-1 px-2 text-xs text-codGray font-semibold bg-green rounded">
              Your goal
            </span>
            {isEditable && !isEditing && (
              <div
                className="cursor-pointer mr-1"
                onClick={() => {
                  setIsEditing(true)
                  setEditingData({
                    prompt: goal?.prompt || goal.title,
                    description: goal?.description,
                  })
                }}
              >
                <Edit size={16} />
              </div>
            )}
          </div>
          {!isEditing ? (
            <div className="px-2">
              {(goal?.prompt || goal?.title) && (
                <>
                  <h4 className="mt-2 text-[18px] leading-[24px]">{goal?.prompt || goal.title}</h4>
                  <div className="w-12 h-1 mt-1.5 mb-2.5 bg-codGray" />
                </>
              )}
              {goal?.description && <p className="mb-1 text-sm">{goal.description}</p>}
              <div className="mb-2">
                {goal?.startsOn && (
                  <p className="text-sm">
                    <span className="font-medium">Starts on</span>:{' '}
                    {DateTime.fromMillis(goal.startsOn).toFormat('MMMM dd, yyyy')}
                  </p>
                )}
                {goal?.endsOn && (
                  <p className="text-sm">
                    <span className="font-medium">Ends on</span>:{' '}
                    {DateTime.fromMillis(goal.endsOn).toFormat('MMMM dd, yyyy')}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className="px-2">
              <div className="max-w-72">
                <span className="px-1 text-xs font-medium text-gray-700">Name</span>
                <input
                  type="text"
                  className="w-full h-8 px-2 text-gray-600 border border-gray-400 rounded"
                  defaultValue={goal?.prompt || goal.title}
                  onChange={e => {
                    setEditingData({ ...editingData, prompt: e.target.value })
                  }}
                />
                <span className="px-1 text-xs font-medium text-gray-700">Description</span>
                <textarea
                  className="w-full h-16 py-1 px-2 text-gray-600 border border-gray-400 rounded"
                  defaultValue={goal?.description}
                  onChange={e => {
                    setEditingData({ ...editingData, description: e.target.value })
                  }}
                />
                <div className="opacity-60">
                  <span className="px-1 text-xs font-medium text-gray-700">Starts on</span>
                  <input
                    type="date"
                    disabled
                    className="w-full h-8 px-2 text-gray-600 border border-gray-400 rounded cursor-default"
                    defaultValue={DateTime.fromMillis(goal.startsOn).toFormat('yyyy-MM-dd')}
                  />
                  <span className="px-1 text-xs font-medium text-gray-700">Ends on</span>
                  <input
                    type="date"
                    disabled
                    className="w-full h-8 px-2 text-gray-600 border border-gray-400 rounded cursor-default"
                    defaultValue={DateTime.fromMillis(goal.endsOn).toFormat('yyyy-MM-dd')}
                  />
                </div>
              </div>

              <div className="flex mt-4 gap-x-1.5">
                <AButton small dontRound outline onClick={handleCancelEditing}>
                  Cancel
                </AButton>
                <AButton small dontRound onClick={handleEditingChanges} disabled={disableSave}>
                  Confirm
                </AButton>
              </div>
            </div>
          )}
        </>
      ) : (
        <div>Goal not found</div>
      )}
    </div>
  )
}
