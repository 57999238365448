import { useCallback, useMemo, useState } from 'react'
import classnames from 'classnames'

import { useTranslations } from '@hooks'

import ReactMarkdown from 'react-markdown'
import { MChatMenuItem } from '@molecules/MChatMenuItem'
import { AIconOpenSideMenu } from '@atoms/AIcons'
import { MFadeIn } from '@molecules/MFadeIn'

export const MThreadsList = ({
  threads,
  currentThreadId,
  onThreadOpen = () => {},
  onNewChat = () => {},
  onDeleteThread = () => {},
  deletingThread = null,
}) => {
  const { t } = useTranslations()
  const [activeFilter, setActiveFilter] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const filters = useMemo(() => {
    return [
      {
        name: 'All',
        emptyState: t('coach.threads.emptyState.all'),
        filterMethod: () => true,
      },
      {
        name: 'Threads',
        emptyState: t('coach.threads.emptyState.threads'),
        filterMethod: ({ type, ...rest }) => type === 'assistant',
      },
      {
        name: 'Insights',
        emptyState: t('coach.threads.emptyState.insights'),
        filterMethod: ({ type }) => type === 'insights',
      },
    ]
  }, [t])

  const filteredThreads = useMemo(() => {
    return threads.filter(filters[activeFilter].filterMethod)
  }, [activeFilter, filters, threads])

  const handleThreadOpen = useCallback(
    threadId => {
      onThreadOpen(threadId)
      setIsMenuOpen(false)
    },
    [onThreadOpen]
  )

  return (
    <>
      <div className="md:hidden absolute w-full h-full">
        <MFadeIn
          visible={isMenuOpen}
          className="w-full h-full"
          childClassName="w-full h-full"
          translateValue={0}
        >
          <div
            className="w-full h-full bg-codGray/60"
            onClick={() => {
              setIsMenuOpen(false)
            }}
          />
        </MFadeIn>
      </div>
      <div
        className={classnames(
          'absolute md:static md:translate-x-0 w-[90%] h-full z-10 transition-transform duration-500 ease-in-out',
          'flex-shrink-0 md:w-72 lg:w-96',
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        )}
      >
        <div
          className="md:hidden absolute -top-px -right-9 w-12 h-12 rounded-2xl bg-gray-50"
          onClick={() => {
            setIsMenuOpen(!isMenuOpen)
          }}
        >
          <AIconOpenSideMenu isOpen={isMenuOpen} className="p-3" />
        </div>
        <div className="relative flex flex-col h-full p-3 lg:p-6 bg-gray-50">
          <div className="flex items-center">
            <div className="text-xl font-semibold">{t('coach.threads.heading')}</div>
          </div>
          <div className="mt-5">
            <ul className="flex flex-row items-center gap-x-4">
              {filters.map((item, index) => (
                <li key={index}>
                  <div
                    onClick={() => setActiveFilter(index)}
                    className={classnames(
                      'flex items-center pb-2 text-xs font-semibold relative cursor-pointer transition-colors',
                      activeFilter === index ? 'text-green-dark' : 'text-gray-500'
                    )}
                  >
                    <span>{item.name}</span>
                    <span
                      className={classnames(
                        'absolute inset-x-0 bottom-0 h-0.5 w-full bg-green-dark rounded-full transition-opacity',
                        activeFilter === index ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="h-full mt-5 -mx-3.5 lg:-mx-6 overflow-hidden">
            <div className="flex flex-col h-full px-2 lg:px-6 overflow-y-auto">
              {filteredThreads.length ? (
                filteredThreads.map(thread => (
                  <MChatMenuItem
                    key={thread.id}
                    thread={thread}
                    currentThreadId={currentThreadId}
                    onItemClick={handleThreadOpen}
                    deleteThread={onDeleteThread}
                    deletingThread={deletingThread}
                  />
                ))
              ) : (
                <ReactMarkdown className="mt-12 text-center text-sm">
                  {filters[activeFilter].emptyState}
                </ReactMarkdown>
              )}
            </div>
          </div>
          {/*{currentThreadId ? (*/}
          <div className="mt-auto pt-2 flex justify-end border-t border-gray-200">
            <button
              onClick={onNewChat}
              className="flex items-center justify-center h-10 w-10 text-codGray bg-green shadow-sm rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
            </button>
          </div>
          {/*) : null}*/}
        </div>
      </div>
    </>
  )
}
