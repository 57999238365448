import React, { useContext, createContext } from 'react'

export const AuthorContext = createContext({})
AuthorContext.displayName = 'AuthorContext'

export const AuthorProvider = ({
  children,
  author = {
    name: '',
    avatarUrl: '',
    avatarMeta: {
      crop: null,
      hotspot: null,
    },
  },
}) => {
  const generateInitials = () => {
    const splitName = author?.name?.split(' ')

    return splitName.map(name => name[0]).join('')
  }

  return (
    <AuthorContext.Provider
      value={{
        author: author
          ? {
              ...author,
              initials: generateInitials(),
            }
          : author,
      }}
    >
      {children}
    </AuthorContext.Provider>
  )
}

export const useAuthor = () => {
  const context = useContext(AuthorContext)

  if (context === undefined) {
    throw new Error('useAuthor must be nested in AuthorProvider')
  }

  return context
}
