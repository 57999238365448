import { useCallback, useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'

import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collection, doc, query, setDoc, where } from 'firebase/firestore'
import {
  auth,
  COLLECTIONS,
  DangerouslyCreateCheckupConversationFBFunction,
  firestoreDb,
  generateUserCheckupConversationFBFunction,
} from '@services/Firebase'

import { useEphemeralStore } from '@store/useStore'
import useAIHomeStore from '@store/useAIHomeStore'

export const generateUserCheckupConversation = async () => {
  return await generateUserCheckupConversationFBFunction()
}

export const dangerouslyCreateCheckupConversation = async () => {
  return await DangerouslyCreateCheckupConversationFBFunction()
}

export const useCatchupConversations = () => {
  const [conversationsRaw, loading, error] = useCollectionData(
    query(
      collection(firestoreDb, COLLECTIONS.checkupConversations),
      where('userId', '==', auth?.currentUser?.uid || null),
      where('isCompleted', '==', false)
    )
  )

  const dateNow = useEphemeralStore(state => state.dateNow)

  const setCatchupMission = useAIHomeStore(state => state.setCatchupMission)
  const catchupMission = useAIHomeStore(state => state.catchupMission)

  const todayConversations = useMemo(() => {
    if (!conversationsRaw?.length) {
      return []
    }

    return conversationsRaw
      .filter(({ isLoading = false }) => !isLoading)
      .filter(({ isCompleted = false }) => !isCompleted)
      .filter(({ startDate, endDate }) => {
        if (!startDate || !endDate || !dateNow) {
          return true
        }

        const fromDateTime = dateNow.startOf('day').plus({ seconds: 1 })
        const toDateTime = dateNow.endOf('day')
        const fromDateTimeMillis = fromDateTime.toMillis()
        const toDateTimeMillis = toDateTime.toMillis()

        const startsOnMillis = startDate.seconds * 1000
        const endsOnMillis = endDate.seconds * 1000

        const shouldIncludeByStartsOn = startsOnMillis <= toDateTimeMillis
        const shouldIncludeByEndsOn = endsOnMillis >= fromDateTimeMillis

        return shouldIncludeByStartsOn && shouldIncludeByEndsOn
      })
  }, [conversationsRaw, dateNow])

  const currentCatchupConversation = useMemo(() => {
    if (!todayConversations.length) {
      return null
    }

    return todayConversations[0]
  }, [todayConversations])

  useEffect(() => {
    if (currentCatchupConversation && !catchupMission) {
      setCatchupMission({
        id: currentCatchupConversation?.id || 'catchup',
        title: 'Catchup mission',
        messages: currentCatchupConversation?.messages || [],
      })
    }

    if (!currentCatchupConversation && catchupMission) {
      setCatchupMission(null)
    }
  }, [catchupMission, currentCatchupConversation, setCatchupMission, todayConversations])
}

export const useCatchupConversationCompleted = () => {
  const catchupMission = useAIHomeStore(state => state.catchupMission)

  const markCatchupConversationAsCompleted = useCallback(async () => {
    if (!catchupMission) {
      return
    }

    const { id } = catchupMission

    if (!id || id === 'catchup') {
      return
    }

    const updateDate = new Date()
    const dataToSave = {
      isCompleted: true,
      completedAt: updateDate,
      updatedAt: updateDate,
    }

    const firebaseAssistantThreadRef = doc(firestoreDb, COLLECTIONS.checkupConversations, id)
    await setDoc(firebaseAssistantThreadRef, dataToSave, { merge: true })
  }, [catchupMission])

  return {
    markCatchupConversationAsCompleted,
  }
}
