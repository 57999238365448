import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export const useAssistantContextStore = create(
  persist(
    (set, get) => ({
      assistantContext: {},

      setAssistantContext: value => {
        set({ assistantContext: value })
      },
      addAssistantContext: value => {
        set({ assistantContext: { ...get().assistantContext, ...value } })
      },
      clearAssistantContext: () => {
        set({ assistantContext: {} })
      },

      clearLocalStorage: () => {
        // default values
        set({
          assistantContext: {},
        })
      },
    }),
    {
      name: 'assistant-context-storage',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

export default useAssistantContextStore
