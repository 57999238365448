import React, { useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES, generateRoute } from '@const/Routes'

import useProgressStore from '@store/useProgressStore'

import { useTranslations, useScreenSize } from '@hooks'

import { AHeading } from '@atoms/AHeading'
import { MProgramTopic } from '@molecules/MProgramTopic'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { OProgressSkeleton } from '@organisms/OProgressSkeleton'

export const PProgress = ({ isLoading }) => {
  const { t, translateData } = useTranslations()
  const navigate = useNavigate()

  const { width } = useScreenSize()

  const topics = useProgressStore(state => state.userContent?.topics)
  const getTopicProgress = useProgressStore(state => state.getTopicProgress)
  const userProgress = useProgressStore(state => state.userProgress)

  const progressBarRef = useRef(null)

  // const { availableTopics, currentProgramDay } = useProgramTimeline()
  const availableTopics = useMemo(() => {
    return topics?.map(topic => {
      const translatedTopic = translateData(topic, userProgress)
      const topicProgress = getTopicProgress(topic.id)

      return {
        ...translatedTopic,
        ...topicProgress,
      }
    })
  }, [getTopicProgress, topics, userProgress, translateData])

  const handleClick = useCallback(
    topicId => {
      navigate(generateRoute(ROUTES.PROGRAM_TOPIC, { topicId }))
    },
    [navigate]
  )

  const calcProgressBar = useCallback(
    missions => {
      const topicLength = missions.length
      const completedMissionsLength = missions.filter(({ isCompleted }) => isCompleted).length
      const barWidth = (width && progressBarRef?.current?.offsetWidth) || 0

      return Math.ceil((barWidth / topicLength) * completedMissionsLength)
    },
    [width]
  )

  if (isLoading) {
    return (
      <OPageWrapper>
        <OProgressSkeleton />
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper>
      <AHeading>{t('progressHeading')}</AHeading>
      <div className="max-w-sm md:max-w-3xl lg:max-w-none grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-3 md:gap-4 lg:gap-y-8 lg:gap-x-5 mt-4 mb-6">
        {availableTopics?.map(topic => (
          <MProgramTopic key={topic.id} topic={topic} />
        ))}
      </div>
    </OPageWrapper>
  )
}
