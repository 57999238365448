import { MAIViewContainer } from '@molecules/MAIViewContainer'
import { AButton } from '@atoms/AButton'

export const MAIViewGoalSuggestion = ({ meta }) => {
  console.log('MAIViewGoalSuggestion', meta)

  return (
    <MAIViewContainer {...meta}>
      <div className="flex gap-x-4">
        {meta.suggestions.map(suggestion => (
          <div
            key={suggestion.id}
            className="flex flex-col justify-between w-60 p-4 bg-gray-50 rounded-lg shadow-md"
          >
            <div>
              <h3 className="mb-2 text-md font-medium">{suggestion.title}</h3>
              <p className="mb-4 text-sm text-gray-600">{suggestion.description}</p>
            </div>
            <div className="flex justify-end">
              <AButton small outline className="">
                Add goal
              </AButton>
            </div>
          </div>
        ))}
      </div>
    </MAIViewContainer>
  )
}
