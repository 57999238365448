import React, { useState, useCallback } from 'react'
import { useEffectOnce } from './useEffectOnce'

export const useMessages = ({ msg, scrollChatBottom = () => {}, customTypingMethods = [] }) => {
  const [isTyping, setIsTyping] = useState(msg.type !== 'user-options')
  const [message, setMessage] = useState(msg)

  const handleLoadedMessage = useCallback(() => {
    setIsTyping(false)
    setTimeout(scrollChatBottom, 50)

    if (msg.onRender) {
      msg.onRender()
    }
  }, [])

  const handleTypingMethod = useCallback(() => {
    const customMethod = customTypingMethods.find(
      method => method.componentName === msg.componentName
    )

    if (customMethod) {
      return customMethod.handler(msg, handleLoadedMessage, setMessage)
    }

    let delay = 450

    if (message.type === 'bot') {
      if (message.msg?.length > 32) {
        delay = 350 * 3
      }

      if (message.msg?.length > 120) delay = 350 * 5

      if (message.msg?.length > 180) delay = 350 * 7
    }

    if (message.type === 'bot-component') {
      delay = 350 * 4
    }

    setTimeout(handleLoadedMessage, delay)
  }, [])

  useEffectOnce(() => {
    if (isTyping) {
      handleTypingMethod()
    }
  })

  return [message, isTyping]
}
