import { create } from 'zustand'

import { CometChat } from '@cometchat-pro/chat'

let chatStore = null

const COMETCHAT_LISTENER_ID = 'UNIQUE_LISTENER_ID'

export const createChatStore = () => {
  if (chatStore) return chatStore

  chatStore = create((set, get) => ({
    isChatInitialized: false,
    chatUnreadMessageCount: 0,

    refreshChatUnreadMessageCount: async () => {
      try {
        const messagesCount = await CometChat.getUnreadMessageCount()

        const { groups = {}, users = {} } = messagesCount

        const value = [...Object.values(groups), ...Object.values(users)].reduce((acc, curr) => {
          return acc + curr
        }, 0)

        set({ chatUnreadMessageCount: value })
      } catch (err) {
        console.log(err)
      }
    },
    subscribeUnreadMessageCount: async () => {
      await get().refreshChatUnreadMessageCount()

      CometChat.addMessageListener(
        COMETCHAT_LISTENER_ID,
        new CometChat.MessageListener({
          onTextMessageReceived: textMessage => {
            get().refreshChatUnreadMessageCount()
          },
          onMediaMessageReceived: mediaMessage => {
            get().refreshChatUnreadMessageCount()
          },
          onCustomMessageReceived: customMessage => {
            get().refreshChatUnreadMessageCount()
          },
        })
      )
    },
    unsubscribeUnreadMessageCount: async () => {
      CometChat.removeMessageListener(COMETCHAT_LISTENER_ID)
    },
    initializeChat: async chatCredentials => {
      try {
        const { appId, region, authKey, uid } = chatCredentials

        const appSetting = new CometChat.AppSettingsBuilder()
          .subscribePresenceForAllUsers()
          .setRegion(region)
          .autoEstablishSocketConnection(false)
          .build()

        await CometChat.init(appId, appSetting)

        await CometChat.login(uid, authKey)

        await CometChat.connect()

        set({ isChatInitialized: true })
      } catch (err) {
        console.error(err)
      }
    },

    clearChatStorage: async () => {
      const isChatInitialized = get().isChatInitialized

      if (isChatInitialized) {
        await CometChat.logout()
        await CometChat.disconnect()
        get().unsubscribeUnreadMessageCount()
      }

      set({ dateNow: null, isChatInitialized: false, chatUnreadMessageCount: 0 })
    },
  }))

  return chatStore
}

export default createChatStore
