const preloadImage = async src => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => resolve(img)
    img.onerror = reject
  })
}

const fetchVimeoPadding = async videoSrc => {
  return await fetch(`https://vimeo.com/api/oembed.json?url=${videoSrc}`)
}

const getYoutubeId = url => {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

const handlerVideo = (msg, endFunction, setMessage) => {
  const videoSrc = msg.componentOptions.src
  // @toDo: videoSrc.match(/https:\/\/(:?www.)?(\w*)/)?.[2] || 'unknown'
  const videoType = videoSrc.match(/https:\/\/(:?www.)?(\w*)/)
    ? videoSrc.match(/https:\/\/(:?www.)?(\w*)/)[2]
    : 'unknown'
  const isVimeo = videoType === 'vimeo'
  const isYoutube = videoType === 'youtube' || videoType === 'youtu'

  const msgWithNewComponentOptions = newOptions => {
    const oldMsg = msg

    return {
      ...oldMsg,
      componentOptions: {
        ...oldMsg.componentOptions,
        ...newOptions,
      },
    }
  }

  if (isVimeo) {
    fetchVimeoPadding(videoSrc)
      .then(response => response.json())
      .then(({ height, width }) => {
        const videoPadding = Math.round((height / width) * 100) / 1
        const msgWithPadding = msgWithNewComponentOptions({
          padding: videoPadding,
          type: 'vimeo',
        })

        setMessage(msgWithPadding)
      })
      .catch(error => {
        console.log('fetch video dimensions error: ', error)
      })
      .finally(() => {
        endFunction()
      })
  }

  if (isYoutube) {
    const videoId = getYoutubeId(videoSrc)

    setMessage(
      msgWithNewComponentOptions({
        src: `https://www.youtube.com/embed/${videoId}`,
        type: 'youtube',
      })
    )

    endFunction()
  }

  if (!isVimeo && !isYoutube) {
    setMessage(msgWithNewComponentOptions({ type: 'unknown' }))

    endFunction()
  }
}

const TYPES = ['illustrationFileName', 'illustrationUrl', 'gifs', 'src', 'coverImage']

const handlerImage = (msg, endFunction = () => {}) => {
  const imageKey = Object.keys(msg.componentOptions).find(key => TYPES.includes(key))
  const imageProp = msg.componentOptions[imageKey]
  const imageUri = Array.isArray(imageProp) ? imageProp[0] : imageProp

  if (!imageProp) {
    return endFunction()
  }

  preloadImage(imageUri).then(endFunction)
}

export const customTypingMethods = [
  {
    componentName: 'video',
    handler: handlerVideo,
  },
  {
    componentName: 'infographic-illustration',
    handler: handlerImage,
  },
  {
    componentName: 'interactive-file-download-print',
    handler: handlerImage,
  },
  {
    componentName: 'gif',
    handler: handlerImage,
  },
  {
    componentName: 'image',
    handler: handlerImage,
  },
  {
    componentName: 'audio',
    handler: handlerImage,
  },
]
