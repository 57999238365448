import PropTypes from 'prop-types'
import classNames from 'classnames'

const COLOR_DARK = '#e5e7eb' // gray-300
const COLOR_LIGHT = '#f3f4f6' // gray-200

export const MSkeleton = ({ width, height, rounded = 'lg' }) => {
  const styles = {
    height,
    width,
    background: `linear-gradient(90deg, ${COLOR_DARK}, ${COLOR_DARK}, ${COLOR_LIGHT}, ${COLOR_LIGHT}, ${COLOR_DARK})`,
    backgroundSize: `${width * 2}px ${height}px`,
  }

  return (
    <div
      className={classNames(`bg-gray-200 overflow-hidden rounded-${rounded} animate-skeleton`)}
      style={styles}
    />
  )
}

MSkeleton.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  rounded: PropTypes.string,
}

MSkeleton.defaultProps = {
  rounded: 'lg',
}
