export const translations = {
  en: {
    translation: {
      logo: 'Edwin Sandbox',
      headings: {
        goals: 'Your goals for today',
        tips: "Don't forget",
      },
      topicHeading: 'Continue on {{topicName}}',
      progressHeading: 'Program Progress',
      leaderboardHeading: '{{teamName}} Leaderboard',
      points: '{{number}} points',
      navigation: {
        today: 'Today',
        program: 'Program',
        ai: 'AI',
        messages: 'Coach',
        account: 'Account',
      },
      screen: {
        today: 'Today',
        progress: 'Program progress',
        leaderboard: 'Leaderboard',
        messages: 'Coach',
        conversation: 'Thread',
        account: 'Your Account',
        gettingStarted: 'Getting started',
      },
      label: {
        name: 'Name',
        email: 'Email',
        phoneNumber: 'Your phone number',
        password: 'Password',
        language: 'Language',
        points: 'My points',
        totalPoints: 'My total points',
        weeks_one: 'Week',
        weeks_other: 'Weeks',
        assignments_one: 'Assignment',
        assignments_other: 'Assignments',
        notifications: 'Allow Notifications',
        account: 'Your Account',
        joined: 'Joined {{date}}',
      },
      placeholder: {
        tracker: {
          text: 'Enter a text',
          number: 'Enter a number',
        },
      },
      required: {
        email: 'Email is required',
        password: 'Password is required',
        phoneNumber: 'Phone number is required',
        smsCode: 'Sms code is required',
      },
      error: {
        error: 'Error',
        signIn: 'Failed to sign in. Please check your email and password, and try again.',
        email: "We can't find that email. Please double-check.",
        signInPhone: 'Failed to sign in. Please check your phone number, and try again.',
        phoneNumberFormat: 'Invalid phone number',
        phoneNumberDoesNotExists: 'The account with this phone number does not exists',
        noData: 'No data',
        somethingWrong: 'Something went wrong. Please, try again',
        internetConnection: 'There is no internet connection',
      },
      chat: {
        with: 'with ',
      },
      mission: {
        author: 'with ',
        steps: "What we'll cover",
      },
      modal: {
        congratulations: 'Congratulations!',
        bonusPoints: '{{points}} Bonus points!',
        completedTopic: 'You have completed {{topicName}}.',
        resetPassword: 'Reset your password',
        resetPasswordInstruction:
          'To reset your password press the button below and check you email inbox for instructions from us on how to reset your password',
        resetPasswordDescription:
          'Enter your email and we will send you a link to reset your password.',
        passwordWasSent: 'Password reset link was sent',
        enterEmail: 'Enter your email and we will send you a link to reset your password',
        confirmResetPassword1: 'It should be in your inbox shortly.',
        confirmResetPassword2: "If you can't find it, check your SPAM folder.",
        areYouSure: 'Are you sure?',
        startFromScratch: 'You will need to start this assignment from scratch',
        signOut: 'Are you sure you want to sign out?',
        notifications:
          'To {{isNotificationsEnabled}} Push Notifications go to Settings and {{isNotificationsEnabled}} notifications for Edwin Sandbox.',
        pushNotifications: 'Push Notifications',
      },
      summaryComponent: {
        congrats: 'Well Done!',
        description:
          'Tracking your personal care products is a powerful way to understand what you use and how it may impact your health.',
        completed: 'You have completed {{name}}.',
        ctaText: 'Finish',
      },
      missionStartsOn: 'Starts {{startsOn}}',
      assignments: 'Assignments',
      welcome: 'Welcome!',
      getStarted: 'Sign in and get started with Edwin Sandbox.',
      yourAccount: 'Your Account',
      signIn: 'Sign in',
      signOut: 'Sign Out',
      resetPassword: 'Reset Password',
      resetLink: 'Get reset link',
      signInWithEmailAndPassword: 'Sign in with email and password',
      signInWithPhoneNumber: 'Sign in with phone number',
      smsCodeReceived: 'Code received via SMS',
      generateNewSmsCode: 'Generate new SMS code',
      generateVerificationCode: 'Generate verification code',
      retry: 'Retry',
      submit: 'Submit',
      confirm: 'Confirm',
      cancel: 'Cancel',
      openSettings: 'Open Settings App',
      version: 'Version: {{appVersion}}',
      screenReaders: {
        openMainMenu: 'Open main menu',
        openUserMenu: 'Open user menu',
        closePanel: 'Close panel',
      },
      tips: {
        revisitMission: 'Revisit Mission',
      },
      coach: {
        threads: {
          heading: 'Threads',
          emptyState: {
            all: 'There are no threads and insights yet.\n\nCreate one!',
            threads: 'There are no threads yet.\n\nCreate one!',
            insights: 'There are no insights yet.',
          },
        },
        thread: {
          title: 'What do you want to talk about?',
          description:
            'You can ask me about your progress, your goals, appointments or to dive deeper into topics from finished missions.',
        },
        message: {
          weeklyInsights: 'Weekly insights',
        },
        label: {
          placeholder: 'Talk to me....',
          loading: 'Thinking...',
        },
      },
    },
  },
}
