/**
 * OpenAI Service Module
 * @module OpenAi
 */
import OpenAI from 'openai'
import { firestoreDb, COLLECTIONS } from '@services/Firebase'
import { doc, getDoc } from 'firebase/firestore'

let openai = null

const initializeOpenAi = async () => {
  if (!openai) {
    const openAiCredentialsRef = await doc(firestoreDb, COLLECTIONS.openai, 'credentials')
    const openAiCredentialsDoc = await getDoc(openAiCredentialsRef)
    const openAiCredentialsData = openAiCredentialsDoc.data()

    if (
      !openAiCredentialsDoc.exists() ||
      !openAiCredentialsData ||
      !openAiCredentialsData.apiKey ||
      !openAiCredentialsData.organization
    ) {
      throw new Error('No OpenAI credentials found')
    }

    openai = new OpenAI({
      apiKey: openAiCredentialsData.apiKey,
      organization: openAiCredentialsData.organization,
      dangerouslyAllowBrowser: true,
    })
  }

  return openai
}

export { openai, initializeOpenAi }
