import React, { useRef, useCallback } from 'react'
import classNames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import ReactMarkdown from 'react-markdown'
import _ from 'lodash'

export const MChatUserOption = ({
  label,
  isChosen = false,
  chosenOption,
  index = 0,
  onClick = () => {},
}) => {
  const { user, assistant } = useUser()

  const buttonRef = useRef(null)

  const handleClick = useCallback(() => {
    buttonRef.current.blur() // removing focus
    onClick()
  }, [onClick])

  return (
    <button
      ref={buttonRef}
      onClick={handleClick}
      tabIndex={chosenOption === index || isChosen ? -1 : 0}
      className={classNames(
        'py-3 px-3.5 text-base text-left leading-[20px] border rounded-[10px] transition cursor-pointer',
        'focus:outline-none focus:ring-1 focus:ring-green-dark focus:ring-offset-1 focus:text-white focus:bg-green-dark focus:border-white',
        chosenOption === null && !isChosen
          ? 'mb-2.5 last:mb-0 text-codGray bg-transparent border-codGray hover:text-gray-50 hover:bg-green-dark'
          : chosenOption === index || isChosen
          ? 'text-white bg-green-dark border-green-dark animate-border-radius cursor-auto pointer-events-none'
          : 'hidden'
      )}
    >
      <ReactMarkdown>{_.template(label)({ user, assistant, input: assistant })}</ReactMarkdown>
    </button>
  )
}
