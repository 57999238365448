import { create } from 'zustand'
import _ from 'lodash'
import { persist, createJSONStorage } from 'zustand/middleware'
import { addDoc, collection } from 'firebase/firestore'
import { nanoid } from 'nanoid/non-secure'

import { firestoreDb, COLLECTIONS } from '@services/Firebase'

export const useQuestionnaireStore = create(
  persist(
    (set, get) => ({
      requestsQueue: [],
      isQueueProcessing: false,

      /**
       *
       * @param {*} task
       */
      addQueueTask: task => {
        const randomId = nanoid()
        const modifiedTask = {
          ...task,
          id: randomId,
        }
        const queue = [...get().requestsQueue, modifiedTask]

        set({ requestsQueue: queue })
      },
      /**
       *
       * @param {*} taskId
       */
      removeTaskFromQueue: taskId => {
        const tasks = [...get().requestsQueue]
        const modifiedTasks = _.remove(tasks, ({ id }) => id !== taskId)

        set({ requestsQueue: modifiedTasks })
      },
      removeAllTasksFromQueue: () => {
        set({ requestsQueue: [] })
      },
      /**
       *
       * @returns
       */
      processQueue: async () => {
        const isQueueProcessing = get().isQueueProcessing
        const tasks = get().requestsQueue

        if (isQueueProcessing) {
          return
        }

        set({ isQueueProcessing: true })

        for (const task of tasks) {
          try {
            const questionnaireRef = collection(firestoreDb, COLLECTIONS.userQuestionnaires)

            await addDoc(questionnaireRef, task.payload)

            get().removeTaskFromQueue(task.id)
          } catch (err) {
            console.error('QuestionnaireQueue processing error', err)
          }
        }

        set({ isQueueProcessing: false })
      },

      clearQuestionnaireStorage: () => {
        // default values
        set({
          requestsQueue: [],
          isQueueProcessing: false,
        })
      },
    }),
    {
      name: 'app-storage',
      storage: createJSONStorage(() => window.localStorage),
    }
  )
)

export default useQuestionnaireStore
