import { useMemo } from 'react'

import useProgressStore from '@store/useProgressStore'

import { useTranslations } from '@hooks'

export const useActiveTopics = () => {
  const { translateData } = useTranslations()

  const topics = useProgressStore(state => state.userContent?.topics)
  const getTopicProgress = useProgressStore(state => state.getTopicProgress)
  const getMission = useProgressStore(state => state.getMission)
  const userProgress = useProgressStore(state => state.userProgress)

  const activeTopicsWithMissions = useMemo(() => {
    const topicsWithProgressAndMissions = topics?.map(topic => {
      const translatedTopic = translateData(topic)
      const topicProgress = getTopicProgress(topic.id)

      const missionsWithProgress = topic.missionsAndSeries.map(mission => {
        const missionData = getMission(mission.id)
        const translatedMission = translateData(missionData)
        const missionProgressFromTopic = topicProgress?.missionsAndSeries.find(
          ({ id }) => id === mission.id
        )

        return {
          topicId: topic.id,
          ...translatedMission,
          ...missionProgressFromTopic,
        }
      })

      return {
        ...translatedTopic,
        ...topicProgress,
        missionsAndSeries: missionsWithProgress,
      }
    })

    const activeTopics = topicsWithProgressAndMissions
      ?.filter(topic => topic.isStarted && !topic.isCompleted)
      .map(topic => {
        const { missionsAndSeries } = topic
        const filteredMissions = missionsAndSeries.filter(
          ({ isCompleted, isCurrent }) => isCompleted || isCurrent
        )

        return { ...topic, missionsAndSeries: filteredMissions }
      })

    return activeTopics
  }, [getMission, getTopicProgress, topics, userProgress, translateData])

  return activeTopicsWithMissions
}
