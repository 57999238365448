import axios from 'axios'
import {
  CONTENT_ENDPOINTS,
  PROGRESS_ENDPOINTS,
  GOALS_ENDPOINTS,
  TIPS_ENDPOINTS,
} from './const/endpoints'

const API_URL_DEFAULT = 'https://api.edwin.cloud/v1'

class EdwinCloudApiClient {
  /**
   * Initialize the API client.
   * @param {String} token- Access token used for authentication.
   * @param {Object} config - Configuration options for the API client.
   * @param {Number} config.timeout - axios timeout
   * @param {String} [config.apiURL] - The base URL of the API. Defaults to 'https://api.edwin.cloud/v1'.
   */
  init(token, config) {
    this.client = axios.create({
      baseURL: config.apiURL || API_URL_DEFAULT,
      timeout: config.timeout || 10000,
      headers: { 'x-api-key': token },
    })
  }

  /**
   * Fetch the available content for the user.
   * @returns {Object} Contains arrays of topics and missionsAndSeries.
   */
  async getAvailableContent() {
    try {
      const endpoint = CONTENT_ENDPOINTS.GET_AVAILABLE_CONTENT
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
      })
      return response.data
    } catch (error) {
      console.error('Error fetching available content:', error)
      throw error
    }
  }

  /**
   * Fetch user's progress.
   * @returns {Object} User's progress object.
   */
  async getProgress() {
    try {
      const endpoint = PROGRESS_ENDPOINTS.GET_PROGRESS
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
      })
      return response.data
    } catch (error) {
      console.error('Error fetching user progress:', error)
      throw error
    }
  }

  /**
   * Update user's progress with assigned content.
   * @param {Object} progressData - The progress data to update (e.g. { progressField: value, ... }).
   * @returns {Object} Contains the updated progress of the user.
   */
  async setProgress(progressData) {
    try {
      const endpoint = PROGRESS_ENDPOINTS.SET_PROGRESS
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
        data: progressData,
      })
      return response.data
    } catch (error) {
      console.error('Error updating user progress:', error)
      throw error
    }
  }

  /**
   * Fetch all user goals and goal instances.
   * @returns {Object} Contains lists of user goals and goal instances.
   */
  async getGoals() {
    try {
      const endpoint = GOALS_ENDPOINTS.GET_ALL_GOALS
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
      })
      return response.data
    } catch (error) {
      console.error('Error fetching user goals:', error)
      throw error
    }
  }

  /**
   * Fetch user goals by goalId.
   * @param {String[]} goalsIds - Array of unique identifiers for the goals.
   * @returns {Object[]} Array of user goals objects.
   */
  async getGoalsById(goalsIds) {
    try {
      const endpoint = GOALS_ENDPOINTS.GET_GOALS_BY_ID
      const response = await this.client.request({
        url: `${endpoint.endpoint}/${goalsIds.join(',')}`,
        method: endpoint.method.toLowerCase(),
      })
      return response.data
    } catch (error) {
      console.error('Error fetching user goals by ID:', error)
      throw error
    }
  }

  /**
   * Create user goals.
   * @param {Object} goalData - Contains the goal object and array of instances for the goal (e.g. { goal: { ... }, instances: [{ ... }, ...] }).
   * @returns {Object} Contains array of user goals, array of user goal instances, and array of user goal reminders.
   */
  async createGoalsAndInstances(goalData) {
    try {
      const endpoint = GOALS_ENDPOINTS.CREATE_OR_UPDATE_GOALS
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
        data: goalData,
      })
      return response.data
    } catch (error) {
      console.error('Error creating user goals:', error)
      throw error
    }
  }

  /**
   * Search user goal instances.
   * @param {Object} dateRange - Contains fromDateTime and toDateTime properties as UTC strings (e.g. { fromDateTime: '2023-01-01T00:00:00Z', toDateTime: '2023-01-31T23:59:59Z' }).
   * @returns {Object[]} Array of goal instances filtered by the provided date range, or all instances if no date range is provided.
   */
  async searchGoalInstances(dateRange) {
    try {
      const endpoint = GOALS_ENDPOINTS.SEARCH_GOAL_INSTANCES
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
        data: dateRange,
      })
      return response.data
    } catch (error) {
      console.error('Error searching user goal instances:', error)
      throw error
    }
  }

  /**
   * Update user goal instances.
   * @param {Array} goalInstances - Contains array of  goal instances
   * @returns {Object[]} Array of updated goal instances
   */
  async updateGoalInstances(goalInstances) {
    try {
      const endpoint = GOALS_ENDPOINTS.UPDATE_GOAL_INSTANCES
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
        data: goalInstances,
      })
      return response.data
    } catch (error) {
      console.error('Error updating goal instances:', error)
      throw error
    }
  }

  /**
   * Fetch user's tips.
   * @returns {Object[]} Array of user tips.
   */
  async getTips() {
    try {
      const endpoint = TIPS_ENDPOINTS.GET_TIPS
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
      })
      return response.data
    } catch (error) {
      console.error('Error fetching user tips:', error)
      throw error
    }
  }

  /**
   * Update user's tips.
   * @param {Array} tipsData - Contains array of tips.
   * @returns {Object[]} Array of updated tips.
   */
  async setTips(tipsData) {
    try {
      const endpoint = TIPS_ENDPOINTS.SET_TIPS
      const response = await this.client.request({
        url: endpoint.endpoint,
        method: endpoint.method.toLowerCase(),
        data: tipsData,
      })
      return response.data
    } catch (error) {
      console.error('Error updating user tips:', error)
      throw error
    }
  }
}

export default EdwinCloudApiClient
