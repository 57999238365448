import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import { AChatUserInitials } from '../atoms/AChatUserInitials'
import { MChatUserOption } from './MChatUserOption'

export const MChatUserOptions = ({
  options,
  chosenOptionProp = null,
  msg = 'Thank you',
  onFinish,
}) => {
  // const { user } = useUser()
  const [chosenOption, setChosenOption] = useState(null)

  const handleClick = useCallback(
    optionIndex => {
      const { onClick = () => {} } = options[optionIndex]

      if (chosenOption !== null) return

      setChosenOption(optionIndex)
      onClick()
    },
    [chosenOption, options]
  )

  const handleFinish = useCallback(() => {
    setChosenOption(0)
    onFinish()
  }, [onFinish])

  return (
    <div className="flex items-end justify-end ml-auto pb-2 lg:pb-6">
      <div
        className={classNames(
          'flex flex-col items-end max-w-[240px]'
          // user?.initials && 'mr-2.5'
        )}
      >
        {!!onFinish && (
          <MChatUserOption
            label={msg}
            chosenOption={chosenOption}
            onClick={() => {
              handleFinish()
            }}
          />
        )}

        {options?.map(({ label, isChosen = false }, index) => (
          <MChatUserOption
            key={label}
            label={label}
            chosenOption={chosenOption}
            index={index}
            onClick={() => {
              handleClick(index)
            }}
            isChosen={isChosen}
          />
        ))}
      </div>
      {/*{user?.initials && <AChatUserInitials initials={user.initials} />}*/}
    </div>
  )
}
