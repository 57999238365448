import { useCallback, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

const countryCodeNames = [{ code: 'en', name: 'English' }]

const extractLanguage = code => {
  return (
    countryCodeNames.find(c => c.code === code) || {
      code,
    }
  )
}

export const useTranslations = () => {
  const { t, i18n } = useTranslation()

  const languages = useMemo(() => {
    return i18n.options.supportedLngs
      .map(lang => extractLanguage(lang))
      .filter(language => language?.code !== 'cimode')
  }, [i18n.options.supportedLngs])

  const systemLanguage = useMemo(() => {
    const fullLanguage = navigator.language || navigator.userLanguage
    const code = fullLanguage.split('-')[0]

    return extractLanguage(code)
  }, [])

  const currentLanguage = useMemo(() => {
    const code = i18n.language

    return extractLanguage(code)
  }, [i18n.language])

  const defaultLanguage = useMemo(() => {
    const code = i18n.options.fallbackLng[0]

    return extractLanguage(code)
  }, [i18n.options.fallbackLng])

  const changeLanguage = useCallback(
    lngCode => {
      i18n.changeLanguage(lngCode)
    },
    [i18n]
  )

  const translateData = useCallback(
    data => {
      return data?.translations?.[currentLanguage.code] || data
    },
    [currentLanguage]
  )

  return {
    t,
    languages,
    systemLanguage,
    currentLanguage,
    defaultLanguage,
    changeLanguage,
    translateData,
  }
}

export const useUpdateTranslationLanguage = (
  localLanguage,
  serverLanguage,
  isUserDataLoaded,
  updateServerLanguageFn
) => {
  useEffect(() => {
    if (isUserDataLoaded && localLanguage && serverLanguage && localLanguage !== serverLanguage) {
      updateServerLanguageFn(localLanguage)
    }
  }, [localLanguage, serverLanguage, updateServerLanguageFn, isUserDataLoaded])
}
