import React from 'react'
import classnames from 'classnames'

const animateDelays = ['animate-delay-200', 'animate-delay-300', 'animate-delay-400']

export const ATypingIndicator = ({ emojiLoader = false }) => (
  <div className="flex items-center gap-x-0.5 py-2.5 px-3.5">
    {emojiLoader ? (
      <>
        <div className="loader" />
        <div className="loader second" />
        <div className="loader third" />
      </>
    ) : (
      <div className="flex items-center mt-4 mb-1">
        {[...Array(3).keys()].map(delayIndex => (
          <div
            key={delayIndex}
            className={classnames(
              'w-1 h-1 mr-1 last:mr-0 bg-gray-900 rounded-full animate-typing-dot',
              `${animateDelays[delayIndex]}`
            )}
          />
        ))}
      </div>
    )}
  </div>
)
