import React from 'react'

import ReactMarkdown from 'react-markdown'

export const AObjective = ({ objective }) => {
  const { label = '' } = objective || {}

  return (
    <div className="text-sm mx-0.5">
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => <p {...props} className="truncate" />,
        }}
      >
        {label}
      </ReactMarkdown>
    </div>
  )
}
