import { MAIViewContainer } from '@molecules/MAIViewContainer'
import { AButton } from '@atoms/AButton'

const TrackerAction = ({ type, typeMeta }) => {
  const { predefinedValues: values, isUserInput = false } = typeMeta || {}

  if (isUserInput) {
    return (
      <div className="flex">
        <input
          type="text"
          placeholder="Type here..."
          className="max-w-[135px] form-input !py-2 !px-4 !border !border-codGray !rounded-l-full focus:!outline-none focus:!shadow-none focus:!ring-1 focus:!ring-codGray"
        />
        <input
          type="submit"
          value="Submit"
          className="py-2 px-3 text-gray-50 bg-codGray border border-l-0 border-codGray !rounded-r-full disabled:opacity-80 disabled:cursor-auto cursor-pointer"
        />
      </div>
    )
  }

  if (type === 'boolean') {
    const trackerValues = [
      { id: 1, title: '👍 Yes, I did' },
      { id: 2, title: "👎 No, I didn't" },
    ]

    return (
      <div className="flex flex-nowrap gap-x-1">
        {trackerValues.map(value => (
          <AButton key={value.id} small outline className="flex-1 whitespace-nowrap">
            {value.title}
          </AButton>
        ))}
      </div>
    )
  }

  return (
    <div className="flex flex-nowrap gap-x-1">
      {values.map(value => (
        <AButton key={value.id} small outline className="flex-1 whitespace-nowrap">
          {value.title}
        </AButton>
      ))}
    </div>
  )
}

export const MAIViewTracker = ({ meta }) => {
  console.log('MAIViewTracker', meta)

  return (
    <MAIViewContainer {...meta}>
      <div className="flex gap-x-4">
        {meta.goals.map(goal => (
          <div
            key={goal.id}
            className="flex flex-col justify-between w-60 p-4 bg-gray-50 rounded-lg overflow-hidden shadow-md"
          >
            <div>
              <h3 className="mb-2 text-md font-medium">{goal.title}</h3>
              <p className="mb-4 text-sm text-gray-600">{goal.description}</p>
            </div>
            <TrackerAction {...goal} />
          </div>
        ))}
      </div>
    </MAIViewContainer>
  )
}
