import { MAIViewContainer } from '@molecules/MAIViewContainer'

export const MAIViewMission = ({ meta }) => {
  console.log('MAIViewMission', meta)

  return (
    <MAIViewContainer {...meta}>
      <div className="flex gap-x-4">
        {meta.missions.map(mission => (
          <div
            key={mission.id}
            className="flex min-h-[160px] bg-gray-50 rounded-lg overflow-hidden shadow-md"
          >
            <div className="w-[180px] h-full bg-[#ddfeeb]" />
            <div className="py-6 px-4 max-w-[260px]">
              <div className="mb-2 font-p22 font-medium line-clamp-4 text-lg lg:text-xl">
                {mission.title}
              </div>
              <div className="text-sm text-gray-600">{mission.description}</div>
            </div>
          </div>
        ))}
      </div>
    </MAIViewContainer>
  )
}
