import React from 'react'
import classnames from 'classnames'

import ReactMarkdown from 'react-markdown'

export const MAIViewContainer = ({ children, title, description }) => {
  return (
    <div className="py-5 overflow-hidden bg-white shadow sm:rounded-lg max-w-5xl">
      <div className="px-4">
        {title && (
          <h3 className="font-p22 text-base font-medium leading-7 text-gray-900">{title}</h3>
        )}
        {description && (
          <ReactMarkdown
            className="max-w-2xl text-sm leading-6 text-gray-600"
            components={{
              h2: ({ node, ...props }) => <h2 {...props} className="first:mt-0 mt-4 font-medium" />,
              h3: ({ node, ...props }) => (
                <h3 {...props} className="first:mt-0 mt-3 text-lg font-medium font-p22" />
              ),
              ul: ({ node, ...props }) => <ul {...props} className="list-disc pl-5" />,
            }}
          >
            {description}
          </ReactMarkdown>
        )}
      </div>
      {children && (
        <div className={classnames('px-4', (title || description) && 'mt-4')}>{children}</div>
      )}
    </div>
  )
}
