import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useGoal, useQuestionnaire, useChatAnimation } from '@edwin/react-sdk'
import {
  useFlowThread,
  useHandleScrollbarSpace,
  useProgramTimeline,
  useSelectedMission,
  useTranslations,
} from '@hooks/'
import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'

import useStore from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
import useGoalsStore from '@store/useGoalsStore'
import useQuestionnaireStore from '@store/useQuestionnaireStore'
// import usePointsStore from '@store/usePointsStore'
import useTipsStore from '@store/useTipsStore'
import useAssistantContextStore from '@store/useAssistantContextStore'

import { ROUTES, generateRoute } from '@const/Routes'

import {
  createAssistantThreadWithInput,
  markAssistantComponentThreadAsCompleted,
} from '@services/Insights'

import { Mission } from '@edwinsandbox/react-web'
import { AButton } from '@atoms/AButton'
import { OModal } from '@organisms/OModal'
import { OMissionSkeleton } from '@organisms/OMissionSkeleton'
import { OMissionChat } from '@organisms/OMissionChat'
import { MChatInput } from '@molecules/MChatInput'

const findTopicByMissionId = (topics, missionId) => {
  for (const topic of topics) {
    if (topic?.missionsAndSeries) {
      const foundMission = topic.missionsAndSeries?.find(mission => mission?.id === missionId)

      if (foundMission) {
        return topic?.id
      }
    }
  }

  return null
}

export const PMission = ({ isLoading }) => {
  const { t } = useTranslations()
  const { missionId = '', topicId = '' } = useParams()
  const { createGoalPayload } = useGoal()
  const navigate = useNavigate()
  const location = useLocation()

  const loadNextMsgRef = useRef(null)
  const resolveAssistantRef = useRef(null)

  const [threadId, setThreadId] = useState(null)
  const [customMessages, setCustomMessages] = useState([])
  const [isCreatingAssistant, setIsCreatingAssistant] = useState(false)
  const [isAssistantFinished, setIsAssistantFinished] = useState(false)

  const {
    thread,
    modifiedThread,
    sendMessage,
    handleUserInput,
    handleAssistantFlowAction,
    isNewMessagesLoading,
    isLastMsgNeedsUserAction,
    isLastMsgDontTriggerInput,
  } = useFlowThread(threadId)

  const threadSummary = useMemo(() => {
    const currentSummary = thread?.objectives || []
    const prevAssistantSummary = thread?.input?.summary || []

    return [
      ...prevAssistantSummary.map((item, index) => {
        return { id: index, label: item }
      }),
      ...currentSummary,
    ]
  }, [thread])

  const isInputAvailable = useMemo(() => {
    if (!isLastMsgDontTriggerInput) {
      return false
    }

    if (!thread) {
      return false
    }

    if (isLastMsgNeedsUserAction) {
      return false
    }

    if (thread?.messages?.length === 0) {
      return false
    }

    if (!!thread && isNewMessagesLoading) {
      return false
    }

    if (isAssistantFinished) {
      return false
    }

    if (isCreatingAssistant) {
      return false
    }

    return true
  }, [
    isAssistantFinished,
    isCreatingAssistant,
    isLastMsgDontTriggerInput,
    isLastMsgNeedsUserAction,
    isNewMessagesLoading,
    thread,
  ])

  useChatAnimation(isInputAvailable, undefined, false)

  const selectedMission = useSelectedMission(topicId, missionId)
  // const { selectedMission } = useProgramTimeline(topicId, missionId)

  useHandleScrollbarSpace()

  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false)

  const user = useStore(state => state.user)

  const addQueueTask = useQuestionnaireStore(state => state.addQueueTask)

  const setMissionProgress = useProgressStore(state => state.setMissionProgress)
  const { topics } = useProgressStore(state => state.userContent)

  const createGoalAndGoalInstances = useGoalsStore(state => state.createGoalAndGoalInstances)
  const doesGoalExist = useGoalsStore(state => state.doesGoalExist)

  // const getUserPoints = usePointsStore(state => state.getUserPoints)
  // const updateUserPoints = usePointsStore(state => state.updateUserPoints)

  const createTips = useTipsStore(state => state.createTips)

  const assistantContext = useAssistantContextStore(state => state.assistantContext)
  const addAssistantContext = useAssistantContextStore(state => state.addAssistantContext)
  const clearAssistantContext = useAssistantContextStore(state => state.clearAssistantContext)

  const onQuestionAnswered = useCallback(questionAnswered => {
    console.log('--- question answered ---', questionAnswered)
  }, [])

  const { updateQuestionnaire, getQuestionnairePayload } = useQuestionnaire(onQuestionAnswered)

  const previousPathName = useMemo(() => {
    if (!missionId) return ROUTES.ROOT

    const pathName = location?.pathname.replace(`/mission/${missionId}`, '')

    const routesArray = Object.values(ROUTES).map(route =>
      generateRoute(route, { missionId, topicId })
    )

    const availableRoute = routesArray.find(route => route === pathName) || ROUTES.ROOT

    return availableRoute
  }, [location?.pathname, missionId, topicId])

  // const userPoints = useMemo(() => {
  //   return getUserPoints()
  // }, [getUserPoints])

  const handleCloseModal = useCallback(() => {
    setIsCloseModalOpen(false)
  }, [])

  const handleCloseActions = useCallback(() => {
    clearAssistantContext()

    if (selectedMission.isCurrent) {
      return setIsCloseModalOpen(true)
    }

    navigate(previousPathName)
  }, [clearAssistantContext, navigate, previousPathName, selectedMission.isCurrent])

  const handleClose = useCallback(() => {
    handleCloseModal()
    navigate(previousPathName)
  }, [handleCloseModal, navigate, previousPathName])

  const handleFinish = useCallback(
    async (msgs, oaths, questions) => {
      clearAssistantContext()

      const questionnairePayload = getQuestionnairePayload(questions)

      if (questionnairePayload) {
        addQueueTask({
          payload: {
            createdOn: DateTime.now().toJSDate(),
            missionId,
            userId: user.id,
            ...questionnairePayload,
          },
        })
      }

      if (selectedMission?.tips) {
        createTips(selectedMission.tips, selectedMission.id)
      }

      if (selectedMission.isCurrent) {
        const timestamp = new Date().getTime()

        const payload = {
          isCompleted: true,
          completedAt: timestamp,
          isDirty: true,
        }

        const changes = {
          [selectedMission.topicId]: {
            missionsAndSeries: {
              [missionId]: {
                isCompleted: true,
                completedAt: timestamp,
              },
            },
          },
        }

        setMissionProgress(selectedMission.topicId, selectedMission.id, payload, true, changes)
        // updateUserPoints(selectedMission.pointsForCompletion || 0)
      }

      handleClose()
    },
    [
      clearAssistantContext,
      getQuestionnairePayload,
      selectedMission.tips,
      selectedMission.isCurrent,
      selectedMission.id,
      selectedMission.topicId,
      handleClose,
      addQueueTask,
      missionId,
      user.id,
      createTips,
      setMissionProgress,
    ]
  )

  const handleGoal = useCallback(
    goalData => {
      const payload = createGoalPayload({
        ...goalData,
        createdBy: { type: 'mission', missionId: selectedMission.id },
      })

      createGoalAndGoalInstances(payload)
    },
    [createGoalAndGoalInstances, createGoalPayload, selectedMission.id]
  )

  const assistantFlowAction = useCallback(
    async ({ type, input }) => {
      try {
        // let navigateTo = ROUTES.ROOT

        const { id, shouldContinueFlow } = await handleAssistantFlowAction({ type, input })

        // if (id) {
        // navigate to mission needs missionId and topicId
        //   navigateTo = generateRoute(ROUTES.ASSISTANT, { assistantId: id })
        // }

        if (!shouldContinueFlow) {
          resolveAssistantRef.current({ triggerValue: input?.triggerId })
          setIsAssistantFinished(true)
        }

        // handle redirecting to other mission
        // navigate(navigateTo)
        addAssistantContext(input)
      } catch (error) {
        console.error(error)
      }
    },
    [addAssistantContext, handleAssistantFlowAction]
  )

  const handleGoToMission = useCallback(
    missionId => {
      const topicId = findTopicByMissionId(topics, missionId)

      navigate(generateRoute(ROUTES.MISSION, { missionId, topicId }))
    },
    [navigate, topics]
  )

  const onAssistant = useCallback(async ({ assistantId, type, input, loadNextMsg, triggers }) => {
    return new Promise(async (resolve, reject) => {
      // return reject('Assistant is not available')

      const cleanTriggers = triggers.map(({ id, value }) => {
        return { id, value }
      })

      try {
        loadNextMsgRef.current = loadNextMsg
        resolveAssistantRef.current = resolve

        setIsCreatingAssistant(true)
        const { id } = await createAssistantThreadWithInput({
          assistantId,
          type,
          input: {
            ...assistantContext,
            ...input,
          },
          triggers: cleanTriggers,
        })

        setThreadId(id)
        setIsCreatingAssistant(false)
      } catch (error) {
        console.error(error)
        reject(error)
      }
    }, [])
  }, [])

  useEffect(() => {
    if (modifiedThread?.messages?.length) {
      const msgs = modifiedThread.messages

      const filteredMsgs = msgs.filter(
        msg => !customMessages.some(customMsg => customMsg.id === msg.id)
      )

      if (filteredMsgs.length > 0) {
        setCustomMessages(prev => [...prev, ...filteredMsgs])
      }
    }
  }, [customMessages, modifiedThread])

  useEffect(() => {
    const filteredCustomMessages = customMessages.filter(msg => !msg.loaded)

    if (filteredCustomMessages.length > 0) {
      filteredCustomMessages.forEach(msg => {
        loadNextMsgRef.current({ ...msg, renderId: nanoid(), first: false })
      })

      setCustomMessages(prev => {
        return prev.map(msg => {
          const filteredMsg = filteredCustomMessages.find(fMsg => fMsg.id === msg.id)

          if (filteredMsg) {
            return {
              ...msg,
              loaded: true,
            }
          }

          return msg
        })
      })
    }
  }, [customMessages])

  useEffect(() => {
    console.log({ assistantContext })
  }, [assistantContext])

  if (isLoading) {
    return <OMissionSkeleton />
  }

  if (!selectedMission) {
    return null
  }

  return (
    <>
      <div
        className={classnames(
          'relative min-h-full overflow-hidden transition-all',
          isInputAvailable ? 'pb-[60px] duration-0' : 'pb-0 duration-300'
        )}
      >
        <Mission
          user={user}
          assistant={assistantContext}
          objectives={threadSummary}
          mission={selectedMission}
          // points={userPoints}
          onFinish={handleFinish}
          onGoal={handleGoal}
          doesGoalExist={doesGoalExist}
          onClose={handleCloseActions}
          onQuestion={updateQuestionnaire}
          onAssistant={onAssistant}
          missionTitle={selectedMission?.title}
          translations={{
            author: t('mission.author'),
            steps: t('mission.steps'),
            congrats: t('summaryComponent.congrats'),
            description: t('summaryComponent.description'),
            completed: t('summaryComponent.completed'),
            ctaText: t('summaryComponent.ctaText'),
          }}
          shouldDisplayCover={false}
          interactiveComponents={{
            chat: OMissionChat,
          }}
          onAssistantAction={assistantFlowAction}
          onSendHiddenMsg={msg => {
            sendMessage(msg, true)
          }}
          isNewMessagesLoading={(threadId && isNewMessagesLoading) || isCreatingAssistant}
          handleGoToMission={handleGoToMission}
        />
        <div
          className={classnames(
            'absolute inset-x-0 lg:ml-[300px] xl:ml-[360px] transition-all duration-300',
            isInputAvailable ? 'bottom-0' : '-bottom-[60px]'
          )}
        >
          <div className="max-w-3xl mx-auto px-2.5 pb-3">
            <MChatInput handleOnSubmit={handleUserInput} className="px-2.5 sm:px-0" />
          </div>
        </div>
      </div>

      <OModal onClose={handleCloseModal} isOpen={isCloseModalOpen}>
        <div className="text-center">
          <h2 className="text-lg font-semibold">{t('modal.areYouSure')}</h2>
          <p>{t('modal.startFromScratch')}</p>
        </div>
        <div className="flex justify-center items-center mt-4 space-x-2">
          <AButton onClick={handleClose}>{t('confirm')}</AButton>
          <AButton outline onClick={handleCloseModal}>
            {t('cancel')}
          </AButton>
        </div>
      </OModal>
    </>
  )
}
