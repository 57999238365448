import { Fragment, useMemo, useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Transition, Dialog } from '@headlessui/react'
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTime } from 'luxon'
import enUS from 'date-fns/locale/en-US'
import classnames from 'classnames'

import useStore from '@store/useStore'
import useChatsStore from '@store/useChatStore'

import { useTranslations } from '@hooks'

import logo from '@assets/images/logo.svg'

import { AAvatar } from '@atoms/AAvatar'
import { OSignoutModal } from './OSignoutModal'
import { OTimeTravelWrapper } from '@organisms/OTimeTravelWrapper'

import { ROUTES } from '@const/Routes'

import IconX from '@assets/images/icon-x.svg'
import IconBars from '@assets/images/icon-bars.svg'

import CONFIG from '@config/Config'

export const OPageHeader = () => {
  const { t } = useTranslations()
  const location = useLocation()

  const user = useStore(state => state.user)

  const chatUnreadMessageCount = useChatsStore(state => state.chatUnreadMessageCount)

  const navigation = useMemo(
    () => [
      { name: t('navigation.today'), route: ROUTES.ROOT },
      { name: t('navigation.program'), route: ROUTES.PROGRAM },
      { name: t('navigation.ai'), route: ROUTES.AI },
      { name: t('navigation.messages'), route: ROUTES.CHAT },
    ],
    [t]
  )

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isSignoutModalOpen, setIsSignoutModalOpen] = useState(false)

  navigation.forEach(item => {
    if (item.route === ROUTES.ROOT) {
      item.current = location.pathname === item.route
    } else {
      item.current = location.pathname.startsWith(item.route)
    }
  })

  const handleOpenMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(true)
  }, [])

  const handleCloseMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(false)
  }, [])

  const handleOpenSignoutModal = useCallback(() => {
    setIsSignoutModalOpen(true)
  }, [])

  const handleCloseSignoutModal = useCallback(() => {
    setIsSignoutModalOpen(false)
  }, [])

  return (
    <>
      <nav className="relative text-codGray bg-white shadow z-10">
        <div className="mx-auto max-w-7xl px-3.5 lg:px-5">
          <div className="relative flex items-center justify-between py-2.5">
            <div className="relative md:hidden">
              {/* Mobile menu button*/}
              <button
                onClick={handleOpenMobileMenu}
                className="relative inline-flex items-center justify-center rounded-md -ml-1.5 p-1
                text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-codGray"
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">{t('screenReaders.openMainMenu')}</span>
                <img src={IconBars} alt="Menu icon" />
              </button>
              {chatUnreadMessageCount > 0 && (
                <span className="absolute -top-2 left-3/4 inline-block py-0.5 px-1.5 text-xs text-white text-center bg-persianRed/90 rounded-full">
                  {chatUnreadMessageCount}
                </span>
              )}
            </div>
            <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <Link
                  to={ROUTES.ROOT}
                  className="rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-codGray"
                >
                  {CONFIG.timeTravelEnabled ? (
                    <OTimeTravelWrapper calendarClassName="left-4">
                      <div className="relative w-24 h-7">
                        <img
                          src={logo}
                          alt="logo"
                          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-24"
                        />
                      </div>
                    </OTimeTravelWrapper>
                  ) : (
                    // <div className="text-xl font-p22 font-bold">{t('logo')}</div>
                    <div className="relative w-24 h-7">
                      <img
                        src={logo}
                        alt="logo"
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-24"
                      />
                    </div>
                  )}
                </Link>
              </div>
              <div className="hidden md:block ml-auto">
                <div className="flex space-x-7">
                  {navigation.map(item => (
                    <Link
                      key={item.name}
                      to={item.route}
                      className={classnames(
                        item.current ? 'font-bold' : 'font-light',
                        'relative text-sm hover:text-pelorous leading-7 uppercase rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-codGray'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                      {item.route === '/chat' && chatUnreadMessageCount > 0 && (
                        <span className="absolute -top-2 left-3/4 inline-block ml-4 py-0.5 px-1.5 text-xs text-white text-center bg-persianRed/90 rounded-full">
                          {chatUnreadMessageCount}
                        </span>
                      )}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex items-center md:static md:inset-auto md:ml-7">
              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <div>
                  <Menu.Button className="flex rounded-full bg-green text-sm focus:outline-none focus:ring-2 focus:ring-codGray focus:ring-offset-1">
                    <span className="sr-only">{t('screenReaders.openUserMenu')}</span>
                    <AAvatar
                      className="text-codGray font-bold tracking-tighter"
                      firstName={user?.firstName}
                      lastName={user?.lastName}
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute max-w-xs right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {!!user && (
                      <div className="block px-4 py-2 text-sm">
                        <div className="flex flex-col">
                          <span className="font-medium">
                            {user?.firstName} {user?.lastName}
                          </span>
                          <span className="font-regular text-xs text-gray-light truncate">
                            {user?.email}
                          </span>
                        </div>
                      </div>
                    )}

                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={ROUTES.ACCOUNT}
                          className={classnames(
                            active ? 'bg-green-light' : '',
                            'block px-4 py-2 text-sm text-gray-700 whitespace-nowrap'
                          )}
                          aria-current={active ? 'page' : undefined}
                        >
                          {t('yourAccount')}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={handleOpenSignoutModal}
                          className={classnames(
                            active ? 'bg-green-light' : '',
                            'flex flex-row items-center px-4 py-2 text-sm text-gray-700 cursor-pointer'
                          )}
                        >
                          <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-2" />
                          <span>{t('signOut')}</span>
                        </div>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </nav>

      <Transition.Root show={isMobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleCloseMobileMenu}>
          <div className="fixed inset-0 bg-gray-900/40" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 left-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen sm:max-w-xs bg-wildSand">
                    <div className="h-full overflow-y-auto p-3.5">
                      <div className="flex justify-end">
                        <button
                          type="button"
                          className="relative rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-codGray"
                          onClick={handleCloseMobileMenu}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">{t('screenReaders.closePanel')}</span>
                          <img src={IconX} alt="" />
                        </button>
                      </div>
                      <div className="relative flex-1 flex flex-col items-center sm:items-start space-y-7 mt-8 sm:ml-8">
                        {navigation.map(item => (
                          <Link
                            key={item.name}
                            to={item.route}
                            className={classnames(
                              'relative p-1 text-2xl font-medium focus:outline-none focus:ring-2 focus:ring-inset focus:ring-codGray focus:rounded',
                              item.current && 'border-b-2 border-green-dark text-green-dark'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                            {item.route === '/chat' && chatUnreadMessageCount > 0 && (
                              <span className="absolute -top-0.5 left-full inline-block -ml-1.5 py-0.5 px-1.5 text-xs text-white text-center bg-persianRed rounded-full">
                                {chatUnreadMessageCount}
                              </span>
                            )}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <OSignoutModal isOpen={isSignoutModalOpen} onClose={handleCloseSignoutModal} />
    </>
  )
}
