import { phone } from 'phone'

import { DateTime } from 'luxon'

export { createGoalMission } from './createGoalMission'
export { removeMarkdown } from './removeMarkdown'

export const sleep = async (ms = 0) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export const millisToMinutesAndSeconds = millis => {
  const minutes = Math.floor(millis / 60000)
  const seconds = ((millis % 60000) / 1000).toFixed(0)

  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

export const checkIfMobile = (width = 1023) => {
  return window.matchMedia(`only screen and (max-width: ${width}px)`).matches
}

export const validatePhoneNumber = phoneNumber => {
  const validatedPhoneUSA = phone(phoneNumber, { country: 'USA' })
  const validatedPhonePOL = phone(phoneNumber, { country: 'POL' })

  //fallback for POL
  if (validatedPhonePOL.isValid) return validatedPhonePOL

  return validatedPhoneUSA
}

const formatOptions = { month: 'short', day: 'numeric', year: 'numeric' }

export const formatLuxonDate = dateString => {
  const date = DateTime.fromJSDate(new Date(dateString), { locale: 'en' })

  return date.isValid ? date.toLocaleString(formatOptions) : null
}
