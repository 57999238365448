import { useCallback, useMemo } from 'react'
import { DateTime } from 'luxon'

export const useTracker = ({
  id,
  type,
  typeMeta,
  isTracked,
  trackedData,
  allowMultipleTrackingsPerInstance,
}) => {
  const activeValues = useMemo(() => {
    if (allowMultipleTrackingsPerInstance) {
      return []
    }

    return trackedData.map(({ meta }) => meta.value)
  }, [allowMultipleTrackingsPerInstance, trackedData])

  const disabledValues = useMemo(() => {
    if (allowMultipleTrackingsPerInstance) {
      return []
    }

    return trackedData.map(({ meta }) => meta.value)
  }, [allowMultipleTrackingsPerInstance, trackedData])

  const createNewTrackedData = useCallback(
    (value, trackedOn) => {
      const { allowMultipleSelection = false } = typeMeta

      return [
        {
          meta: {
            value,
          },
          trackedOn,
        },
        ...(allowMultipleSelection || allowMultipleTrackingsPerInstance ? trackedData : []),
      ]
    },
    [allowMultipleTrackingsPerInstance, trackedData, typeMeta]
  )

  const createTrackerPayload = useCallback(
    value => {
      const lastTrackedOn = DateTime.utc()
      const newTrackedData = createNewTrackedData(value, lastTrackedOn)

      return {
        id,
        isTracked: !!newTrackedData.length,
        lastTrackedOn,
        trackedData: newTrackedData,
      }
    },
    [createNewTrackedData, id]
  )

  return {
    activeValues,
    disabledValues,
    createTrackerPayload,
  }
}
