import Lottie from 'lottie-react'

import lottieAnimation from '../../assets/lottie/animation_lnukuo2q.json'

export const MChatMilestonePrompt = ({ title, description, lottieAnimationName }) => {
  return (
    <div className="max-w-72 pb-3 px-3.5">
      <div className="w-36 mb-5">
        <Lottie animationData={lottieAnimation} />
      </div>
      <h4 className="text-[20px] leading-[26px] font-p22">{title}</h4>
      <div className="w-16 h-1 mt-1.5 mb-4 bg-codGray" />
      <div className="text-sm">{description}</div>
    </div>
  )
}
