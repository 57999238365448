import { useCallback, useState } from 'react'
import { useSummary } from '@edwin/react-sdk'
import _ from 'lodash'

import Lottie from 'lottie-react'
import { AButton } from '../atoms/AButton'

import lottieAnimation from '../../assets/lottie/animation_lnukuo2q.json'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatMissionSummary = ({
  completionMeta,
  triggerConfetti = () => {},
  onFinish = () => {},
}) => {
  const { title, description } = completionMeta || {}
  const {
    missionTitle,
    translations = {
      congrats: 'Well Done!',
      description: 'Now you are ready to start working on your goals. Check out the next mission.',
      completed: 'You have completed {{name}}.',
      ctaText: 'Finish',
    },
  } = useSummary()

  // const [wasConfetti, setWasConfetti] = useState(false)
  const [isFinished, setIsFinished] = useState(false)

  const handleOnFinish = useCallback(() => {
    if (!isFinished) {
      onFinish()
      setIsFinished(true)
    }
  }, [isFinished, onFinish])

  // useEffect(() => {
  //   if (!wasConfetti) {
  //     setTimeout(() => {
  //       triggerConfetti()
  //       setWasConfetti(true)
  //     }, 500)
  //   }
  // }, [triggerConfetti, wasConfetti])

  return (
    <div className="max-w-72 pb-3 px-3.5">
      <div className="w-36 mb-5">
        <Lottie animationData={lottieAnimation} />
      </div>
      <h4 className="text-[20px] leading-[26px] font-p22">{title || translations.congrats}</h4>
      <div className="w-16 h-1 mt-1.5 mb-4 bg-codGray" />
      <div className="text-sm">{description || translations.description}</div>
      <AButton className="mt-4" onClick={handleOnFinish} disabled={isFinished} small dontRound>
        <span className="px-4">{translations.ctaText}</span>
      </AButton>
    </div>
  )
}
