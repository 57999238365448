import { useEffect, useCallback, useState, useRef, useMemo } from 'react'

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

const convertSecToTime = secondsValue => {
  let seconds = Math.floor(secondsValue)
  let minutes = Math.floor(seconds / 60)

  seconds = seconds % 60
  minutes = minutes % 60

  if (seconds === 0 && minutes === 0) {
    return ''
  }

  if (seconds === 0) {
    return `${minutes}m`
  }

  if (minutes === 0) {
    return `${seconds}s`
  }

  return `${minutes}m${padTo2Digits(seconds)}s`
}

export const useAudio = audioSrc => {
  const playbackInstance = useRef(null)
  const [playbackStatus, setPlaybackStatus] = useState({
    state: 'buffering',
    duration: 0,
    currentTime: 0,
  })

  const leftTimeLabel = useMemo(() => {
    const { duration = 0, currentTime = 0 } = playbackStatus

    return convertSecToTime(duration - currentTime)
  }, [playbackStatus])

  const playAudio = useCallback(() => {
    playbackInstance.current.play()
  }, [])

  const pauseAudio = useCallback(() => {
    playbackInstance.current.pause()
  }, [])

  const toggleAudio = useCallback(async () => {
    if (playbackStatus.state === 'playing') {
      pauseAudio()
    } else {
      playAudio()
    }
  }, [pauseAudio, playAudio, playbackStatus])

  const handlePlayAudio = useCallback(() => {
    setPlaybackStatus(prev => {
      return {
        ...prev,
        state: 'playing',
      }
    })
  }, [])

  const handlePauseAudio = useCallback(() => {
    setPlaybackStatus(prev => {
      return {
        ...prev,
        state: 'pause',
        currentTime: playbackInstance.current.currentTime,
      }
    })
  }, [])

  const handleEndedAudio = useCallback(() => {
    playbackInstance.current.currentTime = 0
    setPlaybackStatus(prev => {
      return {
        ...prev,
        state: 'ready',
        currentTime: 0,
      }
    })
  }, [])

  useEffect(() => {
    playbackInstance.current = new Audio(audioSrc)

    const interval = setInterval(() => {
      if (playbackInstance?.current?.readyState === 4) {
        const { duration = 0, currentTime = 0 } = playbackInstance?.current
        setPlaybackStatus({
          state: 'ready',
          duration,
          currentTime,
        })

        clearInterval(interval)
      }
    }, 300)

    playbackInstance?.current.addEventListener('play', handlePlayAudio)
    playbackInstance?.current.addEventListener('pause', handlePauseAudio)
    playbackInstance?.current.addEventListener('ended', handleEndedAudio)

    return () => {
      clearInterval(interval)
      pauseAudio()
      playbackInstance.current.src = null
      playbackInstance.current.removeEventListener('play', handlePlayAudio)
      playbackInstance.current.removeEventListener('pause', handlePauseAudio)
      playbackInstance.current.removeEventListener('ended', handleEndedAudio)
    }
  }, [audioSrc, handleEndedAudio, handlePauseAudio, handlePlayAudio, pauseAudio])

  return {
    status: playbackStatus.state,
    leftTimeLabel,
    toggleAudio,
  }
}
