import React, { useState, useEffect } from 'react'

export const ATypewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [parsedText, setParsedText] = useState([])

  const parseText = text => {
    const regex = /\*\*(.+?)\*\*/g
    const parts = []
    let lastIndex = 0

    text.replace(regex, (match, boldText, index) => {
      if (index > lastIndex) {
        parts.push({ text: text.slice(lastIndex, index), bold: false })
      }
      parts.push({ text: boldText, bold: true })
      lastIndex = index + match.length
    })

    if (lastIndex < text.length) {
      parts.push({ text: text.slice(lastIndex), bold: false })
    }

    return parts
  }

  useEffect(() => {
    setParsedText(parseText(text))
  }, [text])

  useEffect(() => {
    if (parsedText.length > 0) {
      const fullTextArray = Array.from(parsedText.map(part => part.text).join('')) // Poprawne dzielenie na znaki Unicode

      if (currentIndex < fullTextArray.length) {
        const timeout = setTimeout(() => {
          setCurrentText(prevText => prevText + fullTextArray[currentIndex])
          setCurrentIndex(prevIndex => prevIndex + 1)
        }, delay)

        return () => clearTimeout(timeout)
      }
    }
  }, [currentIndex, delay, parsedText])

  const renderText = () => {
    let displayIndex = 0

    return parsedText.map((part, i) => {
      const length = Array.from(part.text).length
      const visibleText = Array.from(currentText)
        .slice(displayIndex, displayIndex + length)
        .join('')

      displayIndex += length

      return part.bold ? <strong key={i}>{visibleText}</strong> : <span key={i}>{visibleText}</span>
    })
  }

  return <span>{renderText()}</span>
}
