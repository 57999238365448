import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import ReactMarkdown from 'react-markdown'

const Markdown = ({ children }) => {
  return (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <p {...props} className="truncate" />,
      }}
    >
      {children}
    </ReactMarkdown>
  )
}

export const AAnimatedText = ({ text, duration = 450 }) => {
  const [displayedText, setDisplayedText] = useState(null)
  const [isAnimating, setIsAnimating] = useState(false)
  const [prevText, setPrevText] = useState(null)

  useEffect(() => {
    if (!displayedText) {
      return setDisplayedText(text)
    }

    if (text !== displayedText) {
      setIsAnimating(true)
      setPrevText(displayedText)
      setDisplayedText(null)
      setDisplayedText(text)

      setTimeout(() => {
        setPrevText(null)
      }, duration)
    }
  }, [text, displayedText, duration])

  return (
    <div className="relative h-full overflow-hidden">
      {prevText && (
        <span
          className="absolute inset-0 py-2 animate-fade-out-up"
          style={{
            animationDuration: `${duration}ms`,
          }}
        >
          <Markdown>{prevText}</Markdown>
        </span>
      )}

      <span
        key={displayedText}
        className={classnames('absolute inset-0 py-2', isAnimating ? 'animate-fade-in-down' : '')}
        style={{
          animationDuration: `${duration}ms`,
        }}
      >
        <Markdown>{displayedText}</Markdown>
      </span>
    </div>
  )
}
