import { useState, useEffect, useCallback, useMemo } from 'react'
import { nanoid } from 'nanoid'

const LOAD_DELAY = 650

export const useMessageLoader = messages => {
  const [flatMessages, setFlatMessages] = useState([])
  const [messageQueue, setMessageQueue] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)

  const areMessagesToProcess = useMemo(() => {
    return messageQueue.length > 0
  }, [messageQueue.length])

  const filterProcessedMessages = useCallback(
    messages => {
      const processedIds = [
        ...flatMessages.map(({ id }) => id),
        ...messageQueue.map(({ id }) => id),
      ]
      const processedOriginalIds = [
        ...flatMessages.map(({ originalMsgId }) => originalMsgId),
        ...messageQueue.map(({ originalMsgId }) => originalMsgId),
      ]

      const messagesToProcess = messages.filter(({ id, data }) => {
        if (data?.type === 'message-list') {
          return !processedOriginalIds.includes(id)
        }

        return !processedIds.includes(id)
      })

      return messagesToProcess
    },
    [flatMessages, messageQueue]
  )

  const generateIdsForMessages = useCallback(({ messages, originalMsgId }) => {
    return messages.map(message => {
      return {
        ...message,
        id: nanoid(),
        originalMsgId,
      }
    })
  }, [])

  const flattenMessages = useCallback(
    messages => {
      const filteredMessages = filterProcessedMessages(messages)

      if (filteredMessages.length === 0) {
        return
      }

      filteredMessages.forEach(message => {
        if (message.data && message.data.type === 'message-list') {
          setMessageQueue(prev => {
            const messages = generateIdsForMessages({
              messages: message.data.content || [],
              originalMsgId: message.id,
            })

            return [...prev, ...messages]
          })
        } else {
          setMessageQueue(prev => {
            return [...prev, message]
          })
        }
      })
    },
    [filterProcessedMessages, generateIdsForMessages]
  )

  useEffect(() => {
    if (!messages) {
      return
    }

    if (messages.length === 0 && flatMessages.length !== 0) {
      setFlatMessages([])
      setMessageQueue([])
      setIsProcessing(false)
    } else {
      flattenMessages(messages)
    }
  }, [flatMessages.length, flattenMessages, messages])

  useEffect(() => {
    const processMessageQueue = async () => {
      if (messageQueue.length === 0 || isProcessing) {
        return
      }

      setIsProcessing(true)

      const nextMessage = messageQueue.shift()

      setMessageQueue(prev => {
        return prev.filter(({ id }) => id !== nextMessage.id)
      })

      setFlatMessages(prev => {
        return [...prev, { ...nextMessage, disableTyping: true }]
      })

      await new Promise(resolve => setTimeout(resolve, LOAD_DELAY))

      setIsProcessing(false)
    }

    processMessageQueue()
  }, [isProcessing, messageQueue, flatMessages])

  return { flatMessages, areMessagesToProcess, isProcessing }
}
