import {
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
  sendPasswordResetEmail,
  signOut as firebaseSignOut,
} from 'firebase/auth'

import { auth, doesUserExistFBFunction } from '@services/Firebase'
import { trackEvent } from '@services/Analytics'
import ANALYTICS from '@utils/constants/analytics'
import { validatePhoneNumber } from '@utils/helpers'

export const signInWithEmailAndPassword = async ({ email, password }) => {
  try {
    await firebaseSignInWithEmailAndPassword(auth, email, password)
    await trackEvent(ANALYTICS.SIGN_IN)
  } catch (err) {
    await trackEvent(ANALYTICS.SIGN_IN_UNSUCCESSFUL)
    return Promise.reject(err)
  }
}

export const signInWithPhoneNumber = async ({ phoneNumber, verifierCode }) => {
  try {
    const { isValid, phoneNumber: phoneNumberValidTransform } = validatePhoneNumber(phoneNumber)

    if (!isValid) {
      throw new Error('error.phoneNumberFormat')
    }

    const userExistsResult = await doesUserExistFBFunction({
      phoneNumber: phoneNumberValidTransform,
    })

    if (!userExistsResult.data.userExists) {
      throw new Error('error.phoneNumberDoesNotExists')
    }

    return firebaseSignInWithPhoneNumber(auth, phoneNumberValidTransform, verifierCode)
  } catch (err) {
    return Promise.reject(err?.message ? err.message : 'error.signInPhone')
  }
}

export const signOut = async () => {
  await firebaseSignOut(auth)
  await trackEvent(ANALYTICS.SIGN_OUT)
}

export const resetPassword = async email => {
  await sendPasswordResetEmail(auth, email)
}
