import React from 'react'
import PropTypes from 'prop-types'

export const AFormInputWrapper = ({ label, id, errorMessage, children }) => {
  return (
    <div>
      {!!label && (
        <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700">
          {label}
        </label>
      )}

      {children}

      {errorMessage && (
        <p className="block text-sm font-medium leading-5 text-red-400 mt-1">{errorMessage}</p>
      )}
    </div>
  )
}

AFormInputWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
}
