import React from 'react'
import classnames from 'classnames'

export const MTabs = ({ tabs, currentTab, onTabClick = () => {}, className = '' }) => {
  return (
    <div className={classnames('border-b border-gray-200', className)}>
      <nav className="-mb-px flex space-x-6" aria-label="Tabs">
        {tabs.map(tab => (
          <button
            key={tab.name}
            onClick={() => onTabClick(tab.name)}
            className={classnames(
              tab.name === currentTab
                ? 'border-green-dark text-green-dark'
                : 'border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-500',
              'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
              'focus:outline-none focus:ring-2 focus:ring-green-dark focus:rounded'
            )}
          >
            {tab.name}
          </button>
        ))}
      </nav>
    </div>
  )
}
