import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useThread } from '@services/Assistant'

import { OFlow } from '@organisms/OFlow'
import { AObjective } from '@atoms/AObjective'

export const PFlow = () => {
  const { assistantId = '' } = useParams()

  const [thread] = useThread(assistantId)

  const threadSummary = useMemo(() => {
    const currentSummary = thread?.objectives || []
    const prevAssistantSummary = thread?.input?.summary || []

    return [
      ...prevAssistantSummary.map((item, index) => {
        return { id: index, label: item }
      }),
      ...currentSummary,
    ]
  }, [thread])

  return (
    <OFlow assistantId={assistantId}>
      {threadSummary.length > 0 && (
        <div className="flex flex-col gap-y-1 py-2 px-2 bg-green">
          {threadSummary.map((objective, index) => (
            <AObjective key={objective?.id || index} objective={objective} />
          ))}
        </div>
      )}
    </OFlow>
  )
}
