const easeOutQuint = x => 1 - Math.pow(1 - x, 5)

export function animateScroll({ element, initialPosition, targetPosition, duration = 300 }) {
  const maxAvailableScroll = targetPosition
  const amountOfPixelsToScroll = initialPosition - targetPosition

  let start
  let position
  let animationFrame

  const step = timestamp => {
    if (start === undefined) {
      start = timestamp
    }

    const elapsed = timestamp - start
    const relativeProgress = elapsed / duration
    const easedProgress = easeOutQuint(relativeProgress)

    position = initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1)

    if (element === window) {
      window.scrollTo(0, position)
    } else {
      element.scrollTop = position
    }

    if (
      initialPosition !== maxAvailableScroll &&
      (element.scrollTop === maxAvailableScroll || element.scrollY === maxAvailableScroll)
    ) {
      return window.cancelAnimationFrame(animationFrame)
    }

    if (elapsed < duration) {
      animationFrame = window.requestAnimationFrame(step)
    }
  }

  animationFrame = window.requestAnimationFrame(step)
}
