import React, { useRef, useCallback, useEffect, useState } from 'react'

import { useTranslations, useInsightsAnimation, useScreenSize, useThreadUI } from '@hooks'
import { useThread, markThreadAsSeen } from '@services/Assistant'

import { a } from '@react-spring/web'
import { X } from 'react-feather'
import { Dialog } from '@headlessui/react'
import ReactMarkdown from 'react-markdown'
import { Mission } from '@edwinsandbox/react-web'
import { MChatInput } from '@molecules/MChatInput'

import image from '@assets/images/image.jpg'

const calculatePercentage = (dimension, containerDimension) => {
  return Math.floor(((containerDimension - dimension) / containerDimension) * 100)
}

export const OInsightsBanner = ({ threadId, content, onSendMessage, onClose }) => {
  const containerRef = useRef(null)
  const { t } = useTranslations()
  const { width, height } = useScreenSize()

  const [isOpen, setIsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { styles, animation, setBannerInsetValue } = useInsightsAnimation(isModalOpen)

  const [isInputDisabled, setIsInputDisabled] = useState(false)
  const [isNewMessagesLoading, setIsNewMessagesLoading] = useState(false)

  const [thread] = useThread(threadId)

  const sendMessage = useCallback(
    async message => {
      setIsInputDisabled(true)

      setIsNewMessagesLoading(true)

      try {
        await onSendMessage({ threadId, message })
      } catch (err) {
        console.error(err)
      }
    },
    [onSendMessage, threadId]
  )

  const { modifiedThread, handleUserInput } = useThreadUI({
    thread,
    handleNewMessage: sendMessage,
  })

  const handleMarkThreadAsSeen = useCallback(() => {
    if (threadId) {
      markThreadAsSeen(threadId)
    }
  }, [threadId])

  const handleOnClose = useCallback(() => {
    if (onClose) {
      handleMarkThreadAsSeen()
      onClose()
    }
  }, [handleMarkThreadAsSeen, onClose])

  const handleOnCloseAfterExitAnimation = useCallback(() => {
    if (!handleOnClose) {
      return
    }

    setTimeout(() => {
      handleOnClose()
    }, 300)
  }, [handleOnClose])

  const handleInsetValuesOfContainer = useCallback(() => {
    const container = containerRef?.current

    if (!container) {
      return
    }

    const bannerWidth = container.offsetWidth
    const bannerHeight = container.offsetHeight

    const topPercentage = calculatePercentage(bannerHeight, height)
    const leftPercentage = calculatePercentage(bannerWidth, width)

    setBannerInsetValue(`${topPercentage}% 0% 0% ${leftPercentage}%`)
  }, [height, setBannerInsetValue, width])

  const openModal = useCallback(() => {
    handleInsetValuesOfContainer()

    setTimeout(() => {
      setIsModalOpen(true)

      animation.openModal()
    }, 50)
  }, [animation, handleInsetValuesOfContainer])

  const closeBanner = useCallback(() => {
    animation.closeBanner()

    handleOnCloseAfterExitAnimation()
  }, [animation, handleOnCloseAfterExitAnimation])

  const closeChat = useCallback(() => {
    animation.closeChat()

    handleOnCloseAfterExitAnimation()
  }, [animation, handleOnCloseAfterExitAnimation])

  useEffect(() => {
    const isRemoteThreadLoading = thread?.inProgress

    setIsNewMessagesLoading(isRemoteThreadLoading)
  }, [thread])

  useEffect(() => {
    setIsInputDisabled(!!isNewMessagesLoading)
  }, [isNewMessagesLoading])

  useEffect(() => {
    if (!isOpen) {
      setIsOpen(true)

      animation.openBanner()
    }
  }, [animation, isOpen])

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={handleOnClose}
      initialFocus={false}
      open={isOpen}
    >
      <div className="min-h-screen">
        <a.div
          onClick={isModalOpen ? closeChat : closeBanner}
          style={styles.mask}
          className="fixed inset-0 z-50 bg-codGray/80"
        />

        <a.div
          style={styles.container}
          className="pointer-events-none fixed left-0 sm:left-auto sm:mx-4 sm:mb-4 rounded-lg shadow-lg ring-1 ring-codGray/40 overflow-hidden z-50"
        >
          <div ref={containerRef}>
            <a.div
              style={styles.banner}
              className="relative w-full h-full text-codGray flex items-stretch"
            >
              <div
                onClick={closeBanner}
                className="absolute top-2 right-2.5 text-codGray cursor-pointer z-10"
              >
                <X className="h-6 w-6" />
              </div>
              <div className="hidden sm:block flex-shrink-0 w-[180px]">
                <img src={image} alt="" className="w-full h-full object-cover" />
              </div>
              <div className="p-8">
                <ReactMarkdown
                  className="text-sm leading-6 max-w-sm sm:max-w-xs"
                  components={{
                    h2: ({ node, ...props }) => (
                      // eslint-disable-next-line jsx-a11y/heading-has-content
                      <h2 {...props} className="mb-3 text-xl font-medium font-p22" />
                    ),
                  }}
                >
                  {content || thread?.messages[0]?.text}
                </ReactMarkdown>
                <div onClick={openModal} className="mt-5 text-codGray">
                  <div className="pointer-events-none flex flex-row items-center w-full bg-white border rounded-full h-12">
                    <div className="w-full mx-0.5">
                      <span className="py-2 px-4 text-[#707784]">
                        {t('coach.label.placeholder')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </a.div>
            <a.div style={styles.chat} className="absolute inset-0">
              <div onClick={closeChat} className="absolute top-3 right-3 cursor-pointer z-10">
                <X className="h-6 w-6" />
              </div>
              <div className="flex flex-col h-full w-full pb-6">
                <div className="h-full pb-2 overflow-hidden">
                  {modifiedThread && (
                    <Mission
                      thread={modifiedThread}
                      handleNewMessage={sendMessage}
                      shouldScrollItself
                      isNewMessagesLoading={isNewMessagesLoading}
                    />
                  )}
                </div>
                <div className="px-2.5">
                  <MChatInput
                    isModalOpen={isModalOpen}
                    handleOnSubmit={handleUserInput}
                    isInputDisabled={isInputDisabled}
                  />
                </div>
              </div>
            </a.div>
          </div>
        </a.div>
      </div>
    </Dialog>
  )
}
