import { useCallback, useRef } from 'react'

export const useQuestionnaire = (onQuestionAnswered = () => {}) => {
  const questionnaireRef = useRef({})

  const updateQuestionnaire = useCallback(
    questionOrAnswer => {
      const {
        questionId,
        questionLabel = '',
        answers = [],
        answerId = null,
        answerLabel = '',
        groupId = '',
        priority = 0,
        color,
        allowMultipleAnswers = false,
      } = questionOrAnswer

      const questionAlreadyExist = Object.keys(questionnaireRef.current).find(
        key => key === questionId
      )
      const isAnswer = !!answerId
      const areAnswers = answers.length

      const isAnswerWithoutQuestion = !questionAlreadyExist && isAnswer
      const isNewQuestion = !questionAlreadyExist && !isAnswer

      if (isAnswerWithoutQuestion) {
        return
      }

      if (isNewQuestion) {
        questionnaireRef.current[questionId] = {
          questionLabel,
          groupId,
          priority,
          allowMultipleAnswers,
          ...(color && { color }),
        }

        return
      }

      const currentQuestion = questionnaireRef.current[questionId]

      if (isAnswer) {
        const { answers = [] } = currentQuestion

        questionnaireRef.current[questionId] = {
          ...currentQuestion,
          answers: [
            ...(currentQuestion.allowMultipleAnswers ? answers : []),
            { id: answerId, label: answerLabel },
          ],
        }

        return onQuestionAnswered({
          questionId,
          ...questionnaireRef.current[questionId],
        })
      }

      if (areAnswers) {
        const { answers: oldAnswers = [] } = currentQuestion

        questionnaireRef.current[questionId] = {
          ...currentQuestion,
          answers: [
            ...(currentQuestion.allowMultipleAnswers ? oldAnswers : []),
            ...answers.map(({ answerId, answerLabel }) => {
              return { id: answerId, label: answerLabel }
            }),
          ],
        }

        return onQuestionAnswered({
          questionId,
          ...questionnaireRef.current[questionId],
        })
      }

      questionnaireRef.current[questionId] = {
        questionLabel,
        groupId,
        priority: currentQuestion.priority,
      }
    },
    [onQuestionAnswered]
  )

  const getQuestionnairePayload = useCallback(questions => {
    if (!questions || questions?.length === 0) {
      return
    }

    const questionnaireId =
      questions.find(({ questionnaireId }) => questionnaireId)?.questionnaireId || null
    const questionnaireAnswers = {}

    questions.forEach(questionOrAnswer => {
      const {
        questionId,
        questionLabel = '',
        answers = [],
        answerId = null,
        answerLabel = '',
        groupId = '',
        priority = 0,
        color,
        allowMultipleAnswers = false,
      } = questionOrAnswer

      const questionAlreadyExist = Object.keys(questionnaireAnswers).find(key => key === questionId)
      const isAnswer = !!answerId
      const areAnswers = answers.length

      const isAnswerWithoutQuestion = !questionAlreadyExist && isAnswer
      const isNewQuestion = !questionAlreadyExist && !isAnswer

      if (isAnswerWithoutQuestion) {
        return
      }

      if (isNewQuestion) {
        questionnaireAnswers[questionId] = {
          questionLabel,
          groupId,
          priority,
          allowMultipleAnswers,
          ...(color && { color }),
        }

        return
      }

      const currentQuestion = questionnaireAnswers[questionId]

      if (isAnswer) {
        const { answers = [] } = currentQuestion

        questionnaireAnswers[questionId] = {
          ...currentQuestion,
          answers: [
            ...(currentQuestion.allowMultipleAnswers ? answers : []),
            { id: answerId, label: answerLabel },
          ],
        }

        return
      }

      if (areAnswers) {
        const { answers: oldAnswers = [] } = currentQuestion

        questionnaireAnswers[questionId] = {
          ...currentQuestion,
          answers: [
            ...(currentQuestion.allowMultipleAnswers ? oldAnswers : []),
            ...answers.map(({ answerId, answerLabel }) => {
              return { id: answerId, label: answerLabel }
            }),
          ],
        }

        return
      }

      questionnaireAnswers[questionId] = {
        questionLabel,
        groupId,
        priority: currentQuestion.priority,
      }
    })

    Object.keys(questionnaireAnswers).forEach(questionKey => {
      const question = questionnaireAnswers[questionKey]
      const isQuestionWithoutAnswers = !question.answers

      if (isQuestionWithoutAnswers) {
        delete questionnaireAnswers[questionKey]
      }
    })

    return {
      questionnaireId,
      questionnaireAnswers,
    }
  }, [])

  return {
    updateQuestionnaire,
    getQuestionnairePayload,
  }
}
