import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import classNames from 'classnames'

import { useForm, Controller } from 'react-hook-form'

import AnimateHeight from 'react-animate-height'

const AutoHeight = ({ children, ...props }) => {
  const [height, setHeight] = useState('auto')
  const contentDiv = useRef(null)

  useEffect(() => {
    const element = contentDiv.current

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight)
    })

    resizeObserver.observe(element)

    return () => resizeObserver.disconnect()
  }, [])

  return (
    <AnimateHeight
      {...props}
      height={height}
      contentClassName="auto-content"
      contentRef={contentDiv}
      disableDisplayNone
    >
      {children}
    </AnimateHeight>
  )
}

const UserInput = ({ onProceed = () => {} }) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      userInput: '',
    },
  })
  const inputValue = watch('userInput')

  const [isSend, setIsSend] = useState(false)

  const hasSomeValue = useMemo(() => {
    return inputValue.length > 0
  }, [inputValue])

  const handleClick = async data => {
    if (!hasSomeValue) {
      return
    }

    const { userInput } = data

    setIsSend(true)
    onProceed(userInput)
  }

  return (
    <form onSubmit={handleSubmit(handleClick)} className="flex">
      <Controller
        control={control}
        rules={{ required: true }}
        name="userInput"
        render={({ field }) => (
          <input
            {...field}
            type="text"
            disabled={isSend}
            maxLength={60}
            placeholder="Type here..."
            className="max-w-[165px] form-input !py-2 !px-4 !border !border-codGray !rounded-l-[10px] focus:!outline-none focus:!shadow-none focus:!ring-0 focus:!ring-codGray"
          />
        )}
      />
      <input
        type="submit"
        value="Submit"
        disabled={!hasSomeValue || isSend}
        className="py-2 px-3 text-gray-50 bg-codGray border border-l-0 border-codGray !rounded-r-[10px] disabled:opacity-80 disabled:cursor-auto cursor-pointer"
      />
    </form>
  )
}

export const MChatUserInput = ({ onProceed = () => {} }) => {
  const [isSend, setIsSend] = useState(false)
  const [value, setValue] = useState('')

  const handleOnProceed = useCallback(
    value => {
      console.log('User input:', value)
      setValue(value)
      setIsSend(true)

      setTimeout(() => {
        onProceed()
      }, 300)
    },
    [onProceed]
  )

  return (
    <div className="flex items-end justify-end ml-auto pb-2 lg:pb-6">
      <div className={classNames('flex flex-col items-end max-w-[240px]')}>
        <AutoHeight duration={300}>
          {isSend ? (
            <div
              className={classNames(
                'py-3 px-3.5 text-base text-left leading-[20px] border rounded-[10px]',
                'text-white bg-green-dark border-green-dark animate-border-radius cursor-auto pointer-events-none'
              )}
            >
              {value}
            </div>
          ) : (
            <div className="h-24">
              <UserInput onProceed={handleOnProceed} />
            </div>
          )}
        </AutoHeight>
      </div>
    </div>
  )
}
