import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Grid } from 'react-loader-spinner'

export const ALoader = ({ height = 60, width = 60, color = 'blue', className }) => {
  return (
    <div
      className={classnames(
        'flex flex-grow flex-1 mx-auto items-center justify-center fill-pelorous',
        {
          [className]: true,
        }
      )}
    >
      <Grid color="current" height={height} width={width} />
    </div>
  )
}

ALoader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
}
