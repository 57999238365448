import React, { useCallback, useState } from 'react'

import { scenarios } from '@utils/constants/scenariosAIHomeView'

import { AButton } from '@atoms/AButton'

export const OAIViewSimulation = ({ onScenarioClick, onCatchupTrigger, onCatchupClear }) => {
  const [currentClickedScenario, setCurrentClickedScenario] = useState()
  const [isScenarioLoading, setIsScenarioLoading] = useState(false)

  const handleScenarioClick = useCallback(
    async scenario => {
      setCurrentClickedScenario(scenario.id)
      setIsScenarioLoading(true)

      try {
        onScenarioClick(JSON.stringify(scenario.data))
      } catch (err) {
        console.error(err)
      } finally {
        setCurrentClickedScenario(null)
        setIsScenarioLoading(false)
      }
    },
    [onScenarioClick]
  )

  const handleCatchupTrigger = useCallback(async () => {
    setCurrentClickedScenario('catchup')
    setIsScenarioLoading(true)

    try {
      await onCatchupTrigger()
    } catch (error) {
      throw new Error(error)
    } finally {
      setCurrentClickedScenario(null)
      setIsScenarioLoading(false)
    }
  }, [onCatchupTrigger])

  const handleCatchupClear = useCallback(async () => {
    setCurrentClickedScenario('clear')
    setIsScenarioLoading(true)

    try {
      await onCatchupClear()
    } catch (error) {
      throw new Error(error)
    } finally {
      setCurrentClickedScenario(null)
      setIsScenarioLoading(false)
    }
  }, [onCatchupClear])

  return (
    <div className="w-full flex flex-col p-4">
      <AButton
        onClick={handleCatchupTrigger}
        isLoading={isScenarioLoading && currentClickedScenario === 'catchup'}
      >
        Trigger catchup
      </AButton>
      <div className="w-full h-px bg-gray-300 my-5" />
      <div className="flex flex-col space-y-3">
        {scenarios.map(scenario => (
          <AButton
            key={scenario.id}
            onClick={() => {
              handleScenarioClick(scenario)
            }}
            isLoading={isScenarioLoading && currentClickedScenario === scenario.id}
          >
            {scenario.name}
          </AButton>
        ))}
      </div>
      <div className="w-full h-px bg-gray-300 my-5" />
      <AButton
        onClick={handleCatchupClear}
        isLoading={isScenarioLoading && currentClickedScenario === 'clear'}
      >
        Clear catchup mission
      </AButton>
    </div>
  )
}
