import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import _ from 'lodash'
import { nanoid } from 'nanoid/non-secure'

let AIHomeStore = null

export const createAIHomeStore = ({ sessionStorage }) => {
  if (AIHomeStore) return AIHomeStore

  AIHomeStore = create(
    persist(
      (set, get) => ({
        requestsQueue: [],
        catchupMission: null,
        isQueueProcessing: false,
        data: [],
        isDataLoaded: true,

        setIsDataLoaded: (isDataLoaded = false) => {
          set({ isDataLoaded })
        },
        setCatchupMission: data => {
          set({ catchupMission: data })
        },
        /**
         *
         * @param data
         */
        setData: data => {
          set({ data })
        },
        /**
         *
         * @param data
         */
        setDataFromJSON: data => {
          set({ data: JSON.parse(data) })
        },
        /**
         *
         * @param task
         */
        addQueueTask: task => {
          const randomId = nanoid()
          const modifiedTask = {
            ...task,
            id: randomId,
          }
          const queue = [...get().requestsQueue, modifiedTask]

          set({ requestsQueue: queue })
        },
        /**
         * Remove task from queue
         * @param {*} taskId
         */
        removeTaskFromQueue: taskId => {
          const tasks = [...get().requestsQueue]
          const modifiedTasks = _.remove(tasks, ({ id }) => id !== taskId)

          set({ requestsQueue: modifiedTasks })
        },
        /**
         * Remove all tasks from queue
         */
        removeAllTasksFromQueue: () => {
          set({ requestsQueue: [] })
        },
        /**
         * Process queue tasks
         * @returns
         */
        processQueue: async () => {
          const isQueueProcessing = get().isQueueProcessing
          const tasks = get().requestsQueue

          if (isQueueProcessing) {
            return
          }

          set({ isQueueProcessing: true })

          // Process tasks

          set({ isQueueProcessing: false })
        },

        clearAIHomeStorage: () => {
          set({
            requestsQueue: [],
            isQueueProcessing: false,
            data: [],
            isDataLoaded: false,
          })
        },
      }),
      {
        name: 'tips-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )

  return AIHomeStore
}

export default AIHomeStore
