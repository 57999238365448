import { forwardRef, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { useTracker, useTranslations } from '@hooks'

import useGoalsStore from '@store/useGoalsStore'

import { useForm, Controller } from 'react-hook-form'

import { PuzzlePieceIcon } from '@heroicons/react/24/outline'
import ReactMarkdown from 'react-markdown'
import { AIconArrowRight } from '@atoms/AIcons'

export const ATrackerButton = ({
  btnText,
  onClick = () => {},
  isActive = false,
  isDisabled = false,
  className = '',
}) => (
  <button
    disabled={isDisabled}
    onClick={onClick}
    className={classnames(
      'relative block mb-2.5 py-1.5 px-3.5 text-left border border-codGray rounded-full hover:text-gray-50 hover:bg-codGray transition-colors',
      isActive ? 'text-gray-50 bg-codGray' : 'text-codGray opacity-90',
      className
    )}
  >
    {btnText}
  </button>
)

export const ATrackerInput = ({ activeValues, type = 'number', onClick = () => {} }) => {
  const { t } = useTranslations()
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      tracker: type === 'number' ? 0 : '',
    },
  })
  const inputValue = watch('tracker')

  const handleClick = async data => {
    const { tracker } = data

    onClick(tracker)
  }

  return (
    <form onSubmit={handleSubmit(handleClick)} className="flex">
      <Controller
        control={control}
        rules={{ required: true }}
        name="tracker"
        render={({ field }) => (
          <input
            {...field}
            type={type}
            min="0"
            placeholder={t(`placeholder.tracker.${type}`)}
            className="max-w-[165px] form-input !py-2 !px-4 !border !border-codGray !rounded-l-full focus:!outline-none focus:!shadow-none focus:!ring-1 focus:!ring-codGray"
          />
        )}
      />
      <input
        type="submit"
        value="Submit"
        disabled={activeValues?.some(value => value === inputValue)}
        className="py-2 px-3 text-gray-50 bg-codGray border border-l-0 border-codGray !rounded-r-full disabled:opacity-80 disabled:cursor-auto cursor-pointer"
      />
    </form>
  )
}

export const MTrackerBoolean = ({
  id,
  type = 'boolean',
  typeMeta,
  isTracked = false,
  trackedData = [],
  allowMultipleTrackingsPerInstance = false,
  handleOnTracked = () => {},
}) => {
  const { activeValues, disabledValues, createTrackerPayload } = useTracker({
    id,
    type,
    typeMeta,
    isTracked,
    trackedData,
    allowMultipleTrackingsPerInstance,
  })
  const { trueLabel = '', falseLabel = '' } = typeMeta

  const handleClick = useCallback(
    value => {
      const payload = createTrackerPayload(value)

      handleOnTracked(payload)
    },
    [createTrackerPayload, handleOnTracked]
  )

  return (
    <div className="mt-5 mb-2 pl-4">
      <ATrackerButton
        btnText={trueLabel}
        onClick={() => handleClick(true)}
        isActive={activeValues.includes(true)}
        isDisabled={disabledValues.includes(true)}
      />
      {!!falseLabel && (
        <ATrackerButton
          btnText={falseLabel}
          onClick={() => handleClick(false)}
          isActive={activeValues.includes(false)}
          isDisabled={disabledValues.includes(false)}
        />
      )}
    </div>
  )
}

export const MTrackerNumber = ({
  id,
  type = 'number',
  typeMeta,
  isTracked = false,
  trackedData = [],
  allowMultipleTrackingsPerInstance = false,
  handleOnTracked = () => {},
}) => {
  const { activeValues, disabledValues, createTrackerPayload } = useTracker({
    id,
    type,
    typeMeta,
    isTracked,
    trackedData,
    allowMultipleTrackingsPerInstance,
  })
  const { predefinedNumbers: values, unit = null, isUserInput = false } = typeMeta

  const getLabel = useCallback(
    (value, label) => {
      const labelOrValue = label || value

      if (unit) {
        const { oneLabel = '', multipleLabel = '' } = unit
        const isValueEqualToOne = value === 1

        return `${labelOrValue} ${isValueEqualToOne ? oneLabel : multipleLabel}`
      }

      return labelOrValue
    },
    [unit]
  )

  const handleClick = useCallback(
    value => {
      const payload = createTrackerPayload(value)

      handleOnTracked(payload)
    },
    [createTrackerPayload, handleOnTracked]
  )

  if (isUserInput) {
    return (
      <div className="flex flex-col mt-5 mb-2 px-4">
        <ATrackerInput activeValues={activeValues} type="number" onClick={handleClick} />
      </div>
    )
  }

  return (
    <div className="flex flex-col mt-5 mb-2 pl-4 pr-10">
      {values?.map(({ value, label }, index) => (
        <ATrackerButton
          key={index}
          btnText={getLabel(value, label)}
          onClick={() => handleClick(value)}
          isActive={activeValues.includes(value)}
          isDisabled={disabledValues.includes(value)}
          className="text-left"
        />
      ))}
    </div>
  )
}

export const MTrackerText = ({
  id,
  type = 'text',
  typeMeta,
  isTracked = false,
  trackedData = [],
  allowMultipleTrackingsPerInstance = false,
  handleOnTracked = () => {},
}) => {
  const { activeValues, disabledValues, createTrackerPayload } = useTracker({
    id,
    type,
    typeMeta,
    isTracked,
    trackedData,
    allowMultipleTrackingsPerInstance,
  })
  const { predefinedValues: values, isUserInput = false } = typeMeta

  const handleClick = useCallback(
    value => {
      const payload = createTrackerPayload(value)

      handleOnTracked(payload)
    },
    [createTrackerPayload, handleOnTracked]
  )

  if (isUserInput) {
    return (
      <div className="flex flex-col mt-5 mb-2 px-4">
        <ATrackerInput type="text" onClick={handleClick} />
      </div>
    )
  }

  return (
    <div className="flex flex-col mt-5 mb-2 pl-4 pr-10">
      {values?.map(({ value, label }, index) => (
        <ATrackerButton
          key={index}
          btnText={label || value}
          onClick={() => handleClick(value)}
          isActive={activeValues.includes(value)}
          isDisabled={disabledValues.includes(value)}
          className="text-left"
        />
      ))}
    </div>
  )
}

export const OTracker = forwardRef((props, ref) => {
  const { id, goal = {}, isTracked, trackedData = [], className } = props
  const { title, prompt, description, type, typeMeta, allowMultipleTrackingsPerInstance } = goal

  const updateGoalInstance = useGoalsStore(store => store.updateGoalInstance)

  const configTrackers = {
    boolean: MTrackerBoolean,
    number: MTrackerNumber,
    text: MTrackerText,
  }

  const TrackerComponent = configTrackers[type]

  const isInstructionsAvailable = useMemo(() => false, [])

  const handleOnTracked = useCallback(
    changes => {
      const payload = {
        ...props,
        ...changes,
      }

      updateGoalInstance(id, payload, true, { payload: changes })
    },
    [id, props, updateGoalInstance]
  )

  if (!TrackerComponent) return null

  return (
    <div
      ref={ref}
      className={classnames(
        'relative w-[276px] pt-3.5 pb-1 bg-gray-50 shadow-md rounded-lg',
        className
      )}
    >
      <div className="px-4">
        <div className="flex-shrink-0 flex items-center justify-center w-10 h-10 mr-4 text-gray-50 bg-codGray rounded-full">
          <PuzzlePieceIcon className="w-6" />
        </div>
        <ReactMarkdown className="mt-3 text-xl font-p22 font-medium">
          {prompt || title}
        </ReactMarkdown>
      </div>
      <TrackerComponent
        id={id}
        type={type}
        typeMeta={typeMeta}
        isTracked={isTracked}
        trackedData={trackedData}
        handleOnTracked={handleOnTracked}
        allowMultipleTrackingsPerInstance={allowMultipleTrackingsPerInstance}
      />
      {isInstructionsAvailable && (
        <div className="flex justify-end pt-1.5 pb-1 px-4 border-t border-gray-300">
          <div onClick={() => {}} className="flex items-center cursor-pointer">
            <div className="text-sm text-gray-700">View Instructions</div>
            <AIconArrowRight />
          </div>
        </div>
      )}
    </div>
  )
})
