import { useCallback, useState, useMemo } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { useUser } from '@edwin/react-sdk'

import ReactMarkdown from 'react-markdown'
import { MChatAudioBtn } from './MChatAudioBtn'
import { MChatAudioTranscript } from './MChatAudioTranscript'
import { MChatModal } from './MChatModal'
import { OInnerImageZoom } from '../organisms/OInnerImageZoom'
import { useAudio } from '../../utils/useAudio'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatAudio = ({ coverImage, description, transcriptMarkdown, audiofileLink }) => {
  const { user, assistant } = useUser()
  const { status, leftTimeLabel, toggleAudio } = useAudio(audiofileLink)
  const [isImageZoomOpen, setIsImageZoomOpen] = useState(false)

  const openImageZoom = useCallback(() => {
    setIsImageZoomOpen(true)
  }, [setIsImageZoomOpen])

  const closeImageZoom = useCallback(() => {
    setIsImageZoomOpen(false)
  }, [setIsImageZoomOpen])

  const isPossibleToZoom = useMemo(() => {
    return coverImage && coverImage.slice(-4) !== '.gif'
  }, [coverImage])

  return (
    <div className="max-w-72">
      {coverImage && (
        <div
          onClick={isPossibleToZoom ? openImageZoom : null}
          className={classNames(isPossibleToZoom && 'cursor-pointer')}
        >
          <img src={coverImage} alt="" className="w-full h-full max-h-72 object-cover m-0 p-0" />
        </div>
      )}
      <div className="py-3 px-3.5">
        <div className="text-xl">
          {description && (
            <ReactMarkdown
              components={{
                a: ({ href, children }) => (
                  <a className="underline " href={href} target="_blank" rel="noreferrer">
                    {children[0]}
                  </a>
                ),
              }}
            >
              {_.template(description)({ user, assistant, input: assistant })}
            </ReactMarkdown>
          )}
        </div>
        <div
          className={classNames(
            'flex items-center',
            transcriptMarkdown ? 'justify-between' : 'justify-end',
            (coverImage || description) && 'mt-2'
          )}
        >
          {transcriptMarkdown && <MChatAudioTranscript transcriptMarkdown={transcriptMarkdown} />}
          <MChatAudioBtn
            playbackState={status}
            playbackTimeToFinish={leftTimeLabel}
            onClick={toggleAudio}
          />
        </div>
      </div>
      {isPossibleToZoom && (
        <MChatModal isOpen={isImageZoomOpen} onClose={closeImageZoom}>
          <OInnerImageZoom src={coverImage} onClose={closeImageZoom} />
        </MChatModal>
      )}
    </div>
  )
}
