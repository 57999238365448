import { useMemo } from 'react'
import classnames from 'classnames'

import { DateTime } from 'luxon'

import { removeMarkdown, formatLuxonDate } from '@utils/helpers'

import { ALoader } from '@atoms/ALoader'

export const MChatMenuItem = ({
  thread,
  currentThreadId,
  onItemClick = () => {},
  deleteThread = () => {},
  deletingThread = null,
}) => {
  const isThreadCurrent = useMemo(
    () => thread?.id === currentThreadId,
    [thread?.id, currentThreadId]
  )

  const lastEditedDate = useMemo(() => {
    const date = thread?.updatedAt || thread?.createdAt

    return date ? DateTime.fromISO(date).toRelativeCalendar({ locale: 'en', unit: 'days' }) : null
  }, [thread?.createdAt, thread?.updatedAt])

  const lastWeekDate = useMemo(() => {
    const hasMetadata = Object.keys(thread?.metadata || {}).length > 0

    const lastWeek = hasMetadata ? thread?.metadata?.values?.lastWeek : {}

    const { startsOn: lastWeekStartsOn, endsOn: lastWeekEndsOn } = lastWeek

    return hasMetadata
      ? `${formatLuxonDate(lastWeekStartsOn)} - ${formatLuxonDate(lastWeekEndsOn)}`
      : null
  }, [thread?.metadata])

  const firstThreadMessage = useMemo(() => {
    const firstMessage = thread?.messages?.[0]

    return removeMarkdown(firstMessage?.componentOptions?.title || firstMessage?.text)
  }, [thread?.messages])

  const lastThreadMessage = useMemo(() => {
    const lastMessage = thread?.messages?.[thread?.messages?.length - 1]
    const lastMessageName = lastMessage?.data?.name

    if (lastMessageName === 'display-goal') {
      return 'Goal'
    }

    if (lastMessageName === 'display-multiple-goals') {
      return 'List of goals'
    }

    if (lastMessageName === 'create-goal') {
      return 'Create goal'
    }

    if (lastMessageName === 'display-mission') {
      return 'Mission'
    }

    if (lastMessageName === 'display-multiple-missions') {
      return 'List of missions'
    }

    if (thread?.messages?.length > 1 || (lastMessage && thread?.type === 'insights')) {
      return removeMarkdown(lastMessage?.text)
    }

    return null
  }, [thread?.messages, thread?.type])

  const threadHeading = useMemo(() => {
    if (thread?.type === 'insights') {
      return 'Weekly insights'
    }

    return thread?.title || firstThreadMessage
  }, [firstThreadMessage, thread?.title, thread?.type])

  return (
    <>
      <div
        onClick={() => onItemClick(thread?.id)}
        className={classnames(
          'relative flex flex-row items-center py-3 px-2.5 lg:p-4 border-l-2',
          thread?.id === currentThreadId
            ? 'bg-gradient-to-r from-green/40 to-transparent border-green-dark/60'
            : 'border-transparent',
          thread?.id === deletingThread ? 'pointer-events-none' : 'cursor-pointer'
        )}
      >
        <div
          className={classnames(
            'flex flex-col flex-grow',
            thread?.id === deletingThread && 'opacity-20'
          )}
        >
          {lastWeekDate && <div className="mb-1 text-xs text-codGray/60">{lastWeekDate}</div>}
          <div className="flex items-center">
            <div className="w-52 lg:w-64 text-sm font-semibold truncate">{threadHeading}</div>
            {thread?.isActive && <div className="h-2 w-2 rounded-full bg-green-500 ml-2" />}
            <div
              onClick={e => {
                e.stopPropagation()

                deleteThread(thread?.id)
              }}
              className="ml-auto p-0.5 text-persianRed"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
          </div>
          <div className="flex items-baseline justify-between mt-1">
            <div className="text-xs truncate w-44">{lastThreadMessage}</div>
            {lastEditedDate && <div className="text-xs text-gray-500">{lastEditedDate}</div>}
          </div>
        </div>
        {thread?.id === deletingThread && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <ALoader width={32} height={32} />
          </div>
        )}
      </div>
      <div className="w-full min-h-[1px] bg-gray-200 last:hidden" />
    </>
  )
}
