import { useEffect } from 'react'
import { useIsOnline } from '@hooks/useIsOnline'

import useQuestionnaireStore from '@store/useQuestionnaireStore'

export const useQuestionnaireQueue = (isSimulatedProgress = false) => {
  const isOnline = useIsOnline()

  const processQueue = useQuestionnaireStore(state => state.processQueue)
  const requestsQueue = useQuestionnaireStore(state => state.requestsQueue)

  useEffect(() => {
    if (isOnline && !isSimulatedProgress) {
      processQueue()
    }
  }, [processQueue, requestsQueue, isOnline, isSimulatedProgress])
}
