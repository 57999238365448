const TAILWIND_PREFLIGHT = containerClass => [
  `
      .${containerClass} {
        -moz-tab-size: 4;
        tab-size: 4;
        -webkit-text-size-adjust: 100%;
        margin: 0;
        line-height: inherit;
      }
    `,
  `
      .${containerClass} *,
      .${containerClass} ::before,
      .${containerClass} ::after {
        box-sizing: border-box;
      }
    `,
  `
      .${containerClass} hr {
        height: 0;
        color: inherit;
      }
    `,
  `
      .${containerClass} abbr[title] {
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
      }
    `,
  `
      .${containerClass} b,
      .${containerClass} strong {
        font-weight: bolder;
      }
    `,
  `
      .${containerClass} code,
      .${containerClass} kbd,
      .${containerClass} samp,
      .${containerClass} pre {
        font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
        font-size: 1em;
      }
    `,
  `
      .${containerClass} small {
        font-size: 80%;
      }
    `,
  `
      .${containerClass} sub,
      .${containerClass} sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
      }
    `,
  `
      .${containerClass} sub {
        bottom: -0.25em;
      }
    `,
  `
      .${containerClass} sup {
        top: -0.5em;
      }
    `,
  `
      .${containerClass} table {
        text-indent: 0;
        border-color: inherit;
      }
    `,
  `
      .${containerClass} button,
      .${containerClass} input,
      .${containerClass} optgroup,
      .${containerClass} select,
      .${containerClass} textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
      }
    `,
  `
      .${containerClass} button,
      .${containerClass} select {
        text-transform: none;
      }
    `,
  `
      .${containerClass} button,
      .${containerClass} [type='button'],
      .${containerClass} [type='reset'],
      .${containerClass} [type='submit'] {
        -webkit-appearance: button;
      }
    `,
  // `
  //   .${containerClass} ::-moz-focus-inner {
  //     border-style: none;
  //     padding: 0;
  //   }
  // `,
  // `
  //   .${containerClass} :-moz-focusring {
  //     outline: 1px dotted ButtonText;
  //   }
  // `,
  // `
  //   .${containerClass} :-moz-ui-invalid {
  //     box-shadow: none;
  //   }
  // `,
  `
      .${containerClass} legend {
        padding: 0;
      }
    `,
  `
      .${containerClass} progress {
        vertical-align: baseline;
      }
    `,
  `
      .${containerClass} ::-webkit-inner-spin-button,
      .${containerClass} ::-webkit-outer-spin-button {
        height: auto;
      }
    `,
  `
      .${containerClass} [type='search'] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
      }
    `,
  `
      .${containerClass} ::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    `,
  `
      .${containerClass} ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
      }
    `,
  `
      .${containerClass} summary {
        display: list-item;
      }
    `,
  `
      .${containerClass} blockquote,
      .${containerClass} dl,
      .${containerClass} dd,
      .${containerClass} h1,
      .${containerClass} h2,
      .${containerClass} h3,
      .${containerClass} h4,
      .${containerClass} h5,
      .${containerClass} h6,
      .${containerClass} hr,
      .${containerClass} figure,
      .${containerClass} p,
      .${containerClass} pre {
        margin: 0;
      }
    `,
  `
      .${containerClass} button {
        background-color: transparent;
        background-image: none;
      }
    `,
  `
      .${containerClass} fieldset {
        margin: 0;
        padding: 0;
      }
    `,
  `
      .${containerClass} ol,
      .${containerClass} ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    `,
  `
      .${containerClass} *,
      .${containerClass} ::before,
      .${containerClass} ::after {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
        border-color: currentColor;
      }
    `,
  `
      .${containerClass} hr {
        border-top-width: 1px;
      }
    `,
  `
      .${containerClass} img {
        border-style: solid;
      }
    `,
  `
      .${containerClass} textarea {
        resize: vertical;
      }
    `,
  `
      .${containerClass} input::placeholder,
      .${containerClass} textarea::placeholder {
        opacity: 1;
        color: #9ca3af;
      }
    `,
  `
      .${containerClass} button,
      .${containerClass} [role='button'] {
        cursor: pointer;
      }
    `,
  `
      .${containerClass} table {
        border-collapse: collapse;
      }
    `,
  `
      .${containerClass} h1,
      .${containerClass} h2,
      .${containerClass} h3,
      .${containerClass} h4,
      .${containerClass} h5,
      .${containerClass} h6 {
        font-size: inherit;
        font-weight: inherit;
      }
    `,
  `
      .${containerClass} a {
        color: inherit;
        text-decoration: inherit;
      }
    `,
  `
      .${containerClass} button,
      .${containerClass} input,
      .${containerClass} optgroup,
      .${containerClass} select,
      .${containerClass} textarea {
        padding: 0;
        line-height: inherit;
        color: inherit;
      }
    `,
  `
      .${containerClass} pre,
      .${containerClass} code,
      .${containerClass} kbd,
      .${containerClass} samp {
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
          'Courier New', monospace;
      }
    `,
  `
      .${containerClass} img,
      .${containerClass} svg,
      .${containerClass} video,
      .${containerClass} canvas,
      .${containerClass} audio,
      .${containerClass} iframe,
      .${containerClass} embed,
      .${containerClass} object {
        display: block;
        vertical-align: middle;
      }
    `,
  `
      .${containerClass} img,
      .${containerClass} video {
        max-width: 100%;
        height: auto;
      }
    `,
  `
      .${containerClass} *,
      .${containerClass} ::before,
      .${containerClass} ::after {
        --tw-border-opacity: 1;
        border-color: rgba(229, 231, 235, var(--tw-border-opacity));
      }
    `,
]

export const applyTailwindPreflightStyles = containerClass => {
  const styleSheets = document.styleSheets
  const isCustomStyleSheetApplied = Object.keys(styleSheets).some(
    key => styleSheets[key].title === containerClass
  )

  if (!isCustomStyleSheetApplied) {
    const styles = TAILWIND_PREFLIGHT(containerClass)

    const style = document.createElement('style')
    style.setAttribute('title', containerClass)

    document.head.appendChild(style)

    styles.map(styleRule => style.sheet.insertRule(styleRule))
  }
}
