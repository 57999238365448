import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useHandleScrollbarSpace, useTranslations } from '@hooks/'
import { useCatchupConversationCompleted } from '@services/Catchup'

import useAIHomeStore from '@store/useAIHomeStore'

import { ROUTES } from '@const/Routes'

import { Mission } from '@edwinsandbox/react-web'
import { OMissionSkeleton } from '@organisms/OMissionSkeleton'

export const PCatchup = ({ isLoading }) => {
  const { t } = useTranslations()
  const navigate = useNavigate()

  const [isDataSending, setIsDataSending] = useState(false)

  const catchupMission = useAIHomeStore(state => state.catchupMission)
  const setIsDataLoaded = useAIHomeStore(state => state.setIsDataLoaded)
  const setCatchupMission = useAIHomeStore(state => state.setCatchupMission)

  const { markCatchupConversationAsCompleted } = useCatchupConversationCompleted()

  useHandleScrollbarSpace()

  const handleFinish = useCallback(async () => {
    setIsDataSending(true)

    try {
      await markCatchupConversationAsCompleted()
    } catch (e) {
      console.error(e)
    } finally {
      setIsDataLoaded(false)
      navigate(ROUTES.AI)
      setIsDataSending(false)
    }
  }, [markCatchupConversationAsCompleted, navigate, setIsDataLoaded])

  useEffect(() => {
    if (!catchupMission && !isDataSending) {
      navigate(ROUTES.ROOT)
    }
  }, [catchupMission, isDataSending, navigate])

  if (isLoading) {
    return <OMissionSkeleton />
  }

  if (!catchupMission) {
    return null
  }

  return (
    <Mission
      mission={catchupMission}
      onFinish={handleFinish}
      missionTitle={catchupMission?.title}
      translations={{
        author: t('mission.author'),
        steps: t('mission.steps'),
        congrats: t('summaryComponent.congrats'),
        description: t('summaryComponent.description'),
        completed: t('summaryComponent.completed'),
        ctaText: t('summaryComponent.ctaText'),
      }}
      shouldDisplayCover={false}
      shouldDisplaySidebar={false}
      shouldDisplayCloseIcon={false}
    />
  )
}
