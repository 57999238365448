import React, { useState } from 'react'
import classnames from 'classnames'

const AButton = ({
  icon,
  overwriteStyles = false,
  isLoading,
  className = '',
  children,
  type = 'button',
  fullBorderRadius = false,
  primary = true,
  outline = false,
  small = false,
  disabled = false,
  warning = false,
  onClick = () => {},
  ...props
}) => {
  const buttonClassNames = overwriteStyles
    ? className
    : `flex justify-center px-3 py-1.5 text-sm leading-6 rounded-full focus:outline-none ${
        primary && !outline && !warning
          ? 'border border-green-dark bg-green-dark text-white shadow-sm focus:ring-2 focus:ring-offset-1 focus:ring-green-dark'
          : ''
      } ${
        outline
          ? 'border-codGray text-codGray hover:text-white hover:bg-green-dark focus:ring-2 focus:ring-green-dark focus:ring-offset-1 focus:text-white focus:bg-green-dark'
          : ''
      } ${outline && (small ? 'border' : 'border-2')} ${
        warning ? 'text-white focus:outline-none border-red-400 text-red-400' : ''
      } ${className || ''} ${disabled ? 'cursor-default' : ''}`

  return (
    <button
      onClick={!isLoading ? onClick : undefined}
      type={type}
      className={classnames(buttonClassNames, isLoading ? 'bg-opacity-50 pointer-events-none' : '')}
      disabled={disabled || isLoading}
      {...props}
    >
      {Boolean(icon) && <div className="mr-2">{icon}</div>}
      <span className="text-center w-full inline-block">{children}</span>
    </button>
  )
}

const TrackerAction = ({ type, typeMeta, onProceed }) => {
  const [activeId, setActiveId] = useState(null)

  const { predefinedValues: values, isUserInput = false } = typeMeta || {}

  if (isUserInput) {
    return (
      <form
        onSubmit={e => {
          e.preventDefault()
          setActiveId(1)
          onProceed()
        }}
        className="flex"
      >
        <input
          disabled={!!activeId}
          type="text"
          placeholder="Type here..."
          className="form-input !py-2 !px-4 !border !border-codGray !rounded-l-full focus:!outline-none focus:!shadow-none focus:!ring-1 focus:!ring-codGray"
        />
        <input
          disabled={!!activeId}
          type="submit"
          value="Submit"
          className="py-2 px-3 text-gray-50 bg-codGray border border-l-0 border-codGray !rounded-r-full disabled:opacity-80 disabled:cursor-auto cursor-pointer"
        />
      </form>
    )
  }

  if (type === 'boolean') {
    const trackerValues = [
      { id: 1, title: '👍 Yes, I did' },
      { id: 2, title: "👎 No, I didn't" },
    ]

    return (
      <div className="flex flex-nowrap gap-x-1">
        {trackerValues.map(value => (
          <AButton
            key={value.id}
            small
            onClick={() => {
              setActiveId(value.id)
              onProceed()
            }}
            outline={activeId !== value.id}
          >
            {value.title}
          </AButton>
        ))}
      </div>
    )
  }

  return (
    <div className="flex flex-nowrap gap-x-1">
      {values.map(value => (
        <AButton
          key={value.id}
          small
          onClick={() => {
            setActiveId(value.id)
            onProceed()
          }}
          outline={activeId !== value.id}
          className="whitespace-nowrap"
          disabled={!!activeId}
        >
          {value.title}
        </AButton>
      ))}
    </div>
  )
}

export const MChatTracker = ({ title, description, type, typeMeta, onProceed = () => {} }) => {
  return (
    <div className="py-3 px-3.5">
      {title && <h3 className="mb-2 text-[20px] leading-[24px] font-medium font-p22">{title}</h3>}
      {description && <p className="mb-4 text-sm text-gray-600">{description}</p>}
      <TrackerAction type={type} typeMeta={typeMeta} onProceed={onProceed} />
    </div>
  )
}
