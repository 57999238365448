import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'

import useAIHomeStore from '@store/useAIHomeStore'

import { OPageWrapper } from '@organisms/OPageWrapper'
import { MAIViewGoalSuggestion } from '@molecules/MAIViewGoalSuggestion'
import { MAIViewProgress } from '@molecules/MAIViewProgress'
import { MAIViewDailyTips } from '@molecules/MAIViewDailyTips'
import { MAIViewMission } from '@molecules/MAIViewMission'
import { MAIViewTracker } from '@molecules/MAIViewTracker'

export const PAI = ({ isLoading }) => {
  const data = useAIHomeStore(state => state.data)
  const isDataLoaded = useAIHomeStore(state => state.isDataLoaded)
  const setIsDataLoaded = useAIHomeStore(state => state.setIsDataLoaded)

  const componentsConfig = useMemo(() => {
    return {
      goals: MAIViewGoalSuggestion,
      progress: MAIViewProgress,
      tips: MAIViewDailyTips,
      missions: MAIViewMission,
      trackers: MAIViewTracker,
    }
  }, [])

  const isPageLoading = useMemo(() => {
    return isLoading || !isDataLoaded
  }, [isLoading, isDataLoaded])

  const isDataEmpty = useMemo(() => {
    return !data || data.length === 0
  }, [data])

  useEffect(() => {
    if (!isDataLoaded) {
      setTimeout(() => {
        setIsDataLoaded(true)
      }, 2000)
    }
  }, [isDataLoaded, setIsDataLoaded])

  if (isDataEmpty) {
    return (
      <OPageWrapper isLoading={isPageLoading} contentClassName="flex justify-center items-center">
        <h4 className="font-p22 text-xl text-center">
          There is noting to do today. <br /> Please check back later.
        </h4>
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper isLoading={isPageLoading}>
      {data.map(item => {
        const SectionMetaComponent = componentsConfig[item.componentType]

        return (
          <div key={item.id} className="mb-10">
            <div className={classnames((item.title || item.description) && 'mb-4')}>
              <h3 className="text-xl font-p22 font-medium leading-6 text-gray-900">{item.title}</h3>
              {item.description && <p className="text-sm text-gray-500 mt-1">{item.description}</p>}
            </div>
            {SectionMetaComponent && <SectionMetaComponent meta={item.componentMeta} />}
          </div>
        )
      })}
    </OPageWrapper>
  )
}
