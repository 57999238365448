import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import { useUser } from '@edwin/react-sdk'

import ReactMarkdown from 'react-markdown'
import { AButton } from '../atoms/AButton'

export const MChatQuestionnaireAnswersSelectable = ({
  questionnaireId,
  questionId,
  questionLabel,
  description,
  groupId,
  color,
  answers,
  priority,
  allowMultipleAnswers,
  onProceed = () => {},
  onQuestion = () => {},
}) => {
  const { user, assistant } = useUser()

  const [checkedChoices, setCheckedChoices] = useState(answers.map(() => false))
  const [isFinished, setIsFinished] = useState(false)
  const [isAtLeastOneSelected, setIsAtLeastOneSelected] = useState(false)

  useEffect(() => {
    setIsAtLeastOneSelected(Object.values(checkedChoices).some(value => !!value))
  }, [checkedChoices])

  const toggleChoiceAtIndex = index => {
    const newcheckedChoices = checkedChoices.map(value => (allowMultipleAnswers ? value : false))
    newcheckedChoices[index] = !newcheckedChoices[index]

    setCheckedChoices(newcheckedChoices)
  }

  const question = useMemo(() => {
    return {
      questionnaireId,
      questionId,
      questionLabel,
      groupId,
      priority,
      allowMultipleAnswers,
      color,
    }
  }, [allowMultipleAnswers, color, groupId, priority, questionId, questionLabel, questionnaireId])

  const checkedAnswers = useMemo(() => {
    return checkedChoices
      .map((bool, index) => (bool ? answers[index] : false))
      .filter(item => !!item)
  }, [answers, checkedChoices])

  const handleOnProceed = useCallback(() => {
    if (isAtLeastOneSelected) {
      onQuestion(question) // register question
      onQuestion({
        ...question,
        answers: checkedAnswers,
      }) // answer the question

      onProceed()
      setIsFinished(true)
    } else {
      alert('Select something first')
    }
  }, [checkedAnswers, isAtLeastOneSelected, onProceed, onQuestion, question])

  return (
    <div className="flex flex-col py-3 px-3.5 lg:px-5">
      {questionLabel && <h4 className="text-lg font-medium mb-3">{questionLabel}</h4>}
      {description && (
        <ReactMarkdown className="mb-3.5">
          {_.template(description)({ user, assistant, input: assistant })}
        </ReactMarkdown>
      )}
      <ul>
        {answers.map(({ answerId, answerLabel }, index) => (
          <li key={index} className="flex items-start mb-2.5">
            <div className="flex h-5 items-center">
              <input
                id={answerId}
                name={answerId}
                type="checkbox"
                disabled={isFinished}
                checked={checkedChoices[index]}
                onChange={() => {
                  toggleChoiceAtIndex(index)
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    toggleChoiceAtIndex(index)
                  }
                }}
                className="h-4 w-4 rounded border-2 border-silver text-codGray focus:ring-codGray cursor-pointer checked:border-codGray disabled:cursor-auto"
              />
            </div>
            <label htmlFor={answerId} className="ml-3">
              <ReactMarkdown
                className={classnames('text-sm font-medium', !isFinished ? 'cursor-pointer' : '')}
              >
                {_.template(answerLabel || answerId)({ user, assistant, input: assistant })}
              </ReactMarkdown>
            </label>
          </li>
        ))}
      </ul>
      <div className="mt-2 flex justify-center">
        {!isFinished && (
          <AButton
            disabled={!isAtLeastOneSelected}
            outline={!isAtLeastOneSelected}
            onClick={handleOnProceed}
          >
            Send Response
          </AButton>
        )}
      </div>
    </div>
  )
}
