import React, { forwardRef, useCallback, useState } from 'react'
import { useSpring, useTrail, a } from '@react-spring/web'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'

import { generateRoute, ROUTES } from '@const/Routes'
import { useTranslations } from '@hooks'

import { MSlider } from '@molecules/MSlider'

const Trail = ({ open, children }) => {
  const items = React.Children.toArray(children)

  const trail = useTrail(items.length, {
    opacity: open ? 1 : 0,
    x: open ? 0 : 30,
    delay: open ? 400 : 0,
    config: { mass: 5, tension: 2000, friction: 200 },
  })

  const { transform } = useSpring({
    transform: `scaleY(${open ? 1.15 : 1})`,
  })

  return (
    <a.div style={{ transform }} className="w-full">
      {trail.map((style, index) => (
        <a.div
          key={index}
          className="relative w-full h-7 text-[20px] font-p22 overflow-hidden text-center will-change-transform"
          style={style}
        >
          {items[index]}
        </a.div>
      ))}
    </a.div>
  )
}

export const SwiperItem = forwardRef(
  ({ tip, activeItem = false, index, className, onClick = () => {} }, ref) => {
    const navigate = useNavigate()
    const { t } = useTranslations()

    const styles = useSpring({
      width: activeItem ? 200 : 170,
      // height: activeItem ? 225 : 190,
      transform: `scaleY(${activeItem ? 1 : 0.85})`,
    })

    const { opacity, transform } = useSpring({
      opacity: activeItem ? 0 : 1,
      transform: `scaleY(${activeItem ? 1 : 1.1})`,
      delay: activeItem ? 350 : 0,
    })

    const handleClickMission = useCallback(
      missionId => {
        navigate(generateRoute(ROUTES.MISSION, { undefined, missionId }))
      },
      [navigate]
    )

    return (
      <a.div
        ref={ref}
        onClick={() => onClick(index)}
        style={styles}
        className={classnames(
          'relative flex-shrink-0 rounded-lg !rounded-tl-none shadow-md cursor-pointer overflow-hidden',
          activeItem ? 'bg-codGray/90' : 'bg-gray-50',
          activeItem ? 'text-gray-50' : 'text-codGray',
          // activeItem ? '' : 'border-[14px] border-codGray',
          className
        )}
      >
        {/* {activeItem && <div className="absolute inset-0 backdrop-brightness-50" />} */}
        <div className="absolute inset-0 py-6 px-3 flex items-center mx-auto">
          <Trail open={!activeItem}>
            {tip.title.split(' ').map(word => (
              <span key={word}>{word}</span>
            ))}
          </Trail>
        </div>
        <a.div
          style={{ opacity: opacity.to(o => 1 - o), transform }}
          className="relative flex flex-col justify-center w-[200px] h-full pt-5 px-4 pb-3"
        >
          <div className="mb-4 text-xl font-bold font-p22">{tip.title}</div>
          <div className="text-sm">{tip.description}</div>
          <div>
            {tip.isMissionAvailable && (
              <button
                className="mt-8 py-1 px-2.5 text-sm border border-gray-50 rounded-full hover:bg-gray-50 hover:text-codGray"
                onClick={() => {
                  if (activeItem) {
                    handleClickMission(tip.missionId)
                  }
                }}
              >
                {t('tips.revisitMission')}
              </button>
            )}
          </div>
        </a.div>
      </a.div>
    )
  }
)

export const OTodayTips = ({ availableTips }) => {
  const ACTIVE_ITEM = 0

  const [isActive, setIsActive] = useState(ACTIVE_ITEM)

  return (
    <MSlider
      arrows="none"
      startIndex={ACTIVE_ITEM}
      className="mt-4 -mx-3 sm:-mx-5 lg:mx-0 overflow-x-hidden"
      sliderClassName="px-3 sm:px-5 lg:p-0 !pb-3 md:!snap-x"
      // leftPaddingPlaceholder={<div className="ml-3 sm:ml-5 lg:ml-44 -mr-1.5" />}
    >
      {availableTips?.map((tip, index) => (
        <SwiperItem
          key={tip.id}
          tip={tip}
          index={index}
          activeItem={index === isActive}
          onClick={index => setIsActive(index)}
          className={classnames('snap-center flex-shrink-0 mx-1.5 first:ml-0 last:mr-0')}
        />
      ))}
    </MSlider>
  )
}
