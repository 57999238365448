import { useCallback, useEffect, useMemo } from 'react'

import { useGoal } from '@edwin/react-sdk'

import { useStore, useEphemeralStore } from '@store/useStore'
import useProgressStore from '@store/useProgressStore'
import useGoalsStore from '@store/useGoalsStore'
import useTipsStore from '@store/useTipsStore'
import useQuestionnaireStore from '@store/useQuestionnaireStore'

const useCommonFunctions = () => {
  const clearUserQueue = useStore(state => state.removeAllTasksFromQueue)
  const clearQuestionnaireQueue = useQuestionnaireStore(state => state.removeAllTasksFromQueue)

  const setUserProgress = useProgressStore(state => state.setUserProgress)
  const clearProgressQueue = useProgressStore(state => state.removeAllTasksFromQueue)

  const setGoals = useGoalsStore(state => state.setGoals)
  const setGoalInstances = useGoalsStore(state => state.setGoalInstances)
  const clearGoalsQueue = useGoalsStore(state => state.removeAllTasksFromQueue)

  const setTips = useTipsStore(state => state.setTips)
  const clearTipsQueue = useTipsStore(state => state.removeAllTasksFromQueue)

  const userStateCopy = useEphemeralStore(state => state.userStateCopy)

  const clearQueues = useCallback(() => {
    clearProgressQueue()
    clearGoalsQueue()
    clearTipsQueue()
    clearQuestionnaireQueue()
    clearUserQueue()
  }, [clearGoalsQueue, clearProgressQueue, clearQuestionnaireQueue, clearTipsQueue, clearUserQueue])

  const writeUserStateCopy = useCallback(() => {
    if (!userStateCopy) {
      return
    }

    if (userStateCopy.userProgress) {
      setUserProgress(userStateCopy.userProgress)
    }

    if (userStateCopy.goals) {
      setGoals(userStateCopy.goals)
    }

    if (userStateCopy.goalInstances) {
      setGoalInstances(userStateCopy.goalInstances)
    }

    if (userStateCopy.tips) {
      setTips(userStateCopy.tips)
    }
  }, [setGoalInstances, setGoals, setTips, setUserProgress, userStateCopy])

  return {
    clearQueues,
    writeUserStateCopy,
  }
}

export const useUnloadSimulatedProgress = () => {
  const isSimulatedProgress = useEphemeralStore(state => state.isSimulatedProgress)

  const { clearQueues, writeUserStateCopy } = useCommonFunctions()

  const handleUnload = useCallback(() => {
    if (isSimulatedProgress) {
      clearQueues()

      writeUserStateCopy()
    }
  }, [clearQueues, isSimulatedProgress, writeUserStateCopy])

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [handleUnload])
}

export const useSimulateProgress = () => {
  const { createGoalPayload } = useGoal()

  const updateUser = useStore(state => state.updateUser)

  const userProgress = useProgressStore(state => state.userProgress)
  const setUserProgress = useProgressStore(state => state.setUserProgress)
  const topics = useProgressStore(state => state.userContent?.topics)
  const getMission = useProgressStore(state => state.getMission)

  const goals = useGoalsStore(state => state.goals)
  const goalInstances = useGoalsStore(state => state.goalInstances)
  const setGoals = useGoalsStore(state => state.setGoals)
  const setGoalInstances = useGoalsStore(state => state.setGoalInstances)
  const createGoalAndGoalInstances = useGoalsStore(state => state.createGoalAndGoalInstances)

  const tips = useTipsStore(state => state.tips)
  const setTips = useTipsStore(state => state.setTips)

  const setUserStateCopy = useEphemeralStore(state => state.setUserStateCopy)

  const isSimulatedProgress = useEphemeralStore(state => state.isSimulatedProgress)
  const enableSimulatedProgress = useEphemeralStore(state => state.enableSimulatedProgress)
  const disableSimulatedProgress = useEphemeralStore(state => state.disableSimulatedProgress)

  const { clearQueues, writeUserStateCopy } = useCommonFunctions()

  const scenarios = useMemo(() => {
    return [
      {
        label: 'Null Path',
        description: 'User opens the app but takes no further action, embodying a null engagement.',
        pathPercentage: 1,
        config: {
          isOnboarded: false,
          numberOfCompletedMissions: 0,
          areGoals: false,
          areTips: false,
        },
      },
      {
        label: 'Orientation Path',
        description:
          'User learns about the program, achieving basic orientation but not progressing by setting a program goal.',
        pathPercentage: 10,
        config: {
          isOnboarded: true,
          numberOfCompletedMissions: 0,
          areGoals: false,
          areTips: false,
        },
      },
      // {
      //   label: 'Foundation Path',
      //   description:
      //     'User sets a program goal during onboarding, laying a foundational understanding without further advancement.',
      //   pathPercentage: 20,
      //   config: {
      //     isOnboarded: true,
      //     numberOfCompletedMissions: 1,
      //     areGoals: false,
      //     areTips: false,
      //   },
      // },
      {
        label: 'Preparatory Path',
        description:
          'User starts Mission 01, engaging in preparatory learning without setting or pursuing goals.',
        pathPercentage: 40,
        config: {
          isOnboarded: true,
          numberOfCompletedMissions: 1,
          areGoals: false,
          areTips: false,
        },
      },
      {
        label: 'Theoretical Path',
        description:
          'User completes educational aspects of Mission 01, forming theoretical goals without practical application.',
        pathPercentage: 60,
        config: {
          isOnboarded: true,
          numberOfCompletedMissions: 1,
          areGoals: true,
          areTips: false,
        },
      },
      // {
      //   label: 'Single Mission Path',
      //   description:
      //     "User fully engages in Mission 01's activities and tracking, completing a single mission without moving on.",
      //   pathPercentage: 80,
      //   config: {
      //     isOnboarded: true,
      //     numberOfCompletedMissions: 1,
      //     areGoals: true,
      //     areTips: false,
      //   },
      // },
      {
        label: 'Happy Path',
        description:
          'User successfully navigates through all program steps, fully engaging and achieving the program goals.',
        pathPercentage: 100,
        config: {
          isOnboarded: true,
          numberOfCompletedMissions: 'all',
          areGoals: false,
          areTips: false,
        },
      },
    ]
  }, [])

  const getUserProgressLimited = useCallback((topics, desiredMissionCount) => {
    const progressPayload = {}
    let missionsLeft = desiredMissionCount

    for (const { id, missionsAndSeries } of topics) {
      const missionsPayload = {}

      for (const mission of missionsAndSeries) {
        if (missionsLeft > 0) {
          missionsPayload[mission.id] = {
            isCompleted: true,
            completedAt: new Date().getTime(),
          }
          missionsLeft--
        } else {
          break
        }
      }

      progressPayload[id] = {
        missionsAndSeries: missionsPayload,
      }

      if (missionsLeft <= 0) {
        break
      }
    }

    return progressPayload
  }, [])

  const handleScenario = useCallback(
    userScenario => {
      const { isOnboarded, numberOfCompletedMissions, areGoals, areTips } = userScenario

      setGoals([])
      setGoalInstances([])
      setTips([])

      updateUser({
        isOnboarded,
      })

      if (numberOfCompletedMissions === 0) {
        setUserProgress({})
      } else if (numberOfCompletedMissions === 'all') {
        const totalMissionsCount = topics.reduce(
          (count, topic) => count + topic.missionsAndSeries.length,
          0
        )

        setUserProgress(getUserProgressLimited(topics, totalMissionsCount))
      } else if (typeof numberOfCompletedMissions === 'number') {
        setUserProgress(getUserProgressLimited(topics, numberOfCompletedMissions))
      }

      if (areGoals) {
        const completedMissionIds = Object.values(userProgress).flatMap(topic => {
          const { missionsAndSeries } = topic

          return missionsAndSeries ? Object.keys(missionsAndSeries) : []
        })

        const GOAL_COMPONENTS_NAME = [
          'goal',
          'scale-of-values-goal',
          'numerical-entry-goal',
          'simple-choice-goal',
          'text-entry-goal',
          'multiple-choice-goal',
        ]

        const goalsToCreate = completedMissionIds.flatMap(missionId => {
          const { messages = [] } = getMission(missionId)
          const goalComponents = messages.filter(({ componentName }) =>
            GOAL_COMPONENTS_NAME.includes(componentName)
          )

          return goalComponents.map(({ componentOptions = {} }) => ({
            missionId,
            ...componentOptions,
          }))
        })

        goalsToCreate.forEach(goalData => {
          const payload = createGoalPayload({
            ...goalData,
            remindersTemplates: goalData.remindersTemplates || [],
            createdBy: { type: 'mission', missionId: goalData.missionId },
          })

          createGoalAndGoalInstances(payload)
        })
      }
    },
    [
      setGoals,
      setGoalInstances,
      setTips,
      updateUser,
      setUserProgress,
      topics,
      getUserProgressLimited,
      userProgress,
      getMission,
      createGoalPayload,
      createGoalAndGoalInstances,
    ]
  )

  const saveUserStateCopy = useCallback(() => {
    setUserStateCopy({
      userProgress,
      goals,
      goalInstances,
      tips,
    })
  }, [goalInstances, goals, setUserStateCopy, tips, userProgress])

  const handleEnableSimulatedProgress = useCallback(
    userScenario => {
      enableSimulatedProgress()

      if (!isSimulatedProgress) {
        saveUserStateCopy()
      }

      if (userScenario) {
        handleScenario(userScenario)
      }
    },
    [enableSimulatedProgress, handleScenario, isSimulatedProgress, saveUserStateCopy]
  )

  const handleDisableSimulatedProgress = useCallback(() => {
    clearQueues()

    writeUserStateCopy()

    disableSimulatedProgress()
  }, [clearQueues, disableSimulatedProgress, writeUserStateCopy])

  return {
    isSimulatedProgress,
    handleEnableSimulatedProgress,
    handleDisableSimulatedProgress,
    scenarios,
  }
}
