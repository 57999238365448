import { useMemo } from 'react'

import useProgressStore from '@store/useProgressStore'

import { useTranslations } from '@hooks'

export const useSelectedMission = (topicId, missionId) => {
  const { translateData } = useTranslations()

  const getMission = useProgressStore(state => state.getMission)
  const getMissionProgress = useProgressStore(state => state.getMissionProgress)

  const selectedMission = useMemo(() => {
    const missionData = getMission(missionId)
    const translatedMission = translateData(missionData)
    const missionProgress = getMissionProgress(topicId, missionId)

    return {
      topicId,
      ...translatedMission,
      ...missionProgress,
    }
  }, [getMission, getMissionProgress, missionId, topicId, translateData])

  return selectedMission
}
