import React, { useCallback, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { ROUTES, generateRoute } from '@const/Routes'

import { getSanityImage } from '@edwin/react-sdk'
import { useScreenSize } from '@hooks'

import ReactMarkdown from 'react-markdown'
import ProgressiveImage from 'react-progressive-graceful-image'
import { AIconCompleted, AIconLocked } from '@atoms/AIcons'

export const MProgramTopic = ({ topic }) => {
  const navigate = useNavigate()

  const { width } = useScreenSize()

  const progressBarRef = useRef(null)

  const { coverImageUrl, coverImageMeta } = topic
  const isLocked = !topic.isStarted && !topic.isCompleted
  const isCurrent = topic.isStarted && !topic.isCompleted

  const placeholderImage = getSanityImage(coverImageUrl, {
    width: 148,
    height: 320,
    crop: coverImageMeta?.crop,
    hotspot: coverImageMeta?.hotspot,
    quality: 30,
  })

  const coverImage = getSanityImage(coverImageUrl, {
    width: 148 * 3,
    height: 320 * 3,
    crop: coverImageMeta?.crop,
    hotspot: coverImageMeta?.hotspot,
  })

  const progressBarWidth = useMemo(() => {
    const topicLength = topic?.missionsAndSeries?.length || 1
    const completedMissionsLength =
      topic?.missionsAndSeries?.filter(({ isCompleted }) => {
        return isCompleted
      })?.length || 0
    const barWidth = progressBarRef?.current?.offsetWidth || 0

    return Math.ceil((barWidth / topicLength) * completedMissionsLength)
  }, [topic?.missionsAndSeries, width, progressBarRef?.current])

  const handleClick = useCallback(
    topicId => {
      navigate(generateRoute(ROUTES.PROGRAM_TOPIC, { topicId }))
    },
    [navigate]
  )

  return (
    <button
      key={topic.id}
      onClick={() => !isLocked && handleClick(topic.id)}
      tabIndex={isLocked ? -1 : 0}
      className={classnames(
        'relative flex items-center md:items-start w-full h-full min-h-[200px] rounded-lg !rounded-tl-none bg-white overflow-hidden',
        'focus:outline-none focus:ring-2 focus:ring-codGray',
        isLocked ? 'cursor-auto pointer-events-none' : 'cursor-pointer'
      )}
    >
      <div className="relative flex-shrink-0 w-[148px] h-full">
        {coverImage && (
          <ProgressiveImage placeholder={placeholderImage} src={coverImage}>
            {src => (
              <img
                className="absolute inset-0 w-full h-full object-cover"
                src={src}
                alt="topic cover"
              />
            )}
          </ProgressiveImage>
        )}
      </div>
      <div
        className={classnames(
          'flex flex-col justify-between w-full h-full p-5 lg:py-9 text-left',
          isCurrent ? '' : 'py-9'
        )}
      >
        <div>
          <div className="absolute top-2.5 right-2.5 flex flex-col">
            {topic.isCompleted && <AIconCompleted className="w-5 h-5 mb-1.5 bg-codGray/80" />}
            {isLocked && <AIconLocked className="w-5 h-5 bg-[#EAEAEA] fill-gray-500" />}
          </div>
          <div
            className={classnames(
              'font-p22',
              isCurrent ? 'text-2xl font-medium mb-2.5 md:mb-4 lg:mb-0' : 'text-xl font-bold',
              isLocked && 'opacity-50',
              isLocked || (topic.isCompleted && 'pr-6')
            )}
          >
            {topic.name}
          </div>
          <div className="line-clamp-4">
            <ReactMarkdown
              className={classnames('text-base', isLocked ? 'opacity-50' : 'opacity-90')}
            >
              {topic.description}
            </ReactMarkdown>
          </div>
        </div>
        {isCurrent && (
          <div ref={progressBarRef} className="h-4 mt-5 bg-pampas rounded-xl overflow-hidden">
            <div className="h-full bg-codGray" style={{ width: progressBarWidth }} />
          </div>
        )}
      </div>
    </button>
  )
}
