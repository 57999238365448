import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import {
  useThreads,
  useThread,
  sendThreadMessage,
  deleteThread,
  confirmThreadAction,
  cancelThreadAction,
  cancelAssistantThreadActivity,
} from '@services/Assistant'

import { OPageWrapper } from '@organisms/OPageWrapper'
import { MThreadsList } from '@molecules/MThreadsList'
import { MChatActionConfirmationModal } from '@molecules/MChatActionConfirmationModal'
import { OThread } from '@organisms/OThread'

export const PChat = ({ isLoading }) => {
  const [threads = [], isThreadsLoading] = useThreads()
  const [currentThreadId, setCurrentThreadId] = useState(null)
  const [isChatActionConfirmationModalOpen, setIsChatActionConfirmationModalOpen] = useState(false)
  const [confirmationModalData, setConfirmationModalData] = useState(null)
  const modalCloseCallbackRef = useRef(null)
  const [confirmationResult, setConfirmationResult] = useState(null)

  const [currentThread] = useThread(currentThreadId)

  const [isNewMessagesLoading, setIsNewMessagesLoading] = useState(false)
  const [deletingThread, setDeletingThread] = useState(false)

  const filteredThreads = useMemo(() => {
    return threads.filter(thread => {
      return thread.type === 'assistant' || thread.type === 'insights'
    })
  }, [threads])

  const isChatLoading = useMemo(() => {
    return isThreadsLoading
  }, [isThreadsLoading])

  const shouldShowThreadConfirmation = useMemo(() => {
    return currentThread?.threadConfirmationInProgress
  }, [currentThread?.threadConfirmationInProgress])

  const threadConfirmationData = useMemo(() => {
    const recentConfirmation = currentThread?.threadConfirmations?.[0]

    if (recentConfirmation) return recentConfirmation

    return null
  }, [currentThread?.threadConfirmations])

  const handleThreadOpen = useCallback(
    threadId => {
      const matchingThread = threads.find(thread => thread.id === threadId)

      if (matchingThread?.id) {
        setCurrentThreadId(threadId)
      }
    },
    [threads]
  )

  const handleNewChat = useCallback(() => {
    setCurrentThreadId(null)
  }, [])

  const sendMessage = useCallback(
    async message => {
      setIsNewMessagesLoading(true)

      const messageThread = await sendThreadMessage({ threadId: currentThreadId, message })

      if (!currentThreadId) {
        setCurrentThreadId(messageThread?.id)
      }
    },
    [currentThreadId]
  )

  const handleDeleteThreadModal = useCallback(async threadId => {
    setConfirmationModalData({
      title: 'Confirm delete thread',
    })
    setIsChatActionConfirmationModalOpen(true)

    return new Promise(resolve => {
      modalCloseCallbackRef.current = confirmed => {
        resolve({ confirmed })
      }
    }).then(async ({ confirmed }) => {
      setDeletingThread(threadId)

      if (confirmed) {
        await deleteThread(threadId)

        setCurrentThreadId(prev => (prev === threadId ? null : prev))
      }

      setDeletingThread(null)
      setIsChatActionConfirmationModalOpen(false)
    })
  }, [])

  const handleModalConfirm = async () => {
    if (shouldShowThreadConfirmation) {
      await confirmThreadAction({
        threadId: currentThread?.id,
        confirmationId: threadConfirmationData?.id,
      })
    }

    setConfirmationResult(true)
    setIsChatActionConfirmationModalOpen(false)
    setConfirmationModalData({})
  }

  const handleModalClose = async () => {
    if (shouldShowThreadConfirmation) {
      await cancelThreadAction({
        threadId: currentThread?.id,
        confirmationId: threadConfirmationData?.id,
      })
    }

    setConfirmationResult(false)
    setIsChatActionConfirmationModalOpen(false)
    setConfirmationModalData({})
  }

  const handleCancelCurrentThread = async () => {
    await cancelAssistantThreadActivity(currentThread?.id)
  }

  useEffect(() => {
    const isRemoteThreadLoading = currentThread?.inProgress

    setIsNewMessagesLoading(isRemoteThreadLoading)
  }, [currentThread])

  useEffect(() => {
    if (shouldShowThreadConfirmation && threadConfirmationData) {
      setConfirmationModalData({
        title: threadConfirmationData.description.title,
        body: threadConfirmationData.description.body,
        data: threadConfirmationData,
      })
      setIsChatActionConfirmationModalOpen(true)
    }
  }, [shouldShowThreadConfirmation, threadConfirmationData])

  // Effect to detect modal close and resolve the promise
  useEffect(() => {
    if (!isChatActionConfirmationModalOpen && modalCloseCallbackRef.current !== null) {
      modalCloseCallbackRef.current(confirmationResult)
      modalCloseCallbackRef.current = null // Reset the ref after calling the callback
      setConfirmationResult(null) // Reset the confirmation result
    }
  }, [isChatActionConfirmationModalOpen, confirmationResult])

  return (
    <OPageWrapper
      isLoading={isLoading || isChatLoading}
      className="flex flex-col h-full"
      contentClassName="!p-0"
    >
      <div className="absolute inset-0 flex flex-row h-full antialiased overflow-hidden">
        <MThreadsList
          threads={filteredThreads}
          currentThreadId={currentThreadId}
          onThreadOpen={handleThreadOpen}
          onNewChat={handleNewChat}
          onDeleteThread={handleDeleteThreadModal}
          deletingThread={deletingThread}
        />
        <OThread
          thread={currentThread}
          isNewMessagesLoading={isNewMessagesLoading}
          isEnableToCancel={isNewMessagesLoading && currentThreadId}
          handleNewMessage={sendMessage}
          handleCancelCurrentThread={handleCancelCurrentThread}
        />
      </div>

      <MChatActionConfirmationModal
        isOpen={isChatActionConfirmationModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        title={confirmationModalData?.title}
        body={confirmationModalData?.body}
      />
    </OPageWrapper>
  )
}
