import React, { useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useUser } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'
import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatComponentFlow = ({ message, missionId }) => {
  const { user, assistant, handleGoToMission = () => {} } = useUser()

  const parsedMsg = useMemo(() => {
    try {
      const parsed = _.template(message)({ user, assistant, input: assistant })

      return parsed
    } catch (e) {
      console.warn(e)

      return message
    }
  }, [assistant, message, user])

  const handleOnClick = useCallback(() => {
    handleGoToMission(missionId)
  }, [handleGoToMission, missionId])

  return (
    <div className="py-3 px-3.5">
      {message && (
        <AMarkdown paragraphStyles="text-[20px] leading-[26px]" className="mt-1 mb-3.5 font-p22">
          {parsedMsg}
        </AMarkdown>
      )}

      {missionId ? (
        <AButton onClick={handleOnClick} small dontRound outline>
          Let's go
        </AButton>
      ) : (
        <div className="text-sm text-red-600">Psst...there is no missionId</div>
      )}
    </div>
  )
}
