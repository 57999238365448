import React, { useCallback, useState, useMemo } from 'react'

import { useInteractiveComponents } from '@edwin/react-sdk'

import { Dialog, Transition } from '@headlessui/react'
import { X } from 'react-feather'
import { AButton } from '../atoms/AButton'
import classNames from 'classnames'

export const MChatInteractiveChat = ({
  title,
  description,
  buttons,
  additionalAIInstructions,
  onProceed,
  missionPath,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [isConfirmCloseModal, setIsConfirmCloseModal] = useState(false)

  const { InteractiveComponent } = useInteractiveComponents('chat')

  const handleProceed = useCallback(() => {
    setIsModalOpen(false)
    setIsFinished(true)
    onProceed()
  }, [onProceed])

  const handleBeforeClose = useCallback(() => {
    setIsConfirmCloseModal(true)
  }, [])

  return (
    <>
      <div className="py-3 px-3.5">
        {title && <h4 className="text-lg mb-3">{title}</h4>}
        {description && <p className="mb-3">{description}</p>}
        <div className="flex gap-x-1.5">
          {buttons?.open && (
            <AButton
              disabled={isFinished}
              onClick={() => {
                setIsModalOpen(true)
              }}
              small
              dontRound
            >
              {buttons?.open || 'Let’s talk'}
            </AButton>
          )}
          {buttons?.close && (
            <AButton disabled={isFinished} outline onClick={handleProceed} small dontRound>
              {buttons?.close || 'Not now'}
            </AButton>
          )}
        </div>
      </div>

      <Transition show={isModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={handleBeforeClose}
          initialFocus={false}
          open={isModalOpen}
        >
          <div className="react-web--edwinsandbox__container flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900/75" />
            </Transition.Child>

            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="absolute inset-0 max-w-[680px] mx-auto sm:top-[10%] sm:inset-x-[15%] sm:bottom-[20%] inline-block overflow-hidden text-left text-codGray align-middle transition-all transform bg-wildSand shadow-xl rounded-lg">
                <div
                  className={classNames(
                    'absolute inset-0 flex flex-col items-center justify-center w-full h-full z-50',
                    !isConfirmCloseModal && 'hidden'
                  )}
                >
                  <p>Are you sure?</p>
                  <div className="flex items-center mt-2 gap-x-2">
                    {buttons?.open && (
                      <AButton
                        onClick={() => {
                          handleProceed()
                        }}
                        small
                        dontRound
                      >
                        Yes, close modal
                      </AButton>
                    )}
                    {buttons?.close && (
                      <AButton
                        outline
                        onClick={() => {
                          setIsConfirmCloseModal(false)
                        }}
                        small
                        dontRound
                      >
                        No
                      </AButton>
                    )}
                  </div>
                </div>

                <div className={classNames('w-full h-full', isConfirmCloseModal && 'opacity-0')}>
                  <div className="absolute top-2 right-2 z-10">
                    <button
                      onClick={handleBeforeClose}
                      className="rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-codGray"
                    >
                      <X className="h-6 w-6 hover:text-gray-600" />
                    </button>
                  </div>

                  {InteractiveComponent && (
                    <InteractiveComponent
                      missionPath={missionPath}
                      additionalAIInstructions={additionalAIInstructions}
                    />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
