import React from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from '@hooks'

import { signOut } from '@services/Authentication'

import { AButton } from '@atoms/AButton'
import { OModal } from './OModal'

export const OSignoutModal = ({ onClose, title = 'modal.signOut', ...props }) => {
  const { t } = useTranslations()

  return (
    <OModal onClose={onClose} {...props}>
      <div className="text-center">
        <h2 className="text-lg font-semibold">{t(title)}</h2>
      </div>
      <div className="flex justify-center items-center mt-4 space-x-2">
        <AButton onClick={signOut}>{t('signOut')}</AButton>
        <AButton outline onClick={onClose}>
          {t('cancel')}
        </AButton>
      </div>
    </OModal>
  )
}

OSignoutModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
}
