import { getSanityImage, useAuthor } from '@edwin/react-sdk'
import { useWindowSize } from '@react-hook/window-size'

import { MSlider } from '../molecules/MSlider'
import { ACloseIcon } from '../atoms/ACloseIcon'

export const OMissionCover = ({
  title = '',
  imageUrl = '',
  imageMeta = {},
  steps,
  onStart = () => {},
  onClose = () => {},
}) => {
  const { author } = useAuthor()
  // const [width, height] = useWindowSize()
  // const { crop = {}, hotspot = {} } = imageMeta

  return (
    <div className="relative flex flex-col justify-between lg:flex-row min-h-[667px] h-screen max-w-md md:max-w-none text-gray-50 bg-wildSand">
      <div className="absolute top-2 left-2 z-40">
        <ACloseIcon onClick={onClose} />
      </div>
      <div className="relative grow w-full lg:w-1/2 lg:max-w-4xl md:max-h-[60%] lg:max-h-none mx-auto lg:order-1 lg:overflow-hidden">
        {imageUrl && (
          <div className="absolute top-[40px] left-[19px] w-[377px] lg:w-[602px] h-[351px] lg:h-[561px]">
            {/*<img*/}
            {/*  className="absolute inset-0 h-screen object-cover m-0 p-0"*/}
            {/*  src={getSanityImage(imageUrl, {*/}
            {/*    width,*/}
            {/*    height,*/}
            {/*    crop,*/}
            {/*    hotspot,*/}
            {/*  })}*/}
            {/*  alt=""*/}
            {/*/>*/}
          </div>
        )}
      </div>
      <div className="relative flex flex-col lg:self-end lg:w-1/2 lg:max-w-2xl p-6 pb-4 lg:pl-16 lg:pr-8 lg:pb-16">
        <h3 className="text-3xl md:text-4xl lg:text-5xl font-medium max-w-prose lg:max-w-xl">
          {title}
        </h3>
        {author.name && (
          <h5 className="mt-2 text-xs md:text-lg lg:text-xl font-normal md:font-medium">
            with {author.name}
          </h5>
        )}
        <div
          onClick={onStart}
          className="md:w-60 mt-6 py-2.5 text-lg font-medium text-gray-50 bg-crayola border border-crayola rounded-full text-center cursor-pointer z-50 lg:order-last"
        >
          Get Started
        </div>
        {steps && (
          <>
            <svg
              width="119"
              height="10"
              viewBox="0 0 119 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="hidden lg:block mt-4"
            >
              <path
                d="M39.3262 1C49.3096 1 49.3096 8.66095 59.2446 8.66095C69.2281 8.66095 69.2281 1 79.1631 1C89.1466 1 89.1466 8.66095 99.0816 8.66095C109.017 8.66095 109.065 1 119 1"
                stroke="white"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M0 1C9.84349 1 9.84349 8.66095 19.6392 8.66095C29.4827 8.66095 29.4827 1 39.3262 1"
                stroke="white"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
            <h5 className="mt-5 text-sm lg:text-xs font-normal uppercase tracking-wider lg:tracking-widest">
              What we'll cover
            </h5>
            <div className="mt-2">
              <MSlider startIndex={0} className="relative -mx-6">
                {steps.map(step => (
                  <div key={step} className="last:mr-6 lg:last:mr-5">
                    <div className="w-32 h-full ml-6 -mr-4 p-3 bg-gray-50 bg-opacity-10 rounded-lg rounded-tl-none">
                      <p className="text-sm">{step}</p>
                    </div>
                  </div>
                ))}
              </MSlider>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
