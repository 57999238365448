import { useState, useCallback } from 'react'
import classNames from 'classnames'

import { useGoalProvider } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'

export const MChatSelectableOptions = ({ text, options, inline, multiple }) => {
  const [selectedOption, setSelectedOption] = useState(null)

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])

  const { onSendHiddenMsg = () => {} } = useGoalProvider()

  const handleChooseOption = useCallback(
    option => {
      onSendHiddenMsg(option.label)
      setSelectedOption(option.id)
    },
    [onSendHiddenMsg]
  )

  const handleChooseMultipleOptions = useCallback(
    id => {
      if (selectedOptions.find(option => option === id)) {
        setSelectedOptions(prev => prev.filter(option => option !== id))
      } else {
        setSelectedOptions(prev => [...prev, id])
      }
    },
    [selectedOptions]
  )

  const handleSubmitMultipleOptions = useCallback(() => {
    setIsSubmitted(true)

    onSendHiddenMsg(`${selectedOptions.map(option => option).join(', ')}`)
  }, [onSendHiddenMsg, selectedOptions])

  return (
    <div className="py-3 px-3.5">
      {text && <h4 className="mb-4 text-lg font-p22">{text}</h4>}
      <div className={classNames('flex flex-wrap', inline ? 'gap-1' : 'flex-col gap-y-2.5')}>
        {options.map(({ id, label }) => (
          <AButton
            key={id}
            onClick={() => {
              if (multiple) {
                handleChooseMultipleOptions(id)
              } else {
                handleChooseOption({ id, label })
              }
            }}
            outline={selectedOption !== id && !selectedOptions.find(option => option === id)}
            small
            disabled={!!selectedOption || isSubmitted}
            className={classNames(
              selectedOption === id && '!opacity-100',
              selectedOptions.find(option => option === id) && '!opacity-100',
              inline && '!py-1.5 !px-2.5 !text-sm'
            )}
          >
            {label}
          </AButton>
        ))}
      </div>
      {multiple && (
        <div className="mt-4">
          <AButton
            small
            onClick={handleSubmitMultipleOptions}
            disabled={isSubmitted || !selectedOptions.length}
          >
            Submit
          </AButton>
        </div>
      )}
    </div>
  )
}
