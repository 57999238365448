import React, { useContext, createContext } from 'react'

export const InteractiveContext = createContext({})
InteractiveContext.displayName = 'InteractiveContext'

export const InteractiveProvider = ({ children, customInteractiveComponents = {} }) => {
  return (
    <InteractiveContext.Provider value={{ interactiveConfig: customInteractiveComponents }}>
      {children}
    </InteractiveContext.Provider>
  )
}

export const useInteractiveComponents = componentName => {
  const context = useContext(InteractiveContext)

  if (context === undefined) {
    throw new Error('useInteractiveComponents must be nested in InteractiveProvider')
  }

  const { interactiveConfig } = context

  const InteractiveComponent = interactiveConfig[componentName]

  return { InteractiveComponent }
}
