import classnames from 'classnames'

import { useSimulateProgress } from '@services/SimulateProgress'

import { AButton } from '@atoms/AButton'

export const OProgressSimulation = () => {
  const {
    isSimulatedProgress,
    handleEnableSimulatedProgress,
    handleDisableSimulatedProgress,
    scenarios,
  } = useSimulateProgress()

  return (
    <>
      <div className="flex flex-col items-stretch">
        {scenarios.map(({ label, description, pathPercentage, config }, index) => (
          <button
            key={index}
            onClick={() => {
              handleEnableSimulatedProgress(config)
            }}
            className={classnames(
              'flex items-center py-2.5 px-2 text-sm text-left border-b border-green-dark',
              'focus:outline-none focus:ring-2 focus:ring-green-dark focus:border-transparent focus:rounded cursor-pointer'
            )}
          >
            <div className="text-xs text-green-dark">{description}</div>
            <div className="flex-shrink-0 w-24 ml-3 py-1.5 pl-3 border-l-4 border-green-dark">
              <p className="text-xs text-green-dark font-medium">
                {label} <span className="block text-xl">{pathPercentage}%</span>
              </p>
            </div>
          </button>
        ))}
      </div>
      <div className="flex justify-end mt-3">
        <AButton
          onClick={handleDisableSimulatedProgress}
          disabled={!isSimulatedProgress}
          className={classnames(!isSimulatedProgress && 'invisible')}
          small
          outline
        >
          Cancel simulation
        </AButton>
      </div>
    </>
  )
}
