import React, { useRef, useCallback, useEffect, useState } from 'react'
import { useThreadUI } from '@hooks'

import { createUserMissionInsightsAssistantThreadFBFunction } from '@services/Firebase'
import { useThread, sendThreadMessage } from '@services/Assistant'

import { Mission } from '@edwinsandbox/react-web'
import { MChatInput } from '@molecules/MChatInput'
import { ALoader } from '@atoms/ALoader'

export const OMissionChat = ({ missionPath, additionalAIInstructions }) => {
  const initialized = useRef(false)

  const [isChatInitialized, setIsChatInitialized] = useState(false)

  const [threadId, setThreadId] = useState(null)

  const [isInputDisabled, setIsInputDisabled] = useState(false)
  const [isNewMessagesLoading, setIsNewMessagesLoading] = useState(false)

  const missionId = '65f54ded-28d5-45ca-8008-cb087dea8961'

  const [thread] = useThread(threadId)

  const sendMessage = useCallback(
    async message => {
      setIsInputDisabled(true)

      setIsNewMessagesLoading(true)

      if (!threadId) return

      try {
        await sendThreadMessage({ threadId, message })
      } catch (err) {
        console.error(err)
      }
    },
    [threadId]
  )

  const { modifiedThread, handleUserInput } = useThreadUI({
    thread,
    handleNewMessage: sendMessage,
  })

  const handleInitChat = useCallback(async () => {
    if (isChatInitialized) {
      return
    }

    const fbThread = await createUserMissionInsightsAssistantThreadFBFunction({
      missionPath,
      missionId,
      data: {
        additionalAIInstructions,
      },
    })
    const thread = fbThread?.data

    if (!thread?.id) {
      console.error('Failed to create thread')
    }

    setThreadId(thread.id)

    setIsChatInitialized(true)
  }, [additionalAIInstructions, isChatInitialized, missionPath])

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      handleInitChat()
    }
  }, [handleInitChat])

  useEffect(() => {
    const isRemoteThreadLoading = thread?.inProgress

    setIsNewMessagesLoading(isRemoteThreadLoading)
  }, [thread])

  useEffect(() => {
    setIsInputDisabled(!!isNewMessagesLoading)
  }, [isNewMessagesLoading])

  if (!isChatInitialized) {
    return (
      <div className="flex items-center justify-center h-full">
        <ALoader />
      </div>
    )
  }

  return (
    <div className="flex flex-col h-full w-full pb-6">
      <div className="h-full pb-2 overflow-hidden">
        {modifiedThread && (
          <Mission
            thread={modifiedThread}
            shouldScrollItself
            isNewMessagesLoading={isNewMessagesLoading}
          />
        )}
      </div>
      <div className="px-2.5">
        <MChatInput handleOnSubmit={handleUserInput} isInputDisabled={isInputDisabled} />
      </div>
    </div>
  )
}
