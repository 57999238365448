import { useCallback } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

import { generateRoute, ROUTES } from '@const/Routes'

import { useTranslations, useThreadUI } from '@hooks'
import useGoalsStore from '@store/useGoalsStore'
import useProgressStore from '@store/useProgressStore'

import { Mission } from '@edwinsandbox/react-web'

import { MChatInput } from '@molecules/MChatInput'

export const OThread = props => {
  const { t } = useTranslations()
  const navigate = useNavigate()

  const {
    thread,
    title = t('coach.thread.title'),
    description = t('coach.thread.description'),
    isNewMessagesLoading = false,
    isEnableToCancel = false,
    handleNewMessage = () => {},
    handleCancelCurrentThread = () => {},
    className = '',
  } = props

  const getGoal = useGoalsStore(state => state.getGoal)
  const goals = useGoalsStore(state => state.goals)
  const updateGoal = useGoalsStore(state => state.updateGoal)
  const getMission = useProgressStore(state => state.getMission)
  const getMissionProgress = useProgressStore(state => state.getMissionProgress)

  const handleGetGoal = useCallback(
    goalId => {
      return getGoal(goalId, goals)
    },
    [getGoal, goals]
  )

  const handleMissionClick = useCallback(
    (missionId, topicId) => {
      navigate(generateRoute(ROUTES.CHAT_MISSION, { missionId, topicId }))
    },
    [navigate]
  )

  const { modifiedThread, handleUserInput } = useThreadUI({
    thread,
    handleNewMessage,
  })

  // console.log({ modifiedThread })

  return (
    <div className={classNames('flex flex-col h-full w-full ml-8 md:ml-0 sm:px-4 pb-6', className)}>
      <div className="h-full pb-2 overflow-hidden">
        {modifiedThread ? (
          <Mission
            thread={modifiedThread}
            handleNewMessage={handleNewMessage}
            getGoal={handleGetGoal}
            getMission={getMission}
            getMissionProgress={getMissionProgress}
            updateGoal={updateGoal}
            onMissionClick={handleMissionClick}
            shouldScrollItself
            isNewMessagesLoading={isNewMessagesLoading}
          />
        ) : (
          <>
            {!isNewMessagesLoading && (
              <div className="flex flex-col justify-center items-center h-full text-center">
                <div className="max-w-[200px] sm:max-w-none text-2xl font-medium">{title}</div>
                <div className="max-w-[255px] sm:max-w-sm mt-2 text-sm text-center">
                  {description}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <MChatInput
        handleOnSubmit={handleUserInput}
        isInputDisabled={isNewMessagesLoading}
        isNewMessagesLoading={isNewMessagesLoading}
        isEnableToCancel={isEnableToCancel}
        handleCancelCurrentThread={handleCancelCurrentThread}
        className="px-2.5 sm:px-0"
      />
    </div>
  )
}
