import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ASSISTANT_IDES } from '@utils/constants/flow'

import { useMessageLoader } from '@hooks/useMessageLoader'
import { useThreadUI } from '@hooks/useThreadUI'
import {
  createAssistantThreadWithInput,
  markAssistantComponentThreadAsCompleted,
} from '@services/Insights'
import { sendThreadMessage, useThread } from '@services/Assistant'

export const useFlowThread = assistantId => {
  const [thread] = useThread(assistantId)
  const { flatMessages, areMessagesToProcess, isProcessing } = useMessageLoader(
    thread?.messages || []
  )

  const [isNewMessagesLoading, setIsNewMessagesLoading] = useState(false)

  const threadWithFlatMessages = useMemo(() => {
    return {
      ...thread,
      messages: flatMessages,
    }
  }, [flatMessages, thread])

  const isLastMsgNeedsUserAction = useMemo(() => {
    const lastMsg = flatMessages?.[flatMessages?.length - 1]
    const validTypes = {
      'selectable-options': true,
      'date-time-picker': true,
      'numerical-range': true,
    }

    return validTypes[lastMsg?.data?.type] === true || validTypes[lastMsg?.type] === true
  }, [flatMessages])

  const sendMessage = useCallback(
    async (message, hidden) => {
      // setIsNewMessagesLoading(true)

      await sendThreadMessage({ threadId: assistantId, message, hidden })
    },
    [assistantId]
  )

  const { modifiedThread, handleUserInput } = useThreadUI({
    thread: threadWithFlatMessages,
    handleNewMessage: sendMessage,
  })

  const isLastMsgDontTriggerInput = useMemo(() => {
    const lastMsg = modifiedThread?.messages?.[modifiedThread?.messages?.length - 1]
    const isTextComponent = lastMsg?.type === 'text' || lastMsg?.data?.type === 'text'

    return !isProcessing && isTextComponent && !thread?.inProgress
  }, [isProcessing, modifiedThread?.messages, thread?.inProgress])

  const handleAssistantFlowAction = useCallback(
    async ({ type, input }) => {
      try {
        const shouldContinueFlow = !!type && type !== 'end'
        let newThreadId = null

        if (shouldContinueFlow) {
          const assistantId = ASSISTANT_IDES[type]

          const { id } = await createAssistantThreadWithInput({
            assistantId,
            type,
            input,
          })

          newThreadId = id
        }

        if (thread?.id) {
          await markAssistantComponentThreadAsCompleted({ threadId: thread.id })
        }

        return { id: newThreadId, shouldContinueFlow }
      } catch (error) {
        console.error(error)
      }
    },
    [thread?.id]
  )

  useEffect(() => {
    const isRemoteThreadLoading = thread?.inProgress

    setIsNewMessagesLoading(isRemoteThreadLoading)
  }, [thread])

  return {
    thread,
    modifiedThread,
    sendMessage,
    handleUserInput,
    handleAssistantFlowAction,
    isNewMessagesLoading,
    isLastMsgNeedsUserAction,
    isLastMsgDontTriggerInput,
  }
}
