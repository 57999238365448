import { useCallback, useEffect } from 'react'

export const useWhiteBackground = () => {
  const setBodyBackgroundColor = useCallback(color => {
    const bodyElement = document?.body

    if (bodyElement) {
      bodyElement.style.backgroundColor = color
    }
  }, [])

  useEffect(() => {
    setBodyBackgroundColor('#FFF')

    return () => {
      setBodyBackgroundColor('#F5F5F5')
    }
  }, [setBodyBackgroundColor])

  return null
}
