import React, { useCallback, useMemo } from 'react'
import { getSanityImage, useGoalProvider } from '@edwin/react-sdk'
import classnames from 'classnames'

import ProgressiveImage from 'react-progressive-graceful-image'
import { AIconCompleted, AIconLocked } from '../atoms/AIcons'

export const MChatDisplayMission = ({ missionId, topicId }) => {
  const {
    getMission = () => {},
    onMissionClick = () => {},
    getMissionProgress = () => {},
  } = useGoalProvider()

  const mission = useMemo(() => {
    const missionData = getMission(missionId)
    const missionProgress = getMissionProgress(topicId, missionId)

    return { ...missionData, ...missionProgress }
  }, [getMission, getMissionProgress, missionId, topicId])

  const isLocked = !mission?.isCompleted && !mission?.isCurrent

  const placeholderImage = getSanityImage(mission?.coverImageUrl, {
    width: 296,
    height: 320,
    crop: mission?.coverImageMeta?.crop,
    hotspot: mission?.coverImageMeta?.hotspot,
    quality: 30,
  })

  const coverImage = getSanityImage(mission?.coverImageUrl, {
    width: 296 * 3,
    height: 320 * 3,
    crop: mission?.coverImageMeta?.crop,
    hotspot: mission?.coverImageMeta?.hotspot,
  })

  const handleClick = useCallback(() => {
    if (!isLocked) {
      onMissionClick(missionId, topicId)
    }
  }, [isLocked, onMissionClick, missionId, topicId])

  if (!mission) {
    return (
      <div className="py-3 px-1.5">
        <p>Missing mission</p>
      </div>
    )
  }

  return (
    <button
      onClick={handleClick}
      tabIndex={isLocked ? -1 : 0}
      className={classnames(
        'relative flex flex-col items-start bg-white rounded-lg overflow-hidden transition-opacity w-[276px]',
        'focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-codGray ',
        isLocked ? 'cursor-auto pointer-events-none' : 'hover:opacity-90 cursor-pointer'
      )}
    >
      <div className="absolute top-3 right-3 flex flex-col">
        {mission?.isCompleted && (
          <AIconCompleted className="w-5 h-5 mb-1.5 bg-codGray/90 opacity-80" />
        )}
        {isLocked && <AIconLocked className="w-5 h-5 bg-[#EAEAEA] fill-gray-500 opacity-80" />}
      </div>
      <div className="w-full h-auto overflow-hidden">
        {coverImage && (
          <ProgressiveImage placeholder={placeholderImage} src={coverImage}>
            {src => <img className="w-full h-full object-cover" src={src} alt="mission cover" />}
          </ProgressiveImage>
        )}
      </div>
      <div className="flex-1 py-2 min-h-[120px] px-4 text-left">
        <h4
          className={classnames(
            'font-p22 font-medium line-clamp-3',
            isLocked ? 'opacity-50' : '',
            'text-xl lg:text-2xl'
          )}
        >
          {mission.title}
        </h4>
      </div>
    </button>
  )
}
