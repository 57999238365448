const CONFIG = {
  firebase: {
    apiKey: 'AIzaSyAc_8tEKLK7mp5h7GSV7ETRDkPbPCoS0LY',
    authDomain: 'edwin-sandbox.firebaseapp.com',
    projectId: 'edwin-sandbox',
    storageBucket: 'edwin-sandbox.appspot.com',
    messagingSenderId: '870892666176',
    appId: '1:870892666176:web:78dfbb5949ee56fc0a4ea5',
  },
  sentry: {
    dsn: '',
    debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  },
  mixpanel: {
    token: '',
  },
  openai: {
    organization: 'org-t7SI1v4Fe7MI7FZfyjwBNnIy',
    coachAssistantId: 'asst_O92DP5VqmoKsKCr3BkDWIaUY',
  },
  analyticsEnabled: false,
  timeTravelEnabled: true,
  cloudFunctionsUrl: 'https://us-central1-edwincloudstaging.cloudfunctions.net/api/v1',
  connectFirebaseEmulators: false,
}

export default CONFIG
