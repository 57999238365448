import { useMemo } from 'react'
import {
  firestoreDb,
  COLLECTIONS,
  auth,
  sendAssistantThreadMessageFBFunction,
  deleteAssistantThreadFBFunction,
  resolveAssistantThreadConfirmationFBFunction,
  cancelAssistantThreadConfirmationFBFunction,
  cancelAssistantThreadActivityFBFunction,
} from '@services/Firebase'
import { doc, query, where, collection, updateDoc } from 'firebase/firestore'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { DateTime } from 'luxon'

const createConfirmationDescription = confirmation => {
  const args = JSON.parse(confirmation.arguments)

  const defaultBody = 'Are you sure?'

  if (confirmation.name === 'create_appointment') {
    const { coach, dateTime } = args
    const dateTimeObject = DateTime.fromISO(dateTime)

    const markdownBody = `**Coach:** ${coach}\\
    **Date:** ${dateTimeObject.toFormat('DD')}\\
    **Time:** ${dateTimeObject.toFormat('HH:mm')}`

    return {
      title: 'Confirm a new appointment',
      body: dateTimeObject.isValid ? markdownBody : defaultBody,
    }
  }

  if (confirmation.name === 'create_boolean_goal') {
    const { description, startsOn, endsOn } = args
    let markdownBody = `${description || 'No description provided'} \n`

    if (startsOn) {
      const startsOnDateTime = DateTime.fromISO(startsOn)
      markdownBody += `\n- **Start Date:** ${startsOnDateTime.toFormat('DD')}`
    }

    if (endsOn) {
      const endsOnDateTime = DateTime.fromISO(endsOn)
      markdownBody += `\n- **End Date:** ${endsOnDateTime.toFormat('DD')}`
    }

    return {
      title: 'Confirm a new goal',
      body: markdownBody,
    }
  }
}

export const sortThreads = (a, b) => {
  const aDate = a?.updatedAt?.seconds || a.createdAt?.seconds
  const bDate = b?.updatedAt?.seconds || b.createdAt?.seconds

  return bDate < aDate ? -1 : bDate > aDate ? 1 : 0
}

export const mapThread = threadData => {
  const mappedThread = {
    ...threadData,
    messages: mapMessages(threadData.messages || []),
  }

  if (threadData.createdAt) {
    mappedThread.createdAt = DateTime.fromMillis(threadData.createdAt.seconds * 1000).toISO()
  }

  if (threadData.updatedAt) {
    mappedThread.updatedAt = DateTime.fromMillis(threadData.updatedAt.seconds * 1000).toISO()
  }

  if (threadData.threadConfirmations) {
    mappedThread.threadConfirmations = threadData.threadConfirmations.map(confirmation => {
      const mappedData = { ...confirmation }

      if (confirmation.createdAt) {
        mappedData.createdAt = DateTime.fromMillis(confirmation.createdAt.seconds * 1000).toISO()
      }

      mappedData.description = createConfirmationDescription(confirmation)

      return mappedData
    })
  }

  return mappedThread
}

export const mapMessages = messagesData => {
  const mappedMessages = messagesData
    ?.filter(msg => {
      const role = msg?.metadata?.role || msg?.role

      return role !== 'system'
    })
    .map(message => {
      const mappedMessage = {
        ...message,
      }
      const role = mappedMessage?.metadata?.role || mappedMessage?.role

      if (message.createdAt) {
        mappedMessage.createdAt = DateTime.fromMillis(message.createdAt.seconds * 1000).toISO()
      }

      if (role === 'user') {
        return {
          ...mappedMessage,
          type: 'user-options',
          options: [
            {
              label: mappedMessage.text,
              isChosen: true,
            },
          ],
        }
      }

      const prevMessage = messagesData[messagesData.indexOf(mappedMessage) - 1]
      const prevMessageRole = prevMessage?.metadata?.role || prevMessage?.role
      const isPrevMessageAssistant = prevMessageRole === 'assistant'

      if (mappedMessage.type === 'image_file') {
        return {
          ...mappedMessage,
          first: !isPrevMessageAssistant,
          type: 'bot-component',
          componentName: 'image',
          componentOptions: {
            src: mappedMessage?.fileUrl,
            title: mappedMessage?.text,
          },
        }
      }

      if (mappedMessage.type === 'custom-component') {
        return {
          ...mappedMessage,
          first: !isPrevMessageAssistant,
          type: 'bot-component',
          componentName: mappedMessage?.componentName,
          componentOptions: mappedMessage?.componentOptions,
        }
      }

      return {
        ...mappedMessage,
        first: !isPrevMessageAssistant,
        type: 'bot',
        msg: mappedMessage.text,
      }
    })

  return mappedMessages
}

export const useThreads = () => {
  const [threadsRaw, loading, error] = useCollectionData(
    query(
      collection(firestoreDb, COLLECTIONS.assistantThreads),
      where('userId', '==', auth.currentUser.uid)
    )
  )

  const threads = useMemo(() => {
    if (!threadsRaw?.length) {
      return []
    }

    return threadsRaw.sort(sortThreads).map(mapThread)
  }, [threadsRaw])

  return [threads, loading, error]
}

export const useThread = threadId => {
  const threadRef = threadId ? doc(firestoreDb, COLLECTIONS.assistantThreads, threadId) : null

  const [threadRaw, loading, error] = useDocumentData(threadRef)

  const thread = useMemo(() => {
    if (!threadRaw) {
      return null
    }

    return mapThread(threadRaw)
  }, [threadRaw])

  return [thread, loading, error]
}

export const updateThreadData = async (threadId, data) => {
  const threadRef = doc(firestoreDb, COLLECTIONS.assistantThreads, threadId)

  if (!threadId) {
    throw new Error('No thread id provided')
  }

  if (data?.messages) {
    throw new Error('Cannot update messages directly')
  }

  if (data?.assistantId) {
    throw new Error('Cannot update assistantId directly')
  }

  if (data?.userId) {
    throw new Error('Cannot update userId directly')
  }

  if (data?.createdAt) {
    throw new Error('Cannot update createdAt directly')
  }

  if (data?.id) {
    throw new Error('Cannot update id directly')
  }

  const updatedAt = new Date()

  await updateDoc(threadRef, { ...data, updatedAt })
}

export const markThreadAsSeen = async threadId => {
  if (!threadId) {
    throw new Error('No thread id provided')
  }

  await updateThreadData(threadId, { seen: true, seenAt: new Date() })
}

export const sendThreadMessage = async ({ threadId, message, hidden = false }) => {
  if (!message) {
    throw new Error('No message provided')
  }

  const messageThread = await sendAssistantThreadMessageFBFunction({
    threadId: threadId,
    content: message,
    hidden,
  })

  const responseThreadId = messageThread?.result?.id

  if (responseThreadId) {
    await markThreadAsSeen(responseThreadId)
  }

  return messageThread.data
}

export const deleteThread = async threadId => {
  if (!threadId) {
    throw new Error('No thread id provided')
  }

  await deleteAssistantThreadFBFunction({
    threadId: threadId,
  })
}

export const confirmThreadAction = async ({ threadId, confirmationId }) => {
  if (!threadId) {
    throw new Error('No thread id provided')
  }

  if (!confirmationId) {
    throw new Error('No confirmation id provided')
  }

  return await resolveAssistantThreadConfirmationFBFunction({ threadId, confirmationId })
}

export const cancelThreadAction = async ({ threadId, confirmationId }) => {
  if (!threadId) {
    throw new Error('No thread id provided')
  }

  if (!confirmationId) {
    throw new Error('No confirmation id provided')
  }

  return await cancelAssistantThreadConfirmationFBFunction({ threadId, confirmationId })
}

export const cancelAssistantThreadActivity = async threadId => {
  if (!threadId) {
    throw new Error('No thread id provided')
  }

  return cancelAssistantThreadActivityFBFunction({ threadId })
}
