import React, { useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useUser } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

export const MChatOathChecklist = ({
  title,
  oaths,
  textBeforeOaths,
  first,
  onProceed = () => {},
}) => {
  const { user, assistant } = useUser()
  const [isOathed, setIsOathed] = useState(false)

  const handleOnProceed = () => {
    setIsOathed(true)
    onProceed()
  }

  return (
    <div className="py-3 px-1.5">
      <span className="py-1 px-2 text-xs text-codGray font-semibold bg-green rounded uppercase">
        commitment
      </span>
      <div className="flex flex-col pt-3 px-2">
        {title && <h4 className="mb-3 text-[20px] leading-[26px] font-p22">{title}</h4>}
        {textBeforeOaths && <p className="mb-3 text-base">{textBeforeOaths}</p>}
        <ul>
          {oaths.map((oath, index) => (
            <li key={index} className="mb-2">
              {_.template(oath)({ user, assistant, input: assistant })}
            </li>
          ))}
        </ul>
        {!isOathed && (
          <>
            <div className="h-1 mt-2 mb-4 bg-codGray" />
            <AButton className="ml-auto" onClick={handleOnProceed} dontRound outline small>
              Confirm commitment
            </AButton>
          </>
        )}
      </div>
    </div>
  )
}

MChatOathChecklist.propTypes = {
  title: PropTypes.string,
  textBeforeOaths: PropTypes.string,
  oaths: PropTypes.array,
  onProceed: PropTypes.func.isRequired,
  first: PropTypes.bool,
}
