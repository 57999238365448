import React, { useContext, createContext } from 'react'

export const SummaryContext = createContext({})
SummaryContext.displayName = 'SummaryContext'

export const SummaryProvider = ({
  children,
  missionTitle,
  points,
  pointsForCompletion,
  isCurrent,
  translations,
}) => {
  return (
    <SummaryContext.Provider
      value={{ missionTitle, points, pointsForCompletion, isCurrent, translations }}
    >
      {children}
    </SummaryContext.Provider>
  )
}

export const useSummary = () => {
  const context = useContext(SummaryContext)

  if (context === undefined) {
    throw new Error('useSummary must be nested in SummaryProvider')
  }

  return context
}
