import { useCallback, useEffect } from 'react'

export const useHandleScrollbarSpace = () => {
  const addSpaceForScrollbar = useCallback(() => {
    const htmlElement = document?.getElementsByTagName('html')?.[0]

    if (!!htmlElement) {
      htmlElement.style.scrollbarGutter = 'stable'
    }
  }, [])

  const removeSpaceForScrollbar = useCallback(() => {
    const htmlElement = document?.getElementsByTagName('html')?.[0]

    if (!!htmlElement) {
      htmlElement.style.scrollbarGutter = null
    }
  }, [])

  useEffect(() => {
    addSpaceForScrollbar()

    return () => {
      removeSpaceForScrollbar()
    }
  }, [addSpaceForScrollbar, removeSpaceForScrollbar])

  return null
}
