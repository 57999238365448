import { MSkeleton } from '@molecules/MSkeleton'

export const OAccountSkeleton = () => (
  <div>
    <MSkeleton height={18} width={160} />
    <div className="mt-3 py-2 px-6 bg-white rounded-lg sm:max-w-lg">
      <div className="py-3.5 border-b border-gray-200">
        <MSkeleton width={50} height={15} />
        <div className="mb-2" />
        <MSkeleton width={200} height={18} />
      </div>
      <div className="py-3.5 border-b border-gray-200">
        <MSkeleton width={70} height={14} />
        <div className="mb-2" />
        <MSkeleton width={80} height={18} />
      </div>
      <div className="py-3.5">
        <MSkeleton width={50} height={14} />
        <div className="mb-2" />
        <MSkeleton width={100} height={18} />
      </div>
    </div>
  </div>
)
