import React from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from '@hooks'

import { OModal } from './OModal'
import { AButton } from '@atoms/AButton'

export const OFetchDataErrorModal = ({
  isOpen = false,
  title,
  isLoading = false,
  isRetryAvailable = true,
  onRetry = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslations()

  return (
    <OModal isOpen={isOpen} onClose={onClose}>
      <div className="text-lg text-center">{title || t('error.somethingWrong')}</div>
      {isRetryAvailable && (
        <div className="w-full mt-6">
          <AButton onClick={onRetry} isLoading={isLoading} className="w-full">
            {t('retry')}
          </AButton>
        </div>
      )}
    </OModal>
  )
}

OFetchDataErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  isRetryAvailable: PropTypes.bool,
  onRetry: PropTypes.func,
  onClose: PropTypes.func,
}
