import React, { useCallback } from 'react'
import useStore from '@store/useStore'
import { useNavigate } from 'react-router-dom'
import { generateRoute, ROUTES } from '@const/Routes'

import { Mission } from '@edwinsandbox/react-web'
import { OPageWrapper } from '@organisms/OPageWrapper'
import { MChatInput } from '@molecules/MChatInput'
import { useFlowThread } from '@hooks/useFlowThread'

export const OFlow = ({ children, assistantId }) => {
  const navigate = useNavigate()

  const user = useStore(state => state.user)

  const {
    thread,
    modifiedThread,
    sendMessage,
    handleUserInput,
    handleAssistantFlowAction,
    isNewMessagesLoading,
    isLastMsgNeedsUserAction,
    areMessagesToProcess,
  } = useFlowThread(assistantId)

  const assistantFlowAction = useCallback(
    async ({ type, input }) => {
      try {
        let navigateTo = ROUTES.ROOT

        const { id } = await handleAssistantFlowAction({ type, input })

        if (id) {
          navigateTo = generateRoute(ROUTES.ASSISTANT, { assistantId: id })
        }

        navigate(navigateTo)
      } catch (error) {
        console.error(error)
      }
    },
    [handleAssistantFlowAction, navigate]
  )

  return (
    <OPageWrapper
      className="flex flex-col h-full"
      contentClassName="flex flex-col h-full max-h-full !p-0 !pb-0.5"
    >
      <div>{children}</div>
      <div className="flex-1 flex flex-col max-h-full overflow-hidden">
        <div className="h-full pb-2 overflow-hidden">
          {!!thread && (
            <Mission
              user={user}
              thread={modifiedThread}
              onAssistantAction={assistantFlowAction}
              onSendHiddenMsg={msg => {
                sendMessage(msg, true)
              }}
              shouldDisplayCover={false}
              shouldDisplaySidebar={false}
              shouldScrollItself
              isNewMessagesLoading={isNewMessagesLoading || areMessagesToProcess}
            />
          )}
        </div>
        <MChatInput
          handleOnSubmit={handleUserInput}
          isInputDisabled={isNewMessagesLoading || areMessagesToProcess || isLastMsgNeedsUserAction}
          isNewMessagesLoading={isNewMessagesLoading || areMessagesToProcess}
          className="px-2.5 sm:px-0"
        />
      </div>
    </OPageWrapper>
  )
}
