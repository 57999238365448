import React from 'react'
import classnames from 'classnames'

import ReactMarkdown from 'react-markdown'

export const AMarkdown = ({ children, className, paragraphStyles }) => {
  return (
    <ReactMarkdown
      className={className}
      components={{
        a: ({ href, children }) => (
          <a className="underline " href={href} target="_blank" rel="noreferrer">
            {children[0]}
          </a>
        ),
        p: ({ children }) => {
          return <p className={classnames(paragraphStyles || 'leading-[22px]')}>{children}</p>
        },
        ol: ({ children }) => {
          return <ol className="mt-1.5 mb-4 last:mb-1 pb-0.5 ol-list">{children}</ol>
        },
        ul: ({ children }) => {
          return <ul className="mt-1.5 ml-1 mb-4 last:mb-1 pb-0.5 ul-list">{children}</ul>
        },
        li: ({ children, ...rest }) => {
          return <li className="relative ml-5 pb-1.5 last:pb-0">{children}</li>
        },
        h2: ({ node, ...props }) => {
          return <h2 {...props} className="mb-2 text-lg font-bold font-p22" />
        },
        strong: ({ node, ...props }) => {
          return <strong {...props} className="font-semibold" />
        },
      }}
    >
      {children}
    </ReactMarkdown>
  )
}
