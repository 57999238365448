import classnames from 'classnames'

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'

export const MChatStats = ({ title, lastWeekDate, previousWeekDate, lastWeek, previousWeek }) => {
  return (
    <div className="flex flex-col py-3 px-3.5 lg:px-5">
      {lastWeekDate && <div className="mb-1 text-xs text-codGray/60">{lastWeekDate}</div>}
      {title && <div className="mb-2 text-xl font-bold font-p22">{title}</div>}
      <div className="grid grid-cols-1 pt-2">
        {Object.keys(previousWeek).map((key, index) => (
          <div
            key={index}
            className="pb-5 px-2 mb-3.5 border-b border-gray-200 last:border-0 last:mb-0 last:pb-4"
          >
            <div className="flex">
              <span className="text-lg font-p22 first-letter:uppercase">{key}</span>
              {lastWeek[key] - previousWeek[key] > 0 && (
                <ArrowUpIcon
                  className="ml-auto h-4 w-4 flex-shrink-0 self-center text-green-500"
                  aria-hidden="true"
                />
              )}
              {lastWeek[key] - previousWeek[key] < 0 && (
                <ArrowDownIcon
                  className="ml-auto h-4 w-4 flex-shrink-0 self-center text-red-500"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="flex items-baseline">
              <div className="flex items-baseline mr-10 text-2xl font-semibold">
                {lastWeek[key]}
                <span className="ml-1 text-sm font-normal text-gray-500">
                  from {previousWeek[key]}
                </span>
              </div>
              <div
                className={classnames(
                  'ml-auto inline-flex items-baseline rounded-full py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                )}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
