export const CONTENT_ENDPOINTS = {
  GET_AVAILABLE_CONTENT: {
    name: 'GET_AVAILABLE_CONTENT',
    type: 'getAvailableContent',
    endpoint: '/available-content',
    method: 'GET',
  },
}

export const PROGRESS_ENDPOINTS = {
  GET_PROGRESS: {
    name: 'GET_PROGRESS',
    type: 'getProgress',
    endpoint: '/progress',
    method: 'GET',
  },
  SET_PROGRESS: {
    name: 'SET_PROGRESS',
    type: 'setProgress',
    endpoint: '/progress',
    method: 'PUT',
  },
}

export const GOALS_ENDPOINTS = {
  GET_ALL_GOALS: {
    name: 'GET_ALL_GOALS',
    type: 'getGoals',
    endpoint: '/goals',
    method: 'GET',
  },
  CREATE_OR_UPDATE_GOALS: {
    name: 'CREATE_OR_UPDATE_GOALS',
    type: 'createGoalsAndInstances',
    endpoint: '/goals',
    method: 'POST',
  },
  UPDATE_GOAL_INSTANCES: {
    name: 'UPDATE_GOAL_INSTANCES',
    type: 'updateGoalInstances',
    endpoint: '/goal-instances',
    method: 'PATCH',
  },
  SEARCH_GOAL_INSTANCES: {
    name: 'SEARCH_GOAL_INSTANCES',
    type: 'searchGoalInstances',
    endpoint: '/goals/search',
    method: 'POST',
  },
}

export const TIPS_ENDPOINTS = {
  GET_TIPS: {
    name: 'GET_TIPS',
    type: 'getTips',
    endpoint: '/tips',
    method: 'GET',
  },
  SET_TIPS: {
    name: 'SET_TIPS',
    type: 'setTips',
    endpoint: '/tips',
    method: 'PUT',
  },
}
