import React, { useCallback, useEffect } from 'react'
import { scrollBottom } from '../helpers/scroll-bottom'

export const useChatAnimation = (trigger, ref, shouldScrollItself) => {
  const scrollChat = useCallback(() => {
    let element

    if (shouldScrollItself) {
      element = ref.current
    } else if (typeof window !== 'undefined') {
      element = window
    }

    scrollBottom(element)
  }, [ref, shouldScrollItself])

  useEffect(() => {
    setTimeout(scrollChat, 0)
  }, [trigger, ref, scrollChat])

  return scrollChat
}
