import React, { useCallback, useRef } from 'react'
import classNames from 'classnames'
import { useChatAnimation } from '@edwin/react-sdk'
import { useMessages } from '../../utils/useMessages'

import ReactCanvasConfetti from 'react-canvas-confetti'
import { OChatMessage } from './OChatMessage'

export const OChat = ({
  initialMessages,
  completionMeta,
  onUserAction,
  onFinish,
  onExit,
  onProgress,
  onStepUpdated,
  onQuestion,
  onAssistant,
  shouldScrollItself = false,
  isNewMessagesLoading = false,
}) => {
  const refWrapper = useRef()
  const refAnimationInstance = useRef(null)

  const getInstance = useCallback(instance => {
    refAnimationInstance.current = instance
  }, [])

  const makeShot = useCallback((particleRatio, opts) => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.98, x: 0.5 },
        particleCount: Math.floor(200 * particleRatio),
      })
    }
  }, [])

  const triggerConfetti = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    })

    makeShot(0.2, {
      spread: 60,
    })

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    })
  }, [makeShot])

  const messages = useMessages({
    initialMessages,
    completionMeta,
    onUserAction,
    onFinish,
    onExit,
    onProgress,
    onStepUpdated,
    onQuestion,
    onAssistant,
    triggerConfetti,
    isNewMessagesLoading,
    continuousWaitFlag: true,
  })

  const scrollChatBottom = useChatAnimation(messages, refWrapper, shouldScrollItself)

  return (
    <>
      <div
        ref={refWrapper}
        className={classNames('px-2.5', shouldScrollItself && 'h-full overflow-y-auto')}
      >
        {messages.map(msg => (
          <div key={msg.renderId} className="animate-message first:pt-3 opacity-0">
            <OChatMessage msg={msg} scrollChatBottom={scrollChatBottom} missionPath={messages} />
          </div>
        ))}
      </div>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          pointerEvents: 'none',
          width: '100%',
          height: '100%',
        }}
      />
    </>
  )
}
