import React, { forwardRef } from 'react'
import { getSanityImage } from '@edwin/react-sdk'
import classnames from 'classnames'

import ProgressiveImage from 'react-progressive-graceful-image'
import { AIconCompleted, AIconLocked } from '@atoms/AIcons'

export const OMissionCover = forwardRef(
  ({ mission, onClick = () => {}, className, isActive }, ref) => {
    const { coverImageUrl, coverImageMeta, isCurrent, isCompleted, startsOn } = mission
    const isLocked = !isCompleted && !isCurrent

    const placeholderImage = getSanityImage(coverImageUrl, {
      width: isActive ? 296 : 293,
      height: isActive ? 410 : 320,
      crop: coverImageMeta?.crop,
      hotspot: coverImageMeta?.hotspot,
      quality: 30,
    })

    const coverImage = getSanityImage(coverImageUrl, {
      width: (isActive ? 296 : 293) * 3,
      height: (isActive ? 410 : 320) * 3,
      crop: coverImageMeta?.crop,
      hotspot: coverImageMeta?.hotspot,
    })

    return (
      <button
        ref={ref}
        onClick={isLocked ? undefined : onClick}
        tabIndex={isLocked ? -1 : 0}
        className={classnames(
          'relative flex flex-col items-start bg-white rounded-lg overflow-hidden transition-opacity w-[276px]',
          'my-1 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-codGray ',
          isLocked ? 'cursor-auto pointer-events-none' : 'hover:opacity-90 cursor-pointer',
          isActive ? 'h-[410px] lg:h-[410px] shadow-lg' : 'h-[360px] lg:h-[380px] shadow-md',
          className
        )}
      >
        <div className="absolute top-3 right-3 flex flex-col">
          {isCompleted && <AIconCompleted className="w-5 h-5 mb-1.5 bg-codGray/90 opacity-80" />}
          {isLocked && <AIconLocked className="w-5 h-5 bg-[#EAEAEA] fill-gray-500 opacity-80" />}
        </div>
        <div className="w-full h-auto overflow-hidden">
          {coverImage && (
            <ProgressiveImage placeholder={placeholderImage} src={coverImage}>
              {src => <img className="w-full h-full object-cover" src={src} alt="mission cover" />}
              {/* {src => (
                <div className="pt-4 px-2.5">
                  <svg width="0" height="0">
                    <defs>
                      <clipPath id="myClip">
                        <path
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M179.267 10.5379V163.031V170.982C179.267 171.22 179.465 171.41 179.703 171.41H230.462C236.282 171.41 241 176.128 241 181.947V212.728C241 216.032 238.321 218.712 235.016 218.712H219.132H179.703C173.883 218.712 169.165 213.994 169.165 208.174V187.07C169.165 186.832 168.967 186.642 168.729 186.642H124.694L10.538 186.642C4.71808 186.642 9.15527e-05 181.925 9.15527e-05 176.105V10.5379C9.15527e-05 4.71796 4.71808 0 10.538 0H168.729C174.549 0 179.267 4.71797 179.267 10.5379Z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <img src={src} alt="" className="clip-svg w-full object-fit" />
                </div>
              )} */}
            </ProgressiveImage>
          )}
        </div>
        <div className="flex-1 py-2 min-h-[120px] px-4 text-left">
          <h4
            className={classnames(
              'font-p22 font-medium line-clamp-3',
              isLocked ? 'opacity-50' : '',
              isActive ? 'text-xl lg:text-2xl' : 'text-lg lg:text-xl'
            )}
          >
            {mission.title}
          </h4>
          {startsOn && <p className="mt-2 text-sm font-medium uppercase opacity-50">{startsOn}</p>}
        </div>
      </button>
    )
  }
)
