import React, { useMemo } from 'react'

import Lottie from 'lottie-react'

export const MChatLottie = ({ title, description, lottieJson }) => {
  const animationData = useMemo(() => {
    try {
      return JSON.parse(lottieJson)
    } catch (error) {
      console.error('Invalid lottieJson', error)
      return null
    }
  }, [lottieJson])

  return (
    <div>
      {(title || description) && (
        <div className="pt-3 px-3.5">
          {title && <h4 className="mb-1 text-2xl font-p22">{title}</h4>}
          {description && <p className="mb-1 text-sm">{description}</p>}
        </div>
      )}
      {animationData && <Lottie animationData={animationData} />}
    </div>
  )
}
