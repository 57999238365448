import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const MInnerZoomImage = ({ src, width, height, imgAttributes, isZoomed, fadeDuration }) => (
  <img
    {...imgAttributes}
    className={classNames(
      'pointer-events-none max-h-[90vh]',
      isZoomed && 'invisible opacity-0',
      imgAttributes.className
    )}
    style={{
      transition: `opacity 0ms linear ${isZoomed ? fadeDuration : 0}ms, visibility 0ms linear ${
        isZoomed ? fadeDuration : 0
      }ms`,
    }}
    src={src}
    width={width}
    height={height}
  />
)

MInnerZoomImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  imgAttributes: PropTypes.object,
  isZoomed: PropTypes.bool,
  fadeDuration: PropTypes.number,
}
