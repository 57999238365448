import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'

import {
  useThread,
  sendThreadMessage,
  confirmThreadAction,
  cancelThreadAction,
} from '@services/Assistant'

import { MChatActionConfirmationModal } from '@molecules/MChatActionConfirmationModal'
import { OInsightsBanner } from '@organisms/OInsightsBanner'

export const OInsights = ({ threadId, onCloseBanner }) => {
  const [isChatActionConfirmationModalOpen, setIsChatActionConfirmationModalOpen] = useState(false)
  const [confirmationModalData, setConfirmationModalData] = useState(null)
  const modalCloseCallbackRef = useRef(null)
  const [confirmationResult, setConfirmationResult] = useState(null)

  const [mostRecentInsight] = useThread(threadId)

  const shouldShowThreadConfirmation = useMemo(() => {
    return mostRecentInsight?.threadConfirmationInProgress
  }, [mostRecentInsight?.threadConfirmationInProgress])

  const threadConfirmationData = useMemo(() => {
    const recentConfirmation = mostRecentInsight?.threadConfirmations?.[0]

    if (recentConfirmation) return recentConfirmation

    return null
  }, [mostRecentInsight?.threadConfirmations])

  const handleModalConfirm = async () => {
    if (shouldShowThreadConfirmation) {
      await confirmThreadAction({
        threadId: mostRecentInsight?.id,
        confirmationId: threadConfirmationData?.id,
      })
    }

    setConfirmationResult(true)
    setIsChatActionConfirmationModalOpen(false)
    setConfirmationModalData({})
  }

  const handleModalClose = async () => {
    if (shouldShowThreadConfirmation) {
      await cancelThreadAction({
        threadId: mostRecentInsight?.id,
        confirmationId: threadConfirmationData?.id,
      })
    }

    setConfirmationResult(false)
    setIsChatActionConfirmationModalOpen(false)
    setConfirmationModalData({})
  }

  const handleSendMessage = useCallback(
    async ({ message }) => {
      await sendThreadMessage({ threadId, message })
    },
    [threadId]
  )

  useEffect(() => {
    if (shouldShowThreadConfirmation && threadConfirmationData) {
      setConfirmationModalData({
        title: threadConfirmationData.description.title,
        body: threadConfirmationData.description.body,
        data: threadConfirmationData,
      })
      setIsChatActionConfirmationModalOpen(true)
    }
  }, [shouldShowThreadConfirmation, threadConfirmationData])

  // Effect to detect modal close and resolve the promise
  useEffect(() => {
    if (!isChatActionConfirmationModalOpen && modalCloseCallbackRef.current !== null) {
      modalCloseCallbackRef.current(confirmationResult)
      modalCloseCallbackRef.current = null // Reset the ref after calling the callback
      setConfirmationResult(null) // Reset the confirmation result
    }
  }, [isChatActionConfirmationModalOpen, confirmationResult])

  return (
    <>
      {!!mostRecentInsight && (
        <OInsightsBanner
          threadId={threadId}
          onSendMessage={handleSendMessage}
          onClose={onCloseBanner}
        />
      )}

      <MChatActionConfirmationModal
        isOpen={isChatActionConfirmationModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
        title={confirmationModalData?.title}
        body={confirmationModalData?.body}
      />
    </>
  )
}
