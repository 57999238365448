import PropTypes from 'prop-types'
import classNames from 'classnames'

export const MInnerZoomImageFull = ({
  src,
  fadeDuration,
  top,
  left,
  isZoomed,
  onLoad,
  onDragStart,
  onDragEnd,
  onFadeOut,
}) => {
  return (
    <img
      className={classNames(
        'absolute block !max-w-none',
        isZoomed
          ? 'visible pointer-events-auto opacity-1 cursor-zoom-out touch-none'
          : 'invisible pointer-events-none opacity-0'
      )}
      style={{
        top: top,
        left: left,
        transition: `opacity ${fadeDuration}ms linear, visibility ${fadeDuration}ms linear`,
      }}
      src={src}
      onLoad={onLoad}
      onTouchStart={onDragStart}
      onTouchEnd={onDragEnd}
      onMouseDown={onDragStart}
      onMouseUp={onDragEnd}
      onTransitionEnd={onFadeOut}
      draggable="false"
      alt=""
    />
  )
}

MInnerZoomImageFull.propTypes = {
  src: PropTypes.string,
  fadeDuration: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  isZoomed: PropTypes.bool,
  onLoad: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onFadeOut: PropTypes.func,
}
