import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { AButton } from '@atoms/AButton'

import useStore from '@store/useStore'
import { useTranslations } from '@hooks'

import { OModal } from './OModal'

export const OLanguageModal = ({
  title = 'Language',
  onLanguageChange = arg => {},
  onClose = () => {},
}) => {
  const { languages, currentLanguage, systemLanguage, defaultLanguage, changeLanguage } =
    useTranslations()

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const user = useStore(state => state.user)
  const isAuthUserLoading = useStore(state => state.isAuthUserLoading)
  const setNewLanguage = useStore(state => state.setNewLanguage)

  const handleChangeLanguage = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    try {
      const languageCode = systemLanguage?.code
      setNewLanguage(languageCode)
      await onLanguageChange(languageCode) // callback
    } catch (err) {
      setError('Error changing language')
    }

    setIsLoading(false)
    setIsOpen(false)
  }, [onLanguageChange, setNewLanguage, systemLanguage?.code])

  // useEffect(() => {
  //   const isSystemLanguageDifferent =
  //     systemLanguage?.code !== defaultLanguage?.code &&
  //     systemLanguage?.code !== currentLanguage?.code
  //
  //   const isAdditionalLanguageSupported = !!languages
  //     .filter(lang => lang.code !== defaultLanguage?.code)
  //     .find(lang => lang.code === systemLanguage?.code)
  //
  //   if (
  //     !isAuthUserLoading &&
  //     !user?.language &&
  //     isSystemLanguageDifferent &&
  //     isAdditionalLanguageSupported
  //   ) {
  //     setIsOpen(true)
  //   }
  // }, [
  //   languages,
  //   currentLanguage,
  //   defaultLanguage,
  //   systemLanguage,
  //   isAuthUserLoading,
  //   user?.language,
  // ])

  return (
    <OModal
      title={`We have detected that your system language is ${systemLanguage?.name}`}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
        onClose()
      }}
    >
      <p className="text-gray-900 text-sm">{`Do you want to change language to ${systemLanguage?.name}?`}</p>

      <div className="mt-4">
        <AButton onClick={handleChangeLanguage} isLoading={isLoading}>
          Change language
        </AButton>
      </div>

      {!!error && <p className="mt-1 text-xs text-persianRed">{error}</p>}
    </OModal>
  )
}

OLanguageModal.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
}
