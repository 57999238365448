import { MChatUserOptions } from '../components/molecules/MChatUserOptions'
import { MChatBotMsg } from '../components/molecules/MChatBotMsg'
import { MChatMsgError } from '../components/molecules/MChatMsgError'
import { MChatGif } from '../components/molecules/MChatGif'
import { MChatImage } from '../components/molecules/MChatImage'
import { MChatFormatted } from '../components/molecules/MChatFormatted'
import { MChatInfographicIllustration } from '../components/molecules/MChatInfographicIllustration'
import { MChatInfographicAnimatedNumber } from '../components/molecules/MChatInfographicAnimatedNumber'
import { MChatInfographicPiechart } from '../components/molecules/MChatInfographicPiechart'
import { MChatInteractiveChecklist } from '../components/molecules/MChatInteractiveChecklist'
import { MChatOathChecklist } from '../components/molecules/MChatOathChecklist'
import { MChatVideo } from '../components/molecules/MChatVideo'
import { MChatAudio } from '../components/molecules/MChatAudio'
import { MChatGoal } from '../components/molecules/MChatGoal'
import { MChatDefaultComponent } from '../components/molecules/MChatDefaultComponent'
import { MChatMissionSummary } from '../components/molecules/MChatMissionSummary'
import { MChatQuestionnaireAnswersSelectable } from '../components/molecules/MChatQuestionnaireAnswersSelectable'
import { ATypingIndicator } from '../components/atoms/ATypingIndicator'
import { MChatStats } from '../components/molecules/MChatStats'
import { MChatMilestonePrompt } from '../components/molecules/MChatMilestonePrompt'
import { MChatLottie } from '../components/molecules/MChatLottie'
import { MChatTracker } from '../components/molecules/MChatTracker'
import { MChatUserInput } from '../components/molecules/MChatUserInput'
import { MChatInteractiveChat } from '../components/molecules/MChatInteractiveChat'
import { MChatDisplayGoal } from '../components/molecules/MChatDisplayGoal'
import { MChatDisplayMultipleGoals } from '../components/molecules/MChatDisplayMultipleGoals'
import { MChatDisplayMission } from '../components/molecules/MChatDisplayMission'
import { MChatDisplayMultipleMissions } from '../components/molecules/MChatDisplayMultipleMissions'
import { MChatCreateGoal } from '../components/molecules/MChatCreateGoal'
import { MAssistantChatFlow } from '../components/molecules/MAssistantChatFlow'
import { MChatSelectableOptions } from '../components/molecules/MChatSelectableOptions'
import { MAssistantChatEndFlow } from '../components/molecules/MAssistantChatEndFlow'
import { MChatNumericalRange } from '../components/molecules/MChatNumericalRange'
import { MChatDateTimePicker } from '../components/molecules/MChatDateTimePicker'
import { MChatText } from '../components/molecules/MChatText'
import { MChatComponentFlow } from '../components/molecules/MChatComponentFlow'

const CUSTOM_GOAL_COMPONENTS = {
  'scale-of-values-goal': MChatGoal,
  'numerical-entry-goal': MChatGoal,
  'simple-choice-goal': MChatGoal,
  'text-entry-goal': MChatGoal,
  'multiple-choice-goal': MChatGoal,
}

export const configChatComponents = {
  'user-options': MChatUserOptions,
  'user-input': MChatUserInput,
  bot: MChatBotMsg,
  text: MChatText,
  video: MChatVideo,
  image: MChatImage,
  gif: MChatGif,
  formatted: MChatFormatted,
  'formatted-list': MChatFormatted,
  'infographic-illustration': MChatInfographicIllustration,
  'infographic-animated-number': MChatInfographicAnimatedNumber,
  'infographic-piechart': MChatInfographicPiechart,
  'interactive-checklist': MChatInteractiveChecklist,
  'oath-checklist': MChatOathChecklist,
  error: MChatMsgError,
  goal: MChatGoal,
  ...CUSTOM_GOAL_COMPONENTS,
  audio: MChatAudio,
  congratulations: MChatMissionSummary,
  'questionnaire-answers-selectable': MChatQuestionnaireAnswersSelectable,
  loading: ATypingIndicator,
  stats: MChatStats,
  'milestone-prompt': MChatMilestonePrompt,
  'lottie-animation': MChatLottie,
  'goal-tracker': MChatTracker,
  'interactive-chat': MChatInteractiveChat,
  'display-goal': MChatDisplayGoal,
  'display-multiple-goals': MChatDisplayMultipleGoals,
  'create-goal': MChatCreateGoal,
  'display-mission': MChatDisplayMission,
  'display-multiple-missions': MChatDisplayMultipleMissions,
  flow_component: MAssistantChatFlow,
  'selectable-options': MChatSelectableOptions,
  // 'end-flow-component': MAssistantChatEndFlow,
  'numerical-range': MChatNumericalRange,
  'date-time-picker': MChatDateTimePicker,
  'component-flow': MChatComponentFlow,
  // '': MChatDefaultComponent,
}
