import { useState, useCallback, useEffect } from 'react'

import { checkIfMobile } from '@utils/helpers'

export const useIsBreakpoint = (width = 1023) => {
  const [isBreakpoint, setIsBreakpoint] = useState(checkIfMobile(width))

  const handleResize = useCallback(() => {
    setIsBreakpoint(checkIfMobile(width))
  }, [width])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return isBreakpoint
}
