import { useState, useEffect, useCallback } from 'react'

export const useIsOnline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const onlineHandler = useCallback(() => {
    setIsOnline(true)
  }, [])

  const offlineHandler = useCallback(() => {
    setIsOnline(false)
  }, [])

  useEffect(() => {
    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }, [offlineHandler, onlineHandler])

  return isOnline
}
