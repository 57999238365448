import { useCallback, useState } from 'react'

import { useGoalProvider } from '@edwin/react-sdk'

import { AButton } from '../atoms/AButton'
import { AMarkdown } from '../atoms/AMarkdown'

const ASSISTANT_TYPES = {
  program_finder: 'program_finder',
  behavior_finder: 'behavior_finder',
  behavior_attributes: 'behavior_attributes',
  behavior_prompt: 'behavior_prompt',
  triage: 'triage',
  choose_behavior: 'choose_behavior',
  check_in: 'check_in',
  end: 'end',
}

const defaultCtaText = {
  program_finder: "Let's do it!",
  behavior_finder: "Let's do it!",
  behavior_attributes: "Let's do it!",
  behavior_prompt: "Let's do it!",
  triage: "Let's do it!",
  choose_behavior: "Let's do it!",
  check_in: "Let's do it!",
  end: 'Thank you!',
}

export const MAssistantChatFlow = ({ text, name, ctaText, output = {} }) => {
  const { onAssistantAction = () => {} } = useGoalProvider()

  const [isLoading, setIsLoading] = useState(false)
  const [isFinished, setIsFinished] = useState(false)

  const handleAssistantAction = useCallback(async () => {
    if (isLoading || isFinished) {
      return
    }

    setIsLoading(true)
    const type = ASSISTANT_TYPES[name]

    await onAssistantAction({ type, input: output })
    setIsLoading(false)
    setIsFinished(true)
  }, [output, isLoading, isFinished, name, onAssistantAction])

  const cta = ctaText

  if (!cta) {
    handleAssistantAction().then(() => {})

    return null
  }

  return (
    <div className="py-3 px-3.5">
      {text && (
        <h4 className="mb-3 text-lg font-p22">
          <AMarkdown>{text}</AMarkdown>
        </h4>
      )}
      <AButton
        onClick={handleAssistantAction}
        outline
        small
        isLoading={isLoading}
        disabled={isFinished}
      >
        {cta}
      </AButton>
    </div>
  )
}
