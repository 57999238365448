import PropTypes from 'prop-types'

import { Dialog, Transition } from '@headlessui/react'

export const MChatModal = ({ children, isOpen = false, onClose = () => {} }) => (
  <Transition appear show={isOpen}>
    <Dialog
      as="div"
      style={{
        position: 'relative',
        zIndex: 99999,
      }}
      onClose={onClose}
    >
      <div className="react-web--edwinsandbox__container">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/90" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative max-w-2xl overflow-hidden">{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition>
)

MChatModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
