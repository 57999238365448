import React, { useContext, createContext, useCallback } from 'react'

export const GaolContext = createContext({})
GaolContext.displayName = 'GaolContext'

export const GoalProvider = ({
  children,
  onGoal,
  doesGoalExist,
  sendMessage,
  getGoal,
  getMission,
  getMissionProgress,
  updateGoal,
  onMissionClick,
  onAssistantAction,
  onSendHiddenMsg,
}) => {
  const handleGoal = useCallback(
    data => {
      if (onGoal instanceof Function) {
        onGoal(data)
      }
    },
    [onGoal]
  )

  const handleDoesGoalExist = useCallback(
    goalTemplateId => {
      if (doesGoalExist instanceof Function) {
        return doesGoalExist(goalTemplateId)
      }

      return false
    },
    [doesGoalExist]
  )

  const handleSendMessage = useCallback(
    msg => {
      if (sendMessage instanceof Function) {
        return sendMessage(msg)
      }

      return false
    },
    [sendMessage]
  )

  const handleGetGoal = useCallback(
    goalId => {
      if (getGoal instanceof Function) {
        return getGoal(goalId)
      }

      return null
    },
    [getGoal]
  )

  const handleGetMission = useCallback(
    missionId => {
      if (getMission instanceof Function) {
        return getMission(missionId)
      }

      return null
    },
    [getMission]
  )

  const handleGetMissionProgress = useCallback(
    (topicId, missionId) => {
      if (getMissionProgress instanceof Function) {
        return getMissionProgress(topicId, missionId)
      }

      return null
    },
    [getMissionProgress]
  )

  const handleOnMissionClick = useCallback(
    (missionId, topicId) => {
      if (onMissionClick instanceof Function) {
        return onMissionClick(missionId, topicId)
      }

      return null
    },
    [onMissionClick]
  )

  const handleUpdateGoal = useCallback(
    (id, payload, isDirty = true, changes) => {
      if (updateGoal instanceof Function) {
        return updateGoal(id, payload, isDirty, changes)
      }

      return null
    },
    [updateGoal]
  )

  const handleOnAssistantAction = useCallback(
    props => {
      if (onAssistantAction instanceof Function) {
        return onAssistantAction(props)
      }

      return null
    },
    [onAssistantAction]
  )

  const handleOnSendHiddenMsg = useCallback(
    msg => {
      if (onSendHiddenMsg instanceof Function) {
        return onSendHiddenMsg(msg)
      }

      return null
    },
    [onSendHiddenMsg]
  )

  return (
    <GaolContext.Provider
      value={{
        onGoal: handleGoal,
        doesGoalExist: handleDoesGoalExist,
        sendMessage: handleSendMessage,
        getGoal: handleGetGoal,
        getMission: handleGetMission,
        getMissionProgress: handleGetMissionProgress,
        onMissionClick: handleOnMissionClick,
        updateGoal: handleUpdateGoal,
        onAssistantAction: handleOnAssistantAction,
        onSendHiddenMsg: handleOnSendHiddenMsg,
      }}
    >
      {children}
    </GaolContext.Provider>
  )
}

export const useGoalProvider = () => {
  const context = useContext(GaolContext)

  if (context === undefined) {
    throw new Error('useUser must be nested in GoalProvider')
  }

  return context
}
