import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import _ from 'lodash'
import { nanoid } from 'nanoid/non-secure'
import { DateTime, Interval } from 'luxon'
import { GOALS_ENDPOINTS } from '@edwin/api-client'

let goalsStore = null

const goalDateFields = ['createdOn', 'updatedOn']
const goalInstanceDateFields = ['createdOn', 'updatedOn', 'startsOn', 'endsOn', 'lastTrackedOn']
const reminderDateFields = ['createdOn', 'whenToSend']

// Mapping task types to EdwinCloudApi methods
const apiMethodMapping = {
  createGoalsAndInstances: 'createGoalsAndInstances',
  searchGoalInstances: 'searchGoalInstances',
  updateGoalInstances: 'updateGoalInstances',
}

// iterate over field names that needs to be converted to timestamp
const transformDateFieldsToStrings = (
  object = {},
  dateToTimestampFields = [] // ['createdOn', 'updatedOn']
) => {
  const parsedObject = { ...object }

  for (const dateTimeField of dateToTimestampFields) {
    const fieldToTimestamp = parsedObject[dateTimeField]

    if (!fieldToTimestamp) continue

    parsedObject[dateTimeField] =
      parsedObject[dateTimeField]?.toISO() || parsedObject[dateTimeField]
  }

  return parsedObject
}

export const createGoalsStore = ({ sessionStorage, apiClient }) => {
  if (goalsStore) return goalsStore

  goalsStore = create(
    persist(
      (set, get) => ({
        requestsQueue: [],
        isQueueProcessing: false,

        goals: {},
        goalInstances: {},
        isGoalsDataLoaded: false,

        /**
         *
         * @param {*} id
         * @returns
         */
        getGoal: id => {
          return get().goals[id]
        },
        /**
         *
         * @param {*} id
         * @returns
         */
        getGoalInstance: id => {
          return get().goalInstances[id]
        },
        /**
         *
         * @param {*} id
         * @param {*} payload
         * @param {*} isDirty
         * @param {*} changes
         */
        setGoal: (id, payload, isDirty = true, changes) => {
          const localGoals = { ...get().goals }

          localGoals[id] = {
            ...payload,
            isDirty,
          }

          set({ goals: localGoals })

          if (changes) {
            const payload = transformDateFieldsToStrings(changes.payload, goalDateFields)

            get().addQueueTask({
              ...GOALS_ENDPOINTS.CREATE_OR_UPDATE_GOALS,
              payload,
            })
          }
        },
        setGoals: goals => {
          set({ goals })
        },
        /**
         *
         * @param {*} id
         * @param {*} payload
         * @param {*} isDirty
         * @param {*} changes
         */
        setGoalInstance: (id, payload, isDirty = true, changes) => {
          const localGoalInstances = { ...get().goalInstances }
          let modifiedInstance = { ...payload, isDirty }

          localGoalInstances[id] = modifiedInstance

          set({ goalInstances: localGoalInstances })

          if (changes) {
            const payload = transformDateFieldsToStrings(changes.payload, goalInstanceDateFields)

            get().addQueueTask({
              ...GOALS_ENDPOINTS.UPDATE_GOAL_INSTANCES,
              payload: [payload],
            })
          }
        },
        setGoalInstances: goalInstances => {
          set({ goalInstances })
        },
        /**
         *
         * @param {*} id
         */
        deleteLocalGoal: id => {
          const localGoals = { ...get().goals }
          const modifiedGoals = _.omit(localGoals, id)

          set({ goals: modifiedGoals })
        },
        /**
         *
         * @param {*} id
         */
        deleteLocalGoalInstance: id => {
          const localGoalInstances = { ...get().goals }
          const modifiedGoalInstances = _.omit(localGoalInstances, id)

          set({ goals: modifiedGoalInstances })
        },
        /**
         * Update goal
         * @param id
         * @param payload
         * @param isDirty
         * @param changes
         */
        updateGoal: (id, payload, isDirty = true, changes) => {
          const localGoals = { ...get().goals }
          const localGoal = localGoals[id]

          if (localGoal) {
            let modifiedGoal = { ...localGoal, ...payload, isDirty }

            localGoals[id] = modifiedGoal

            set({ goals: localGoals })

            // if (changes) {
            //   const payload = transformDateFieldsToStrings(changes.payload, goalDateFields)
            //
            //   get().addQueueTask({
            //     ...GOALS_ENDPOINTS.CREATE_OR_UPDATE_GOALS,
            //     payload: [payload],
            //     // objectId: id,
            //   })
            // }
          }
        },
        /**
         *
         * @param {*} id
         * @param {*} payload
         * @param {*} isDirty
         * @param {*} changes
         */
        updateGoalInstance: (id, payload, isDirty = true, changes) => {
          const localGoalInstances = { ...get().goalInstances }
          const localInstance = localGoalInstances[id]

          if (localInstance) {
            let modifiedInstance = { ...localInstance, ...payload, isDirty }

            localGoalInstances[id] = modifiedInstance

            set({ goalInstances: localGoalInstances })

            if (changes) {
              const changeMeta = GOALS_ENDPOINTS.UPDATE_GOAL_INSTANCES

              const payload = transformDateFieldsToStrings(changes.payload, goalInstanceDateFields)

              get().addQueueTask({
                ...changeMeta,
                payload: [payload],
                objectId: id,
              })
            }
          } else {
            throw new Error(`LOCAL updateGoalInstance: Goal instance ${id} doesn't exist`)
          }
        },
        /**
         *
         * @param {*} fromDateTime
         * @param {*} toDateTime
         */
        searchGoalInstances: (fromDateTime, toDateTime) => {
          const fromDateTimeMillis = fromDateTime.toMillis()
          const toDateTimeMillis = toDateTime.toMillis()

          const goalInstances = get().goalInstances
          const instances = Object.values(goalInstances)
            .filter(instance => {
              const isTracked = instance.isTracked

              const startsOnMillis = instance.startsOn
              const endsOnMillis = instance.endsOn
              const lastTrackedOnMillis = instance.lastTrackedOn

              const shouldIncludeByStartsOn = startsOnMillis <= toDateTimeMillis
              const shouldIncludeByEndsOn = endsOnMillis >= fromDateTimeMillis
              let shouldIncludedByTracked = !instance.isTracked

              if (isTracked && lastTrackedOnMillis) {
                const isLastTrackedOnWithinRange =
                  lastTrackedOnMillis >= fromDateTimeMillis &&
                  lastTrackedOnMillis <= toDateTimeMillis

                shouldIncludedByTracked = !!isLastTrackedOnWithinRange
              }

              return shouldIncludeByStartsOn && shouldIncludeByEndsOn && shouldIncludedByTracked
            })
            .map(instance => {
              const goal = get().getGoal(instance.goalId)

              return {
                ...instance,
                goal,
              }
            })

          return instances
        },
        /**
         *
         * @param {*} task
         */
        addQueueTask: task => {
          const randomId = nanoid()
          const modifiedTask = {
            ...task,
            id: randomId,
          }
          const queue = [...get().requestsQueue, modifiedTask]

          set({ requestsQueue: queue })
        },
        /**
         *
         * @param {*} taskId
         */
        removeTaskFromQueue: taskId => {
          const tasks = [...get().requestsQueue]
          const modifiedTasks = _.remove(tasks, ({ id }) => id !== taskId)

          set({ requestsQueue: modifiedTasks })
        },
        removeAllTasksFromQueue: () => {
          set({ requestsQueue: [] })
        },
        /**
         *
         * @returns
         */
        processQueue: async () => {
          const isQueueProcessing = get().isQueueProcessing
          const tasks = get().requestsQueue

          if (isQueueProcessing) {
            return
          }

          set({ isQueueProcessing: true })

          for (const task of tasks) {
            try {
              // Find the appropriate method in EdwinCloudApi based on task type
              const apiMethodName = apiMethodMapping[task.type]

              if (!apiMethodName) {
                throw new Error(`apiMethodName ${task.type} is not defined on apiClient`)
              }

              // Call the API method with the payload
              const data = await apiClient[apiMethodName](task.payload)

              let { goals, goalInstances } = data

              if (apiMethodName === 'updateGoalInstances') {
                // Adjust the condition to match the method name in EdwinCloudApi
                goalInstances = data
              }

              goals?.forEach(goal => {
                get().setGoal(goal.id, goal, false)
              })

              goalInstances?.forEach(goalInstance => {
                get().setGoalInstance(goalInstance.id, goalInstance, false)
              })

              get().removeTaskFromQueue(task.id)
            } catch (err) {
              console.error('RequestQueue processing error', err)
            }
          }

          set({ isQueueProcessing: false })
        },

        /**
         *
         * @param {*} goalTemplateId
         * @returns
         */
        doesGoalExist: goalTemplateId => {
          const goals = get().goals

          return !!Object.values(goals).find(({ templateId }) => templateId === goalTemplateId)
        },
        loadRemoteGoalsAndInstances: async () => {
          const data = await apiClient.getGoals()

          const localGoals = { ...get().goals }
          const localGoalInstances = { ...get().goalInstances }
          const remoteGoals = data.goals
          const remoteGoalInstances = data.goalInstances

          remoteGoals.forEach(remoteGoal => {
            const localGoal = get().getGoal(remoteGoal.id)
            const localDoesntExistOrClean = !localGoal || !localGoal?.isDirty
            const remoteDeleted = remoteGoal.isDeleted

            if (remoteDeleted) {
              delete localGoal[remoteGoal.id]
            } else if (localDoesntExistOrClean) {
              localGoals[remoteGoal.id] = remoteGoal
            }
          })

          remoteGoalInstances.forEach(remoteGoalInstance => {
            const localGoalInstance = get().getGoalInstance(remoteGoalInstance.id)
            const localDoesntExistOrClean = !localGoalInstance || !localGoalInstance?.isDirty
            const remoteDeleted = remoteGoalInstance.isDeleted

            if (remoteDeleted) {
              delete localGoalInstances[remoteGoalInstance.id]
            } else if (localDoesntExistOrClean) {
              localGoalInstances[remoteGoalInstance.id] = remoteGoalInstance
            }
          })

          set({ isGoalsDataLoaded: true, goals: localGoals, goalInstances: localGoalInstances })
        },
        getPastFinishedGoals: async (dateNow, lastDateNow) => {
          if (!dateNow || !lastDateNow) {
            return null
          }

          const today = DateTime.fromISO(dateNow)
          const lastToday = DateTime.fromISO(lastDateNow)

          const data = await apiClient.getGoals()

          const pastFinishedGoals = []

          const goals = data.goals
          const goalInstances = data.goalInstances

          const goalInstancesArray = Object.values(goalInstances || {})
          const goalInstancesGroupedByGoalId = {}

          goalInstancesArray.forEach(instance => {
            if (!goalInstancesGroupedByGoalId[instance.goalId]) {
              goalInstancesGroupedByGoalId[instance.goalId] = []
            }

            goalInstancesGroupedByGoalId[instance.goalId].push(instance)
          })

          Object.values(goalInstancesGroupedByGoalId).forEach(groupedGoalInstances => {
            const instancesSortedByDate = groupedGoalInstances.sort((a, b) => b.endsOn - a.endsOn)
            const lastGoalInstance = instancesSortedByDate[0]

            const lastGoalInstanceDate = DateTime.fromMillis(lastGoalInstance.endsOn)

            const populatePastFinishedGoalsArray = (dateYesterday, goalInstanceEndsOn) => {
              const isLastGoalInstanceYesterday = dateYesterday.hasSame(
                goalInstanceEndsOn.plus({ days: 1 }),
                'day'
              )

              if (isLastGoalInstanceYesterday) {
                const goal = goals.find(goal => goal.id === lastGoalInstance.goalId)

                pastFinishedGoals.push({ goal, goalInstances: instancesSortedByDate.reverse() })
              }
            }

            const todayIsAfterLast = dateNow.toMillis() > lastToday.toMillis()

            if (todayIsAfterLast) {
              const fromDateTimes = Interval.fromDateTimes(new Date(lastDateNow), new Date(dateNow))

              const datesDivided = fromDateTimes
                .divideEqually(fromDateTimes.count('days'))
                .map(date => {
                  const isoDate = date.toISODate()
                  const isoDateFinal = isoDate.substring(isoDate.indexOf('/') + 1, isoDate.length)

                  return DateTime.fromISO(isoDateFinal)
                })

              datesDivided.forEach(date => {
                populatePastFinishedGoalsArray(date, lastGoalInstanceDate)
              })
            } else {
              populatePastFinishedGoalsArray(today, lastGoalInstanceDate)
            }
          })

          if (!pastFinishedGoals?.length) {
            return null
          }

          return pastFinishedGoals
        },
        /**
         *
         * @param {*} payload
         */
        createGoalAndGoalInstances: payload => {
          const transformedPayload = []

          payload.forEach(({ goal, instances }) => {
            get().setGoal(goal.id, goal, true)

            const arrayItem = {}
            arrayItem.goal = transformDateFieldsToStrings(goal, goalDateFields)
            arrayItem.instances = []

            instances.forEach(instance => {
              const { reminders, ...rest } = instance
              const transformedInstance = transformDateFieldsToStrings(rest, goalInstanceDateFields)

              const transformedReminders = reminders?.map(reminder => {
                return transformDateFieldsToStrings(reminder, reminderDateFields)
              })

              transformedInstance.reminders = transformedReminders || []

              arrayItem.instances.push(transformedInstance)

              get().setGoalInstance(rest.id, rest, true)
            })

            transformedPayload.push(arrayItem)
          })

          get().addQueueTask({
            ...GOALS_ENDPOINTS.CREATE_OR_UPDATE_GOALS,
            payload: transformedPayload,
          })
        },

        clearGoalsStorage: () => {
          set({
            requestsQueue: [],
            goals: [],
            goalInstances: [],
            isGoalsDataLoaded: false,
          })
        },
      }),
      {
        name: 'goals-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )

  return goalsStore
}

export default goalsStore
