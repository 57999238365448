import { MAIViewContainer } from '@molecules/MAIViewContainer'

export const MAIViewProgress = ({ meta }) => {
  console.log('MAIViewProgress', meta)

  return (
    <MAIViewContainer {...meta}>
      {/*  */}
      {/*  */}
      {/*  */}
    </MAIViewContainer>
  )
}
