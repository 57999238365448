import { MAIViewContainer } from '@molecules/MAIViewContainer'
import { SwiperItem } from '@organisms/OTodayTips'

export const MAIViewDailyTips = ({ meta }) => {
  console.log('MAIViewDailyTips', meta)

  return (
    <MAIViewContainer {...meta}>
      <div className="flex gap-x-4">
        {meta.tips.map(tip => (
          <SwiperItem key={tip.id} tip={tip} activeItem />
        ))}
      </div>
    </MAIViewContainer>
  )
}
