import React, { useCallback, useState } from 'react'

import { AButton } from '@atoms/AButton'

const FLOW_CONFIG = [
  {
    type: 'program_finder',
    label: 'Program Finder',
  },
  {
    type: 'behavior_finder',
    label: 'Behavior Finder',
  },
  {
    type: 'behavior_attributes',
    label: 'Behavior Attributes',
  },
  {
    type: 'behavior_prompt',
    label: 'Behavior Prompt',
  },
]

const FLOW_INPUT_CONFIG = {
  program_finder: null,
  behavior_finder: [
    {
      name: 'health_goal',
      label: 'Health Goal',
      defaultValue: 'Better sleep',
    },
    {
      name: 'baseline_assessment',
      label: 'Baseline Assessment',
      defaultValue: '2',
    },
  ],
  behavior_attributes: [
    {
      name: 'health_goal',
      label: 'Health Goal',
      defaultValue: 'Better sleep',
    },
    {
      name: 'goal_behavior',
      label: 'Goal Behavior',
      defaultValue: 'Avoid caffeine',
    },
    {
      name: 'motivation_and_ability',
      label: 'Motivation and Ability',
      defaultValue: '5',
    },
    // {
    //   name: 'ability',
    //   label: 'Ability',
    //   defaultValue: '5',
    // },
  ],
  behavior_prompt: [
    {
      name: 'health_goal',
      label: 'Health Goal',
      defaultValue: 'Better sleep',
    },
    {
      name: 'goal_behavior',
      label: 'Behavior',
      defaultValue: 'Avoid caffeine',
    },
    {
      name: 'behavior_frequency_days',
      label: 'Behavior Frequency Days',
      defaultValue: 'Monday, Wednesday, Friday',
    },
  ],
}

export const OProofOfConcept = ({ onAssistantClick }) => {
  const [selectedFlow, setSelectedFlow] = useState(FLOW_CONFIG[0].type)
  const [isLoading, setIsLoading] = useState(false)

  const handleSelect = useCallback(({ target }) => {
    setSelectedFlow(target.value)
  }, [])

  const handleSubmit = useCallback(async () => {
    const type = selectedFlow
    const input =
      FLOW_INPUT_CONFIG[type]?.reduce((acc, { name }) => {
        const value = document.querySelector(`input[name="${name}"]`)?.value
        if (value !== undefined) acc[name] = value
        return acc
      }, {}) || {}
    const inputWithoutEmptyValues = Object.fromEntries(
      Object.entries(input).filter(([_, value]) => value)
    )

    setIsLoading(true)

    try {
      await onAssistantClick({ type, input: inputWithoutEmptyValues })
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [onAssistantClick, selectedFlow])

  return (
    <div className="w-full flex flex-col p-4">
      <div>
        {/*<label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">*/}
        {/*  Assistant*/}
        {/*</label>*/}
        <select
          onChange={handleSelect}
          className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-green-dark focus:ring-2 focus:ring-green-dark"
        >
          {FLOW_CONFIG.map(({ type, label }) => (
            <option key={type} value={type}>
              {label}
            </option>
          ))}
        </select>
      </div>

      {!!FLOW_INPUT_CONFIG[selectedFlow] && (
        <div className="flex flex-col gap-y-3.5 mt-6 mb-2">
          {FLOW_INPUT_CONFIG[selectedFlow]?.map(({ name, label, defaultValue }) => (
            <div className="relative" key={name}>
              <label
                htmlFor={name}
                className="absolute -top-2 left-2 inline-block bg-wildSand px-1 text-xs font-medium text-gray-900"
              >
                {label}
              </label>
              <input
                id={name}
                name={name}
                type="text"
                placeholder={defaultValue}
                defaultValue={defaultValue}
                className="block w-full rounded-md !border-0 !bg-wildSand py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-green-dark placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:!ring-green-dark"
              />
            </div>
          ))}
        </div>
      )}

      <AButton isLoading={isLoading} onClick={handleSubmit} className="mt-8">
        Generate
      </AButton>
    </div>
  )
}
