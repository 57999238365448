import { useEffect, useRef, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { DateTime } from 'luxon'

import useStore, { useEphemeralStore } from '@store/useStore'
import useGoalsStore from '@store/useGoalsStore'

export const usePastFinishedGoals = () => {
  const [pastGoals, setPastGoals] = useState()
  const lastDateNow = useStore(state => state.lastDateNow)
  const dateNow = useEphemeralStore(state => state.dateNow)
  const getPastFinishedGoals = useGoalsStore(state => state.getPastFinishedGoals)

  const dateNowRef = useRef()

  const handeGoalsFinishedYesterday = useMemo(
    () =>
      debounce(async (date, lastDate) => {
        const goalsFinishedYesterday = await getPastFinishedGoals(date, lastDate)

        setPastGoals(goalsFinishedYesterday)
      }, 300),
    [getPastFinishedGoals]
  )

  useEffect(() => {
    const lastDateNowString = DateTime.fromISO(lastDateNow).toISODate()
    const dateNowDayString = DateTime.fromISO(dateNow).toISODate()

    if (dateNowRef.current !== dateNowDayString || dateNowDayString !== lastDateNowString) {
      handeGoalsFinishedYesterday(dateNow, lastDateNow)
      dateNowRef.current = dateNowDayString
    }
  }, [dateNow, lastDateNow, handeGoalsFinishedYesterday])

  return pastGoals
}
