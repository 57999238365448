import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import _ from 'lodash'
import { nanoid } from 'nanoid/non-secure'
import { DateTime } from 'luxon'

const soonestAvailableTipDate = localTips => {
  const tomorrowDay = DateTime.now().startOf('day').plus({ days: 1, seconds: 1 }).toISO()

  if (!localTips.length) {
    return tomorrowDay
  }

  const lastAvailableTipDate = localTips.sort((a, b) => {
    return b.availableFrom - a.availableFrom
  })?.[0]?.availableFrom

  if (lastAvailableTipDate >= DateTime.fromISO(tomorrowDay).toMillis()) {
    return DateTime.fromMillis(lastAvailableTipDate).plus({ days: 1 }).toISO()
  } else {
    return tomorrowDay
  }
}

let tipsStore = null

export const createTipsStore = ({ sessionStorage, apiClient }) => {
  if (tipsStore) return tipsStore

  tipsStore = create(
    persist(
      (set, get) => ({
        requestsQueue: [],
        isQueueProcessing: false,

        // tips: [
        //   {
        //     id: '1',
        //     title: 'Plan Your Plate',
        //     description:
        //       'Adopt a nutritious diet to support your weight loss journey and overall health.',
        //     missionId: '65f54ded-28d5-45ca-8008-cb087dea8961',
        //     availableFrom: DateTime.now()
        //       .startOf('day')
        //       .plus({ seconds: 1 })
        //       .minus({ days: 6 })
        //       .toMillis(),
        //   },
        //   {
        //     id: '12',
        //     title: 'Move Your Body',
        //     description:
        //       'Incorporate regular physical activity to enhance the effects of GLP-1 medication.',
        //     missionId: '65f54ded-28d5-45ca-8008-cb087dea8961',
        //     availableFrom: DateTime.now()
        //       .startOf('day')
        //       .plus({ seconds: 1 })
        //       .minus({ days: 5 })
        //       .toMillis(),
        //   },
        //   {
        //     id: '3',
        //     title: 'Prioritize Self-Care',
        //     description:
        //       'Focus on sleep, stress reduction, and mental well-being for a holistic approach.',
        //     missionId: '937290273097',
        //     availableFrom: DateTime.now()
        //       .startOf('day')
        //       .plus({ seconds: 1 })
        //       .minus({ days: 4 })
        //       .toMillis(),
        //   },
        //   {
        //     id: '4',
        //     title: 'Connect with Others',
        //     description:
        //       'Join a support group or online community to share experiences and gain insights.',
        //     missionId: '61785817-88f8-4ffb-9dc7-72163475523b',
        //     availableFrom: DateTime.now()
        //       .startOf('day')
        //       .plus({ seconds: 1 })
        //       .minus({ days: 3 })
        //       .toMillis(),
        //   },
        //   {
        //     id: '5',
        //     title: 'Journal Your Journey',
        //     description:
        //       'Track your progress, side effects, and experiences with GLP-1 medications.',
        //     missionId: '61785817-88f8-4ffb-9dc7-72163475523b',
        //     availableFrom: DateTime.now()
        //       .startOf('day')
        //       .plus({ seconds: 1 })
        //       .minus({ days: 2 })
        //       .toMillis(),
        //   },
        //   {
        //     id: '6',
        //     title: 'Patience Is Key',
        //     description:
        //       "Give yourself time to adjust to the medication and monitor your body's response.",
        //     missionId: '61785817-88f8-4ffb-9dc7-72163475523b',
        //     availableFrom: DateTime.now()
        //       .startOf('day')
        //       .plus({ seconds: 1 })
        //       .minus({ days: 1 })
        //       .toMillis(),
        //   },
        //   {
        //     id: '7',
        //     title: 'Small Wins Matter',
        //     description:
        //       'Break down larger goals into smaller milestones and celebrate your achievements.',
        //     missionId: '61785817-88f8-4ffb-9dc7-72163475523b',
        //     availableFrom: DateTime.now()
        //       .startOf('day')
        //       .plus({ seconds: 1 })
        //       .minus({ days: 1 })
        //       .toMillis(),
        //   },
        //   {
        //     id: '8',
        //     title: 'Visualize Success',
        //     description:
        //       'Use a vision board or journal to visualize your goals and track progress.',
        //     missionId: '61785817-88f8-4ffb-9dc7-72163475523b',
        //     availableFrom: DateTime.now().startOf('day').plus({ seconds: 1 }).toMillis(),
        //   },
        //   {
        //     id: '9',
        //     title: 'Find Your Motivation',
        //     description: 'Identify your reasons for making a change and use them to stay on track.',
        //     missionId: '61785817-88f8-4ffb-9dc7-72163475523b',
        //     availableFrom: DateTime.now().startOf('day').plus({ seconds: 1, days: 1 }).toMillis(),
        //   },
        //   {
        //     id: '10',
        //     title: 'Ask for Help',
        //     description:
        //       'Reach out to your healthcare team or support network when you need assistance.',
        //     missionId: '61785817-88f8-4ffb-9dc7-72163475523b',
        //     availableFrom: DateTime.now().startOf('day').plus({ seconds: 1, days: 2 }).toMillis(),
        //   },
        //   {
        //     id: '11',
        //     title: 'Celebrate Your Success',
        //     description: 'Reward yourself for your progress and achievements, no matter how small.',
        //     missionId: '61785817-88f8-4ffb-9dc7-72163475523b',
        //     availableFrom: DateTime.now().startOf('day').plus({ seconds: 1, days: 3 }).toMillis,
        //   },
        // ],
        tips: [],
        isTipsDataLoaded: false,

        /**
         * Get tip by id
         * @param tipId
         * @returns {T}
         */
        getTip: tipId => {
          const tips = get().tips
          const tip = tips.find(tip => tip.tipId === tipId)

          return tip
        },
        /**
         * Set tip
         * @param {*} id
         * @param {*} payload
         * @param {*} isDirty
         */
        setTip: (id, payload, isDirty = true) => {
          const localTips = [...get().tips]
          const localTipsIds = localTips.map(({ tipId }) => tipId)
          let modifiedTip = { ...payload, isDirty }

          if (localTipsIds.includes(modifiedTip.tipId)) {
            const tipIndex = localTips.findIndex(({ tipId }) => tipId === modifiedTip.tipId)

            localTips[tipIndex] = { ...localTips[tipIndex], ...modifiedTip }
          } else {
            localTips.push(modifiedTip)
          }

          set({ tips: localTips })
        },
        setTips: tips => {
          set({ tips })
        },
        /**
         * Add task to queue
         * @param {*} task
         */
        addQueueTask: task => {
          const randomId = nanoid()
          const modifiedTask = {
            ...task,
            id: randomId,
          }
          const queue = [...get().requestsQueue, modifiedTask]

          set({ requestsQueue: queue })
        },
        /**
         * Remove task from queue
         * @param {*} taskId
         */
        removeTaskFromQueue: taskId => {
          const tasks = [...get().requestsQueue]
          const modifiedTasks = _.remove(tasks, ({ id }) => id !== taskId)

          set({ requestsQueue: modifiedTasks })
        },
        removeAllTasksFromQueue: () => {
          set({ requestsQueue: [] })
        },
        /**
         * Process queue tasks
         * @returns
         */
        processQueue: async () => {
          const isQueueProcessing = get().isQueueProcessing
          const tasks = get().requestsQueue

          if (isQueueProcessing) {
            return
          }

          set({ isQueueProcessing: true })

          for (const task of tasks) {
            try {
              // Call the API method with the payload
              const tips = await apiClient.setTips(task.payload)

              tips?.forEach(tip => {
                get().setTip(tip.tipId, tip, false)
              })

              get().removeTaskFromQueue(task.id)
            } catch (err) {
              console.error('RequestQueue processing error', err)
            }
          }

          set({ isQueueProcessing: false })
        },
        /**
         * Load tips from API
         * @returns {Promise<void>}
         */
        loadRemoteTips: async () => {
          const tips = await apiClient.getTips()

          const localTips = [...get().tips]

          tips.forEach(tip => {
            const localTip = get().getTip(tip.tipId)
            const localDoesntExist = !localTip
            const localClean = !localTip?.isDirty
            const remoteDeleted = tip.isDeleted

            if (remoteDeleted) {
              localTips.filter(({ tipId }) => tipId !== tip.id)
            } else if (localDoesntExist) {
              localTips.push(tip)
            } else if (localClean) {
              const tipIndex = localTips.findIndex(({ tipId }) => tipId === tip.id)

              localTips[tipIndex] = { ...localTips[tipIndex], ...tip }
            }
          })

          set({ isTipsDataLoaded: true, tips: localTips })
        },
        /**
         * Search tips by date time
         * @param {*} dateTime
         * @returns
         */
        searchTips: dateTime => {
          const tips = [...get().tips]

          if (!dateTime || !tips) {
            return undefined
          }

          const dateTimeToMillis = dateTime.toMillis()

          const availableTips = tips
            .filter(({ availableFrom }) => {
              return availableFrom <= dateTimeToMillis
            })
            .sort((a, b) => a.availableFrom - b.availableFrom)

          return availableTips
        },
        /**
         * Create tips
         * @param {*} newTips
         * @param {*} missionId
         */
        createTips: (newTips, missionId) => {
          const localTips = [...get().tips]
          const localTipsIds = localTips.map(({ tipId }) => tipId)
          let tipDate = soonestAvailableTipDate(localTips)

          const uniqueNewTipsWithAvailableDate = newTips
            .filter(({ _key }) => !localTipsIds.includes(_key))
            .map(({ _key, ...rest }) => {
              const availableFrom = tipDate

              tipDate = DateTime.fromISO(tipDate).plus({ days: 1 }).toISO()

              return {
                ...rest,
                tipId: _key,
                availableFrom,
                missionId,
              }
            })

          uniqueNewTipsWithAvailableDate.forEach(tip => {
            const payload = {
              ...tip,
              availableFrom: DateTime.fromISO(tip.availableFrom).toMillis(),
            }

            get().setTip(tip.tipId, payload, true)
          })

          get().addQueueTask({
            payload: uniqueNewTipsWithAvailableDate,
          })
        },

        clearTipsStorage: () => {
          set({
            requestsQueue: [],
            isQueueProcessing: false,
            tips: [],
            isTipsDataLoaded: false,
          })
        },
      }),
      {
        name: 'tips-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )

  return tipsStore
}

export default tipsStore
