import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import _ from 'lodash'
import { nanoid } from 'nanoid/non-secure'

import { PROGRESS_ENDPOINTS } from '@edwin/api-client'

let progressStore = null

// Mapping task types to EdwinCloudApi methods
const apiMethodMapping = {
  getAvailableContent: 'getAvailableContent',
  getProgress: 'getProgress',
  setProgress: 'setProgress',
}

export const createProgressStore = ({ sessionStorage, apiClient }) => {
  if (progressStore) return progressStore

  progressStore = create(
    persist(
      (set, get) => ({
        requestsQueue: [],
        isQueueProcessing: false,

        userContent: {
          topics: null,
          missionsAndSeries: null,
          onboardingMission: null,
        },
        userProgress: {},
        finishedTopicsIds: [],
        isProgressDataLoaded: false,

        getTopic: topicId => {
          const { topics } = get().userContent

          return topics?.find(topic => topic.id === topicId)
        },
        getTopicProgress: topicId => {
          const { topics } = get().userContent
          const userProgress = get().userProgress

          const topicsProgress = topics?.map(topic => {
            const userTopicProgress = userProgress[topic.id]

            const missionsProgress = topic?.missionsAndSeries?.map(topicMissionOrSeries => {
              const missionProgress =
                userTopicProgress?.missionsAndSeries?.[topicMissionOrSeries.id]

              return { id: topicMissionOrSeries.id, ...missionProgress }
            })

            const isCompleted = !missionsProgress.some(({ isCompleted }) => !isCompleted)
            const hasSomeCompletedMissions = missionsProgress.some(({ isCompleted }) => isCompleted)

            if (hasSomeCompletedMissions && !isCompleted) {
              const firstLockedMissionIndex = missionsProgress.findIndex(
                ({ isCompleted }) => !isCompleted
              )

              missionsProgress[firstLockedMissionIndex].isCurrent = true
            }

            return {
              id: topic.id,
              isStarted: hasSomeCompletedMissions,
              isCompleted,
              missionsAndSeries: missionsProgress,
            }
          })

          if (!topicsProgress) {
            return
          }

          const nextTopicIndex = topicsProgress?.findIndex(
            ({ isStarted, isCompleted }) => !isStarted && !isCompleted
          )
          const prevTopic = topicsProgress[nextTopicIndex - 1] || { missionsAndSeries: [] }
          const prevTopicIsCompleted = prevTopic.missionsAndSeries.every(
            ({ isCompleted }) => isCompleted
          )

          if (nextTopicIndex >= 0 && prevTopicIsCompleted) {
            topicsProgress[nextTopicIndex].isStarted = true

            const firstLockedMissionIndex = topicsProgress[
              nextTopicIndex
            ].missionsAndSeries.findIndex(({ isCompleted }) => !isCompleted)

            topicsProgress[nextTopicIndex].missionsAndSeries[
              firstLockedMissionIndex
            ].isCurrent = true
          }

          return topicsProgress.find(({ id }) => id === topicId)
        },
        getMission: missionId => {
          const { missionsAndSeries } = get().userContent

          return missionsAndSeries?.[missionId]
        },
        getMissionProgress: (topicId, missionId) => {
          const topicProgress = get().getTopicProgress(topicId)
          const missionProgress = topicProgress?.missionsAndSeries?.find(
            ({ id }) => id === missionId
          )

          return missionProgress
        },
        getTopicsLength: () => {
          const userProgress = get().userProgress

          return Object.keys(userProgress).length
        },
        getCompletedAssignmentsNumber: () => {
          const userProgress = get().userProgress

          let completedAssignments = 0

          Object.keys(userProgress).forEach(topicId => {
            const missionsAndSeries = userProgress[topicId].missionsAndSeries || {}

            completedAssignments += Object.keys(missionsAndSeries).length
          })

          return completedAssignments
        },
        setMissionProgress: (topicId, missionId, payload, isDirty = true, changes) => {
          const userProgress = { ...get().userProgress }
          const modifiedUserProgress = {
            ...userProgress,
            [topicId]: {
              ...userProgress?.[topicId],
              missionsAndSeries: {
                ...userProgress?.[topicId]?.missionsAndSeries,
                [missionId]: {
                  ...userProgress?.[topicId]?.missionsAndSeries?.[missionId],
                  ...payload,
                },
              },
            },
          }

          set({ userProgress: modifiedUserProgress })

          if (changes) {
            get().addQueueTask({
              ...PROGRESS_ENDPOINTS.SET_PROGRESS,
              payload: changes,
            })
          }
        },
        setUserProgress: userProgress => {
          set({ userProgress })
        },
        setFinishedTopic: topicId => {
          const finishedTopicsIds = get().finishedTopicsIds || []

          set({ finishedTopicsIds: [...finishedTopicsIds, topicId] })
        },
        removeFinishedTopic: topicId => {
          const finishedTopicsIds = [...get().finishedTopicsIds].filter(id => id !== topicId)

          set({ finishedTopicsIds })
        },
        loadRemoteUserContent: async () => {
          const data = await apiClient.getAvailableContent()
          const { topics, missionsAndSeries, onboardingMission } = data

          set({ userContent: { topics, missionsAndSeries, onboardingMission } })
        },
        loadRemoteUserProgress: async () => {
          const remoteUserProgress = await apiClient.getProgress()

          Object.keys(remoteUserProgress).forEach(topicId => {
            const remoteTopicStatus = remoteUserProgress[topicId]

            Object.keys(remoteTopicStatus.missionsAndSeries || {}).forEach(missionId => {
              const localMissionStatus = get().getMissionProgress(topicId, missionId)
              const remoteMissionStatus = remoteTopicStatus?.missionsAndSeries?.[missionId]
              const localDoesntExistOrClean = !localMissionStatus || !localMissionStatus?.isDirty

              if (localDoesntExistOrClean) {
                get().setMissionProgress(topicId, missionId, remoteMissionStatus, false)
              }
            })
          })
        },
        loadRemoteContentAndProgress: async () => {
          await get().loadRemoteUserContent()
          await get().loadRemoteUserProgress()

          set({ isProgressDataLoaded: true })
        },
        addQueueTask: task => {
          const randomId = nanoid()
          const modifiedTask = {
            ...task,
            id: randomId,
          }
          const queue = [...get().requestsQueue, modifiedTask]

          set({ requestsQueue: queue })
        },
        removeTaskFromQueue: taskId => {
          const tasks = [...get().requestsQueue]
          const modifiedTasks = _.remove(tasks, ({ id }) => id !== taskId)

          set({ requestsQueue: modifiedTasks })
        },
        removeAllTasksFromQueue: () => {
          set({ requestsQueue: [] })
        },
        processQueue: async () => {
          const isQueueProcessing = get().isQueueProcessing
          const tasks = get().requestsQueue

          if (isQueueProcessing) {
            return
          }

          set({ isQueueProcessing: true })

          for (const task of tasks) {
            try {
              // Find the appropriate method in EdwinCloudApi based on task type
              const apiMethodName = apiMethodMapping[task.type]

              if (!apiMethodName) {
                throw new Error(`apiMethodName ${task.type} is not defined on apiClient`)
              }

              // Call the API method with the payload
              const remoteUserProgress = await apiClient[apiMethodName](task.payload)

              Object.keys(remoteUserProgress).forEach(topicId => {
                const remoteTopicStatus = remoteUserProgress[topicId]

                Object.keys(remoteTopicStatus.missionsAndSeries || {}).forEach(missionId => {
                  const localMissionStatus = get().getMissionProgress(topicId, missionId)
                  const remoteMissionStatus = remoteTopicStatus?.missionsAndSeries?.[missionId]
                  const localDoesntExistOrClean =
                    !localMissionStatus || !localMissionStatus?.isDirty

                  if (localDoesntExistOrClean) {
                    get().setMissionProgress(topicId, missionId, remoteMissionStatus, false)
                  }
                })
              })

              get().removeTaskFromQueue(task.id)
            } catch (err) {
              console.error('RequestQueue processing error', err)
            }
          }

          set({ isQueueProcessing: false })
        },

        clearProgressStorage: () => {
          set({
            requestsQueue: [],
            isQueueProcessing: false,
            userContent: {
              topics: null,
              missionsAndSeries: null,
              onboardingMission: null,
            },
            userProgress: {},
            finishedTopicsIds: [],
            isProgressDataLoaded: false,
          })
        },
      }),
      {
        name: 'progress-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )

  return progressStore
}

export default createProgressStore
