import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useUser } from '@edwin/react-sdk'

import { useSpring, animated } from 'react-spring'

import { AMarkdown } from '../atoms/AMarkdown'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

const MIN_WIDTH_OF_ONE_NUMBER = 28

export const MChatInfographicAnimatedNumber = ({
  animateNumberFrom = '0',
  animateNumberTo,
  descriptionMarkdown,
  reset = false,
}) => {
  const { user, assistant } = useUser()
  const { val } = useSpring({ val: animateNumberTo, from: { val: animateNumberFrom } })

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="lg:flex items-center py-2 px-3.5 lg:pr-6 bg-green-dark lg:box-content">
        <animated.div
          style={{ minWidth: animateNumberTo.length * MIN_WIDTH_OF_ONE_NUMBER || 'none' }}
          className="text-green text-5xl font-extrabold"
        >
          {val.to(val => Math.floor(val))}
        </animated.div>
      </div>
      <div className="py-4 px-3.5">
        <AMarkdown>
          {_.template(descriptionMarkdown)({ user, assistant, input: assistant })}
        </AMarkdown>
      </div>
    </div>
  )
}

MChatInfographicAnimatedNumber.propTypes = {
  descriptionMarkdown: PropTypes.string,
  animateNumberFrom: PropTypes.number,
  animateNumberTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
