import React, { PureComponent } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import scrollPolyfill from 'scroll-polyfill'

import { ArrowLeft, ArrowRight, ArrowLeftCircle, ArrowRightCircle } from 'react-feather'

export class MSlider extends PureComponent {
  static propTypes = {
    snapOnScroll: PropTypes.oneOf(['mobile', 'always']),
    leftPaddingPlaceholder: PropTypes.object,
    arrows: PropTypes.oneOf(['side', 'bottom', 'none']),
    dots: PropTypes.oneOf(['mobile', 'always']),
    className: PropTypes.string,
    sliderClassName: PropTypes.string,
    arrowsClassName: PropTypes.string,
    startIndex: PropTypes.number,
  }

  static defaultProps = {
    snapOnScroll: 'mobile',
    arrows: 'side',
  }

  sliderRef = {}
  slidesRef = {}
  leftPaddingPlaceholderRef = {}

  state = {
    scrollLeftEnd: true,
    scrollRightEnd: false,
    currentIndexes: [0],
  }

  componentDidMount() {
    const { startIndex } = this.props

    scrollPolyfill()

    window.addEventListener('resize', this.handleResizeEvent)
    this.sliderRef.addEventListener('scroll', this.handleScrollEvent)

    if (startIndex) this.scrollTo(startIndex)

    this.setState(this.getArrowsVisibility())
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { startIndex, children } = this.props
    const { startIndex: prevStartIndex, children: prevChildren } = prevProps

    if (children && prevChildren.length !== children.length) {
      if (startIndex) this.scrollTo(startIndex)
      this.setState(this.getArrowsVisibility())
    }

    if (prevStartIndex !== startIndex) setTimeout(() => this.scrollTo(startIndex), 300)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeEvent)
    this.sliderRef.removeEventListener('scroll', this.handleScrollEvent)
  }

  isInViewport = element => {
    if (!element) return false

    return (
      element.offsetLeft >= this.sliderRef.scrollLeft &&
      element.offsetLeft + element.getBoundingClientRect().width <=
        this.sliderRef.scrollLeft + this.sliderRef.getBoundingClientRect().width
    )
  }

  handleResizeEvent = () => {
    setTimeout(() => this.setState(this.getArrowsVisibility()), 0)
  }

  getArrowsVisibility = () => {
    return {
      scrollLeftEnd: this.sliderRef.scrollLeft <= 30,
      scrollRightEnd:
        this.sliderRef.scrollLeft >= this.sliderRef.scrollWidth - this.sliderRef.offsetWidth,
    }
  }

  handleScrollEvent = () => {
    this.setState({
      ...this.getArrowsVisibility(),
      currentIndexes: (() => {
        const slides = Object.values(this.slidesRef)
        const visibleSlides = slides.filter(slide => this.isInViewport(slide))

        return visibleSlides.map(slide => slides.indexOf(slide))
      })(),
    })
  }

  scrollToElement = (element, scrollLeft) => {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: scrollLeft ? 'start' : 'end',
    })
  }

  scrollLeft = () => {
    const scrollToSlideIndex = Object.values(this.slidesRef).findIndex(slide => {
      return slide.getBoundingClientRect().left > this.sliderRef.getBoundingClientRect().left - 2
    })

    if (scrollToSlideIndex) {
      this.scrollTo(scrollToSlideIndex - 1)
    }
  }

  scrollRight = () => {
    const scrollToSlideIndex = Object.values(this.slidesRef).findIndex(slide => {
      const position =
        slide.getBoundingClientRect().left - this.sliderRef.getBoundingClientRect().left

      return position > 32
    })

    if (scrollToSlideIndex) this.scrollTo(scrollToSlideIndex)
  }

  handleSlideClick = index => () => {
    const scrollToSlide = this.slidesRef[index]
    const bounds = scrollToSlide.getBoundingClientRect()

    if (bounds.left - bounds.width <= 0 || bounds.right > this.sliderRef.clientWidth) {
      scrollToSlide.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  scrollTo = index => {
    const scrollToSlide = this.slidesRef[index]

    if (scrollToSlide) {
      this.sliderRef.scrollLeft =
        scrollToSlide.offsetLeft -
        (this.leftPaddingPlaceholderRef.getBoundingClientRect
          ? this.leftPaddingPlaceholderRef.getBoundingClientRect().width
          : 0)
    }
  }

  render() {
    const {
      leftPaddingPlaceholder,
      className,
      snapOnScroll,
      arrows,
      dots,
      children,
      arrowsClassName,
      sliderClassName,
    } = this.props

    const { currentIndexes, scrollLeftEnd, scrollRightEnd } = this.state

    const arrowsSideClasses =
      'hidden md:block absolute top-1/2 text-pelorous bg-white/80 -translate-y-full transition-all rounded-full z-10 cursor-pointer'

    return (
      <div className={classnames('relative', className)}>
        <div
          className={classnames(
            'flex w-full pb-6 overflow-x-auto z-0',
            'snap-x snap-mandatory md:snap-none scroll-smooth',
            sliderClassName
          )}
          ref={node => (this.sliderRef = node)}
        >
          {leftPaddingPlaceholder && (
            <div ref={node => (this.leftPaddingPlaceholderRef = node)}>
              {leftPaddingPlaceholder}
            </div>
          )}

          {children.map((slide, index) =>
            React.cloneElement(typeof slide === 'function' ? slide(currentIndexes) : slide, {
              key: index,
              ref: node => {
                this.slidesRef[index] = node
              },
              // onClick: this.handleSlideClick(index),
            })
          )}
        </div>

        {arrows === 'side' && (
          <>
            {!scrollLeftEnd && (
              <ArrowLeftCircle
                onClick={this.scrollLeft}
                size={34}
                className={classnames('left-4', arrowsSideClasses)}
              />
            )}

            {!scrollRightEnd && (
              <ArrowRightCircle
                onClick={this.scrollRight}
                size={34}
                className={classnames('right-4', arrowsSideClasses)}
              />
            )}
          </>
        )}

        {(arrows === 'bottom' || dots) && (
          <div
            className={classnames(
              `flex flex-col justify-center items-center w-full mt-2 border-t border-gray-50`,
              arrowsClassName
            )}
          >
            {arrows === 'bottom' && (
              <div className="hidden flex-ns flex-row py-4">
                <ArrowLeft
                  onClick={this.scrollLeft}
                  size={24}
                  className={classnames(
                    'hidden md:block text-mirage transition-all bg-gray-50 rounded-full mr-1',
                    {
                      'cursor-pointer': !scrollLeftEnd,
                      'opacity-30': scrollLeftEnd,
                    }
                  )}
                />
                <ArrowRight
                  onClick={this.scrollRight}
                  size={24}
                  className={classnames(
                    'hidden md:block text-mirage transition-all bg-gray-50 rounded-full',
                    {
                      'cursor-pointer': !scrollRightEnd,
                      'opacity-30': scrollRightEnd,
                    }
                  )}
                />
              </div>
            )}

            {dots && (
              <div
                className={classnames('flex-row md:mt-2 py-4', {
                  flex: dots === 'always',
                  'flex dn-ns': dots === 'mobile',
                })}
              >
                {children.map((elem, i) => (
                  <div
                    key={i}
                    onClick={this.handleSlideClick(i)}
                    className={classnames('rounded-full border mr-1 transition-all pointer', {
                      'opacity-30': currentIndexes[currentIndexes.length - 1] !== i,
                    })}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
