import { MSkeleton } from '@molecules/MSkeleton'

export const OTodaySkeleton = () => (
  <div>
    <MSkeleton width={160} height={34} />
    <div className="flex mt-4 -mr-3 sm:-mr-5 lg:mr-0 mb-6 md:mb-10 pb-2 overflow-hidden">
      {[...Array(3).keys()].map(item => (
        <div
          key={item}
          className="flex flex-col justify-between w-[276px] h-[360px] lg:h-[380px] mr-2 last:mr-0 pt-2.5 px-2.5 md:px-4 pb-7 bg-white rounded-lg shadow-md"
        >
          <div className="flex items-center justify-start pt-4">
            <MSkeleton height={220} width={220} />
          </div>
          <MSkeleton height={28} width={220} />
        </div>
      ))}
    </div>
  </div>
)
