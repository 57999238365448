import { animateScroll } from './animateScroll'

const supportsSmoothScrolling = () => {
  const body = document.body
  const scrollSave = body.style.scrollBehavior
  body.style.scrollBehavior = 'smooth'
  const hasSmooth = getComputedStyle(body).scrollBehavior === 'smooth'
  body.style.scrollBehavior = scrollSave
  return hasSmooth
}

export const scrollBottom = element => {
  if (!element) {
    return console.error(`Invalid element, are you sure you've provided element?`)
  }

  const hasSmooth = supportsSmoothScrolling()

  const targetPosition =
    element === window
      ? document.body.scrollHeight - element.innerHeight
      : element.scrollHeight - element.offsetHeight

  if (targetPosition <= 0) return

  if (hasSmooth) {
    element.scrollTo({ top: targetPosition, left: 0, behavior: 'smooth' })
  } else {
    const initialPosition = element === window ? element.scrollY : element.scrollTop

    animateScroll({
      element,
      initialPosition,
      targetPosition,
    })
  }
}
