import { useCallback, useEffect } from 'react'

import { useIsBreakpoint } from '@hooks'

import { useSpring } from '@react-spring/web'

export const useInsightsAnimation = isModalOpen => {
  const isMobile = useIsBreakpoint(639)

  const [containerStyles, containerApi] = useSpring(() => ({
    bottom: 0,
    right: 0,
    opacity: 0,
    backgroundColor: '#F0CE57',
    borderRadius: 8,
    transform: 'translateX(0%) translateY(10%)',
  }))

  const [maskStyles, maskApi] = useSpring(() => ({
    opacity: 0,
    pointerEvents: 'none',
  }))

  const [bannerStyles, bannerApi] = useSpring(() => ({
    opacity: 1,
    pointerEvents: 'auto',
  }))

  const [chatStyles, chatApi] = useSpring(() => ({
    opacity: 0,
    pointerEvents: 'none',
  }))

  const openBannerAnimation = useCallback(() => {
    containerApi?.start({
      opacity: 1,
      transform: 'translateX(0%) translateY(0%)',
    })
    maskApi?.start({
      opacity: 0.4,
      pointerEvents: 'auto',
    })
  }, [containerApi, maskApi])

  const handleChangeBannerToModal = useCallback(() => {
    maskApi?.start({
      opacity: 1,
      pointerEvents: 'auto',
    })
    bannerApi?.start({
      opacity: 0,
      pointerEvents: 'none',
    })
    chatApi?.start({
      opacity: 1,
      pointerEvents: 'auto',
    })
  }, [bannerApi, chatApi, maskApi])

  const handleModalOnMobile = useCallback(() => {
    containerApi?.start({
      inset: '0% 0% 0% 0%',
      maxWidth: 'auto',
      backgroundColor: '#F8F4E6',
      borderRadius: 0,
    })
    handleChangeBannerToModal()
  }, [handleChangeBannerToModal, containerApi])

  const handleModalOnDesktop = useCallback(() => {
    containerApi?.start({
      inset: '10% 15% 20% 15%',
      margin: '0 auto',
      maxWidth: 680,
      backgroundColor: '#F8F4E6',
      borderRadius: 8,
    })
    handleChangeBannerToModal()
  }, [handleChangeBannerToModal, containerApi])

  const openModalAnimation = useCallback(() => {
    if (isMobile) {
      handleModalOnMobile()
    } else {
      handleModalOnDesktop()
    }
  }, [isMobile, handleModalOnMobile, handleModalOnDesktop])

  const closeBannerAnimation = useCallback(() => {
    containerApi?.start({
      opacity: 0,
      transform: 'translateX(0%) translateY(60%)',
    })
    maskApi?.start({
      opacity: 0,
      pointerEvents: 'none',
    })
  }, [containerApi, maskApi])

  const closeChatAnimation = useCallback(() => {
    containerApi?.start({
      opacity: 0,
    })

    maskApi?.start({
      opacity: 0,
      pointerEvents: 'none',
    })
  }, [containerApi, maskApi])

  useEffect(() => {
    if (!isModalOpen) {
      return
    }

    if (isMobile) {
      handleModalOnMobile()
    } else {
      handleModalOnDesktop()
    }
  }, [isMobile, isModalOpen, handleModalOnDesktop, handleModalOnMobile])

  return {
    styles: {
      container: containerStyles,
      mask: maskStyles,
      banner: bannerStyles,
      chat: chatStyles,
    },
    animation: {
      openBanner: openBannerAnimation,
      openModal: openModalAnimation,
      closeBanner: closeBannerAnimation,
      closeChat: closeChatAnimation,
    },
    setBannerInsetValue: val => {
      containerApi?.start({ inset: val })
    },
  }
}
