export default {
  APP_OPEN: 'App OPEN',
  VIEWED_PAGE: 'Viewed Page', // Name Fire on every page view

  // Auth
  SIGN_IN: '[AUTH] Sign in Successful',
  SIGN_IN_UNSUCCESSFUL: '[AUTH] Sign in Unsuccessful',
  SIGN_OUT: '[AUTH] Log-out',
  PASSWORD_RESET_REQUESTED: '[AUTH] Password Reset Requested',

  // Onboarding
  ONBOARDING_STARTED: '[ONBR] Onboarding Started',
  ONBOARDING_COMPLETED: '[ONBR] Onboarding Completed',

  // Home
  TODAY_MISSION_OPEN: '[TODAY]: Mission Open', // Title
  TODAY_MISSION_CLOSE: '[TODAY]: Mission Close', // Title
  TODAY_MISSION_COMPLETED: '[TODAY]: Mission Completed', // Title
  TODAY_TOPIC_COMPLETED: '[TODAY] Topic Completed', // Title

  // Progress
  PROGRAM_TOPIC_OPEN: '[PRGRS] Topic Open', // Title,
  PROGRAM_TOPIC_CLOSE: '[PRGRS] Topic Close', // Title,
  PROGRAM_MISSION_OPEN: '[PRGRS] Mission Open', // Title
  PROGRAM_MISSION_CLOSE: '[PRGRS] Mission Close', // Title
  PROGRAM_MISSION_COMPLETED: '[PRGRS] Mission Completed', // Title
  PROGRAM_TOPIC_COMPLETED: '[PRGRS] Topic Completed', // Title

  // Messages
  MESSAGES_CONVERSATION_OPEN: '[MSG] Conversation Open',
  MESSAGES_SEND_MESSAGE: '[MSG] Message Sent',
  MESSAGES_SEND_MEDIA_MESSAGE: '[MSG] Media Message Sent',
  MESSAGES_MESSAGE_READ: '[MSG] Message Read',

  // Account
  ACCOUNT_RESET_PASSWORD: '[ACCT] Password Reset Requested',
}
