import { useEffect } from 'react'
import { useIsOnline } from '@hooks/useIsOnline'

import useStore from '@store/useStore'

export const useUserQueue = (isSimulatedProgress = false) => {
  const isOnline = useIsOnline()

  const processQueue = useStore(state => state.processQueue)
  const requestsQueue = useStore(state => state.requestsQueue)

  useEffect(() => {
    if (isOnline && !isSimulatedProgress) {
      processQueue()
    }
  }, [processQueue, requestsQueue, isOnline, isSimulatedProgress])
}
