// Sanity Image transformations docs: https://www.sanity.io/docs/image-urls
const POSSIBLE_PARAMETERS = [
  {
    name: 'quality',
    key: 'q',
  },
  {
    name: 'crop',
    key: 'rect',
  },
  {
    name: 'hotspot',
    key: 'fit=crop&crop',
  },
  {
    name: 'width',
    key: 'w',
  },
  {
    name: 'height',
    key: 'h',
  },
  {
    name: 'blur',
    key: 'blur',
  },
]

const DEFAULT_OPTIONS = {
  quality: 100,
}

const getImageDimensions = url => {
  const regex = /\d{1,}\x\d{1,}/

  return url.match(regex)[0].split('x')
}

const getCropParams = (crop, imageDimensions) => {
  const [imageWidth, imageHeight] = imageDimensions

  if (crop) {
    const { top, bottom, left, right } = crop
    const insetY = top + bottom
    const insetX = left + right

    const rectProps = [
      Math.round(left * imageWidth),
      Math.round(top * imageHeight),
      Math.round(imageWidth - insetX * imageWidth),
      Math.round(imageHeight - insetY * imageHeight),
    ]

    return rectProps.join(',')
  }

  return `0,0,${imageWidth},${imageHeight}`
}

const getHotspotParams = hotspot => {
  const focalPoint = hotspot || {
    x: 0.5,
    y: 0.5,
  }

  return `focalpoint&fp-x=${focalPoint.x}&fp-y=${focalPoint.y}`
}

const paramsBuilder = (imageDimensions, options) => {
  let parameters = []

  Object.keys(options).forEach(option => {
    const optionValue = options[option]
    const key = POSSIBLE_PARAMETERS.find(item => item.name === option)?.key

    let value
    if (option === 'crop') {
      value = getCropParams(optionValue, imageDimensions)
    } else if (option === 'hotspot') {
      value = getHotspotParams(optionValue)
    } else {
      value = optionValue
    }

    parameters.push(`${key}=${value}`)
  })

  return parameters.join('&')
}

const urlBuilder = (url, params) => `${url}?${params}`

export const getSanityImage = (src, options = {}) => {
  if (!src) return ''

  const imageDimensions = getImageDimensions(src)
  const urlParams = paramsBuilder(imageDimensions, {
    ...DEFAULT_OPTIONS,
    ...options,
  })

  return urlBuilder(src, urlParams)
}
