import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import enUS from 'date-fns/locale/en-US'
import { DateTime } from 'luxon'

import { useGoalProvider } from '@edwin/react-sdk'

import DatePicker, { registerLocale } from 'react-datepicker'
import { AButton } from '../atoms/AButton'

registerLocale('en-US', enUS)

const getRoundedDate = () => {
  const date = new Date()

  const minutes = date.getMinutes()

  const roundedMinutes = minutes < 30 ? 0 : 30

  date.setMinutes(roundedMinutes)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

export const MChatDateTimePicker = ({ text, ctaText = 'Set notification', context, mode }) => {
  const { onSendHiddenMsg = () => {} } = useGoalProvider()

  const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
    <button className={className} onClick={onClick} ref={ref}>
      {value}
    </button>
  ))

  const [isSelected, setIsSelected] = useState(false)

  const [componentDate, setComponentDate] = useState(getRoundedDate())

  const minDate = useMemo(() => {
    return DateTime.now().startOf('day').toJSDate()
  }, [])

  const handleDateChange = useCallback(date => {
    setComponentDate(date)
  }, [])

  const handleChooseOption = useCallback(() => {
    setIsSelected(true)

    if (mode === 'time') {
      const hours = componentDate.getHours()
      const minutes = componentDate.getMinutes()

      return onSendHiddenMsg(`${hours}:${minutes}`)
    }

    onSendHiddenMsg(componentDate)
  }, [componentDate, mode, onSendHiddenMsg])

  return (
    <div className="py-3 px-3.5">
      <h4 className="text-lg font-p22">{text}</h4>
      {context && <p className="mt-2 text-sm text-gray-600">{context}</p>}

      <div className="flex mt-4">
        {mode === 'date' && (
          <DatePicker
            selected={componentDate}
            onChange={handleDateChange}
            disabled={isSelected}
            locale="en-US"
            closeOnScroll={e => e.target === document}
            minDate={minDate}
            customInput={
              <ExampleCustomInput className="py-1.5 px-2 text-sm border border-green-dark border-r-0 rounded-lg rounded-r-none" />
            }
            popperPlacement="bottom-start"
          />
        )}
        {mode === 'time' && (
          <DatePicker
            selected={componentDate}
            onChange={handleDateChange}
            disabled={isSelected}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="h:mm aa"
            locale="en-US"
            closeOnScroll={e => e.target === document}
            customInput={
              <ExampleCustomInput className="py-1.5 px-2 text-sm border border-green-dark border-r-0 rounded-lg rounded-r-none" />
            }
            popperPlacement="bottom-start"
          />
        )}
        <AButton
          onClick={handleChooseOption}
          outline={!isSelected}
          small
          disabled={isSelected}
          className={classNames(
            '!py-1.5 !px-2 !rounded-lg !rounded-l-none',
            isSelected && '!opacity-100'
          )}
        >
          {ctaText}
        </AButton>
      </div>
    </div>
  )
}
