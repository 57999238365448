import React from 'react'

import { useTranslations } from '@hooks'

import ReactMarkdown from 'react-markdown'
import { AButton } from '@atoms/AButton'
import { OModal } from '@organisms/OModal'

export const MChatActionConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  body = 'Are you sure?',
}) => {
  const { t } = useTranslations()

  return (
    <OModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirm}>
      <div>
        <div className="text-2xl font-medium">{title}</div>

        <ReactMarkdown className="mt-2 text-sm">{body}</ReactMarkdown>

        <div className="flex items-center justify-end mt-4">
          <AButton primary={false} onClick={onClose}>
            {t('cancel')}
          </AButton>
          <AButton primary onClick={onConfirm}>
            {t('confirm')}
          </AButton>
        </div>
      </div>
    </OModal>
  )
}
