import { edwinCloudApiClient } from '@services/EdwinCloudApiClient'

import { createProgressStore } from '@edwin/stores'

export const useProgressStore = createProgressStore({
  sessionStorage: window.localStorage,
  apiClient: edwinCloudApiClient,
})

export default useProgressStore
