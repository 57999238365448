import React, { useCallback, useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import { AButton } from '@atoms/AButton'

export const MInsightsPrompt = ({ onPromptClick }) => {
  const [currentClickedPrompt, setCurrentClickedPrompt] = useState()
  const [isPromptLoading, setIsPromptLoading] = useState(false)

  const handlePromptClick = useCallback(
    async prompt => {
      setCurrentClickedPrompt(prompt.id)
      setIsPromptLoading(true)

      try {
        await onPromptClick(prompt)
      } catch (err) {
        console.error(err)
      } finally {
        setCurrentClickedPrompt(null)
        setIsPromptLoading(false)
      }
    },
    [onPromptClick, setCurrentClickedPrompt, setIsPromptLoading]
  )

  const prompts = useMemo(() => {
    const today = DateTime.now()
    const endOfYesterday = today.minus({ days: 1 }).endOf('day')
    const startOfLastWeek = endOfYesterday.minus({ days: 6 }).startOf('day')
    const startOfPreviousWeek = startOfLastWeek.minus({ days: 7 })

    const dateValues = {
      lastWeek: {
        startsOn: startOfLastWeek.toISO(),
        endsOn: endOfYesterday.toISO(),
      },
      previousWeek: {
        startsOn: startOfPreviousWeek.toISO(),
        endsOn: startOfLastWeek.minus({ milliseconds: 1 }).toISO(), // One millisecond before lastWeek starts
      },
    }

    const values = [
      {
        name: 'Less walking',
        lastWeek: {
          ...dateValues.lastWeek,
          steps: 42001,
          sleep: 7.42,
          water: 7.2,
          lessons: 4,
        },
        previousWeek: {
          ...dateValues.previousWeek,
          steps: 78431,
          sleep: 8.02,
          water: 7.8,
          lessons: 4,
        },
      },
      {
        name: 'Less tracking',
        lastWeek: { ...dateValues.lastWeek, steps: 62001, sleep: 7.52, water: 7.6, lessons: 4 },
        previousWeek: {
          ...dateValues.previousWeek,
          steps: 59431,
          sleep: 8.02,
          water: 7.8,
          lessons: 4,
        },
      },
      {
        name: 'Increase on sleep',
        lastWeek: { ...dateValues.lastWeek, steps: 62001, sleep: 7.52, water: 7.7, lessons: 5 },
        previousWeek: {
          ...dateValues.previousWeek,
          steps: 59431,
          sleep: 6.42,
          water: 8.1,
          lessons: 5,
        },
      },
    ]

    const data = values.map((value, index) => ({
      values: { lastWeek: value.lastWeek, previousWeek: value.previousWeek },
      id: index,
      name: value.name,
      text: `LAST WEEK PROGRESS on: steps, lessons, sleep, hydration:
      - ${value.lastWeek.steps} steps
      - ${value.lastWeek.sleep} hours of sleep on average daily
      - ${value.lastWeek.water} cups of water on average daily
      - ${value.lastWeek.lessons} lessons completed

      PREVIOUS WEEK PROGRESS on: steps, lessons, sleep, hydration:
      - ${value.previousWeek.steps} steps
      - ${value.previousWeek.sleep} hours of sleep on average daily
      - ${value.previousWeek.water} cups of water on average daily
      - ${value.previousWeek.lessons} lessons completed
      `,
    }))

    return data
  }, [])

  return (
    <div className="w-full flex flex-col space-y-4 p-4">
      {prompts.map(prompt => {
        return (
          <AButton
            key={prompt.id}
            onClick={() => handlePromptClick(prompt)}
            isLoading={isPromptLoading && currentClickedPrompt === prompt.id}
          >
            {prompt.name}
          </AButton>
        )
      })}
    </div>
  )
}
