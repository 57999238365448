import { useEffect } from 'react'
import { useIsOnline } from '@hooks/useIsOnline'

import useGoalsStore from '@store/useGoalsStore'

export const useGoalsQueue = (isSimulatedProgress = false) => {
  const isOnline = useIsOnline()

  const processQueue = useGoalsStore(state => state.processQueue)
  const requestsQueue = useGoalsStore(state => state.requestsQueue)

  useEffect(() => {
    if (isOnline && !isSimulatedProgress) {
      processQueue()
    }
  }, [processQueue, requestsQueue, isOnline, isSimulatedProgress])
}
