import { useCallback, useEffect, useState, useRef } from 'react'

export const useVisibilityChange = ({ onVisible, onHidden }) => {
  const [visibilityState, setVisibilityState] = useState(document.visibilityState)
  const isVisibilityStateInitialized = useRef(false)

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState)

    if (document.visibilityState === 'visible' && onVisible) {
      onVisible()
    }

    if (document.visibilityState === 'hidden' && onHidden) {
      onHidden()
    }
  }, [onVisible])

  useEffect(() => {
    if (!isVisibilityStateInitialized.current) {
      handleVisibilityChange()
      document.addEventListener('visibilitychange', handleVisibilityChange)

      isVisibilityStateInitialized.current = true
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [handleVisibilityChange])

  return { visibilityState }
}
