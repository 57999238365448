const EMPTY_DATA = []

const SCENARIO_1 = [
  {
    id: 3,
    componentType: 'tips',
    title: 'Daily Tips',
    description: 'Here are some tips to help you stay healthy and reach your weight loss goals:',
    componentMeta: {
      title: 'Daily Tips',
      description: 'Here are some tips to help you stay healthy and reach your weight loss goals:',
      tips: [
        {
          id: 1,
          title: 'Prioritize Protein at Every Meal',
          description:
            'Protein keeps you feeling full and helps maintain muscle mass, which is crucial for a healthy metabolism. Include protein sources like lean meats, fish, beans, or tofu at breakfast, lunch, and dinner.',
        },
        {
          id: 2,
          title: 'Focus on Fiber-Rich Foods',
          description:
            'Fiber helps regulate digestion and keeps you feeling satisfied. Choose whole grains, fruits, vegetables, and legumes to boost your fiber intake.',
        },
        {
          id: 3,
          title: 'Stay Active Your Way',
          description:
            "Find activities that you enjoy and that fit your abilities. Whether it's chair yoga, wheelchair aerobics, adaptive swimming, or simply stretching, any movement counts!",
        },
      ],
    },
  },
  {
    id: 2,
    componentType: 'progress',
    title: 'Progress',
    description: 'Your Journey So Far',
    componentMeta: {
      description:
        "### You're on the right track!\n\n" +
        '- **Seated Strength Training:** 💪\n' +
        '\t- Started incorporating basic exercises\n' +
        '- **Nutrition:** 🍎\n' +
        '\t- Learning about healthy food choices\n' +
        '- **Mindset:** ✨\n' +
        '\t- Building positive habits\n\n' +
        "Remember, this is just the beginning! Celebrate every small step, and don't hesitate to ask for support whenever you need it.",
    },
  },
  {
    id: 5,
    componentType: 'trackers',
    title: 'Track Your Goals',
    description: 'Monitor Your Progress',
    componentMeta: {
      title: "Today's Goals",
      goals: [
        {
          id: 1,
          title: 'Which meal today will you focus on making a healthy choice?',
          description: 'Pick one meal to prioritize a nutritious option.',
          type: 'text',
          typeMeta: {
            predefinedValues: [
              { id: 1, title: '🍳 Breakfast' },
              { id: 2, title: '🥪 Lunch' },
              { id: 3, title: '🍲 Dinner' },
            ],
          },
        },
        {
          id: 2,
          title: 'Describe your physical activity for today.',
          description:
            'Any movement counts - be it stretching, exercise, or simply moving around more.',
          type: 'text',
          typeMeta: {
            isUserInput: true,
          },
        },
        {
          id: 3,
          title: 'Did you drink at least 6 glasses of water today?',
          description: 'Staying hydrated is key!',
          type: 'boolean',
        },
      ],
    },
  },
  {
    id: 4,
    componentType: 'missions',
    title: 'Getting Started Missions',
    componentMeta: {
      description: 'Learn the Basics of Weight Loss',
      missions: [
        {
          id: 1,
          title: 'Building a Balanced Plate',
          description:
            'Learn how to create nutritious meals with the right balance of protein, carbohydrates, and healthy fats.',
        },
        {
          id: 2,
          title: 'Staying Active with a Disability',
          description:
            'Explore different types of exercise and find activities that work for you and your body.',
        },
      ],
    },
  },
  {
    id: 1,
    componentType: 'goals',
    title: 'Goal Suggestion',
    description: 'Set Your First Goals',
    componentMeta: {
      title: 'Goal Suggestion',
      description: 'Set Your First Goals',
      suggestions: [
        {
          id: 1,
          title: 'Track Daily Water Intake',
          description: 'Set a goal for how many glasses of water you want to drink each day.',
        },
        {
          id: 2,
          title: 'Schedule 30 Minutes of Activity',
          description: 'Choose an activity you enjoy and schedule it into your daily routine.',
        },
      ],
    },
  },
]

const SCENARIO_2 = [
  {
    id: 1,
    componentType: 'progress',
    title: 'Progress',
    componentMeta: {
      description:
        '### Fantastic progress despite the challenges! 🎉\n\n' +
        '- **Seated Strength Training:** 💪💪💪💪\n' + // 4 out of 5 muscle emojis
        '\t- Increased reps by 15%, even with your finger injury – amazing!\n' +
        '- **Wheelchair Aerobics:** 🚴🚴🚴🚴\n' + // 4 out of 5 bike emojis
        '\t- Maintaining 30 minutes daily – way to keep up the momentum!\n' +
        '- **Mindful Eating:** 🍎🍎🍎\n' + // 3 out of 5 apple emojis
        '\t- Consistently logging meals\n' +
        '- **Hydration:** 💧💧💧💧\n' + // 4 out of 5 water drop emojis
        '\t- Staying well-hydrated\n\n' +
        "It's so inspiring to see how you're adapting to your broken finger! Remember, setbacks are just opportunities for new strategies. We'll navigate this together. Keep pushing forward, you've got this! 💪",
    },
  },
  {
    id: 2,
    componentType: 'missions',
    title: `Today's Mission`,
    componentMeta: {
      description:
        "You're currently working on two missions. Let's tackle them together!\n\n" +
        "**Remember:** You missed yesterday's mission. It's still important, so don't forget to catch up!\n\n" +
        "We've also swapped one mission to better fit your current needs. This new mission is designed to help you stay on track while still feeling challenged.",
      missions: [
        {
          id: 1,
          title: 'Navigating Healthy Eating on a Budget (Missed)', // This was yesterday's mission
          description:
            "Let's explore strategies for making nutritious choices that won't break the bank. We'll cover tips for meal planning, smart shopping, and maximizing leftovers.",
        },
        {
          id: 2, // New mission
          title: 'Core and Cardio Blast: Chair-Based Workout',
          description:
            "Let's focus on strengthening your core and getting your heart pumping all from the comfort of your chair. No weights or special equipment required!",
        },
      ],
    },
  },
  {
    id: 3,
    componentType: 'goals',
    title: 'Goal Suggestion',
    description:
      "Let's keep the momentum going! Based on your great progress, here are some goals you can try this week:",
    componentMeta: {
      suggestions: [
        {
          id: 1,
          title: 'Track your daily vegetable intake.',
          description:
            'Which meals did you include a serving of vegetables in today? (Breakfast, Lunch, Dinner)',
          type: 'text',
          typeMeta: {
            predefinedValues: [
              { id: 1, title: '🍳 Breakfast' },
              { id: 2, title: '🥪 Lunch' },
              { id: 3, title: '🍲 Dinner' },
              { id: 4, title: 'All meals' },
              { id: 5, title: 'None' },
            ],
          },
        },
        {
          id: 2,
          title: 'Describe your wheelchair aerobics or other exercise routine today.',
          description: 'How did it go? How did you feel?',
          type: 'text',
          typeMeta: {
            isUserInput: true,
          },
        },
        {
          id: 3,
          title: 'Did you meet your daily water intake goal today?',
          description: 'Track your hydration success!',
          type: 'boolean',
        },
      ],
    },
  },
  {
    id: 4,
    componentType: 'tips',
    title: 'Quick Tips',
    description: 'Here are a couple of simple tips to keep you on track:',
    componentMeta: {
      title: 'Quick Tips',
      description: 'Here are a couple of simple tips to keep you on track:',
      tips: [
        {
          id: 1,
          title: 'Keep Healthy Snacks Within Reach',
          description:
            'Prep some cut-up veggies, fruit, or nuts to have on hand when cravings strike. This makes it easier to make healthy choices.',
        },
        {
          id: 2,
          title: 'Set Realistic Goals',
          description:
            'Start small and gradually increase your goals over time. Celebrate each milestone, no matter how small!',
        },
      ],
    },
  },
]

const SCENARIO_3 = [
  {
    id: 3,
    componentType: 'tips',
    componentMeta: {
      title: 'Daily Tips',
      description: 'Here are some tips to help you stay healthy',
      tips: [
        {
          id: 1,
          title: 'Hydrate like a boss!',
          description:
            "8 glasses a day keeps dehydration away. Water isn't just a drink—it's your energy boost, your focus enhancer. Guzzle up, stay sharp, conquer the day!",
        },
        {
          id: 2,
          title: 'Boost your plate, boost your life!',
          description:
            '5 servings of fruits and veggies a day for a vitamin-packed punch. Fuel up, feel alive, thrive!',
        },
        {
          id: 3,
          title: 'Recharge your batteries naturally!',
          description:
            'Aim for 7-8 hours of quality shut-eye each night to let your body recover and rejuvenate. Sleep well, live well, conquer tomorrow!',
        },
      ],
    },
  },
  {
    id: 5,
    componentType: 'trackers',
    title: 'Track you current goals',
    componentMeta: {
      title: "List of today's goals",
      goals: [
        {
          id: 1,
          title: 'Which of your meals today did you choose a healthy option? ',
          description:
            'Let your coach know which of the daily meals did you make a healthy choice.',
          type: 'text',
          typeMeta: {
            predefinedValues: [
              { id: 1, title: '🍳 Breakfast' },
              { id: 2, title: '🥪 Lunch' },
              { id: 3, title: '🍲 Dinner' },
            ],
          },
        },
        {
          id: 2,
          title: 'Write out your highlights of the day.',
          description: 'Creating a journal entry.',
          type: 'text',
          typeMeta: {
            isUserInput: true,
          },
        },
        {
          id: 3,
          title: 'Did you make your bed this morning?',
          description:
            'Making your bed daily provides a sense of accomplishment and sets a positive tone for the day, fostering a tidier and more organized living space.',
          type: 'boolean',
        },
      ],
    },
  },
  {
    id: 4,
    componentType: 'missions',
    title: 'Overdue missions',
    description: 'You have some overdue missions to complete',
    componentMeta: {
      missions: [
        {
          id: 1,
          title: 'Importance of Hydration',
          description:
            'In this mission, we aim to educate and empower you about the crucial role hydration plays in overall health and well-being.',
        },
        {
          id: 2,
          title: 'How GLP-1 medications work for weight loss',
          description:
            'Understanding what GLP-1 medications are: Exploring the basics and the nature of these medications.',
        },
      ],
    },
  },
]

export const scenarios = [
  { id: 0, name: 'Empty state', data: EMPTY_DATA },
  { id: 1, name: 'Components Walkthrough', data: SCENARIO_1 },
  { id: 2, name: 'Progress and goal suggestions', data: SCENARIO_2 },
  { id: 3, name: 'Tips and trackers', data: SCENARIO_3 },
]

export const DEFAULT_SCENARIO = SCENARIO_1
