import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useChatEngine } from '@edwin/react-sdk'

import { nanoid } from 'nanoid/non-secure'

export const useMessages = ({
  initialMessages,
  completionMeta,
  onUserAction,
  onFinish,
  onExit,
  onProgress,
  onStepUpdated,
  onQuestion,
  onAssistant,
  triggerConfetti = () => {},
  isNewMessagesLoading = false,
  continuousWaitFlag = true,
}) => {
  const finishProps = useRef(null)
  const [messages, setMessages] = useState([])

  const typingIndicatorMeta = useMemo(() => {
    return {
      id: nanoid(),
      renderId: nanoid(),
      type: 'bot-component',
      componentName: 'loading',
      // first: true,
      // componentOptions: {
      //   emojiLoader: true,
      // },
    }
  }, [])

  const handleOnFinish = useCallback((msgs, oaths, questions) => {
    finishProps.current = { msgs, oaths, questions }
  }, [])

  const engineMessages = useChatEngine({
    initialMessages,
    onUserAction,
    onProgress,
    onFinish: handleOnFinish,
    onExit,
    onStepUpdated,
    onQuestion,
    onAssistant,
    continuousWaitFlag,
  })

  const handleTypingIndicator = useCallback(() => {
    setMessages(prev => {
      const modifiedMsgs = [
        ...prev.filter(msg => msg.componentName !== 'loading'),
        ...(isNewMessagesLoading ? [typingIndicatorMeta] : []),
      ]

      return modifiedMsgs
    })
  }, [isNewMessagesLoading, typingIndicatorMeta])

  useEffect(() => {
    const lastEngineMessage = engineMessages[engineMessages.length - 1]

    if (lastEngineMessage) {
      setMessages(prev => {
        const lastMessage = prev[prev.length - 1]

        if (!!lastMessage && lastMessage.id === lastEngineMessage.id) {
          return prev
        }

        const modifiedPrev = prev.filter(msg => msg.componentName !== 'loading')

        if (lastEngineMessage.end && lastEngineMessage.type === 'user-options') {
          return [
            ...modifiedPrev,
            {
              ...lastEngineMessage,
              onFinish: () => {
                const { msgs = [], oaths = [], questions = [] } = finishProps?.current

                if (onFinish instanceof Function) {
                  onFinish(msgs, oaths, questions)
                }
              },
            },
          ]
        }

        if (lastEngineMessage.end && lastEngineMessage.type === 'bot') {
          return [
            ...modifiedPrev,
            {
              ...lastEngineMessage,
              ...(lastEngineMessage.end && {
                onRender: () => {
                  setMessages(prev => [
                    ...prev,
                    {
                      id: 'custom-end-msg',
                      renderId: 'custom-end-msg',
                      type: 'bot-component',
                      componentName: 'congratulations',
                      componentOptions: {
                        triggerConfetti,
                        completionMeta,
                        onFinish: () => {
                          const { msgs = [], oaths = [], questions = [] } = finishProps?.current

                          if (onFinish instanceof Function) {
                            onFinish(msgs, oaths, questions)
                          }
                        },
                      },
                    },
                  ])
                },
              }),
            },
          ]
        }

        return [...modifiedPrev, lastEngineMessage]
      })
    }
  }, [completionMeta, engineMessages, onFinish, triggerConfetti])

  useEffect(() => {
    handleTypingIndicator()
  }, [handleTypingIndicator])

  return messages
}
