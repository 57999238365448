import { useMemo } from 'react'
import useProgressStore from '@store/useProgressStore'

import { useTranslations } from '@hooks'

export const useSelectedTopic = topicId => {
  const { translateData } = useTranslations()

  const getTopic = useProgressStore(state => state.getTopic)
  const getTopicProgress = useProgressStore(state => state.getTopicProgress)
  const getMission = useProgressStore(state => state.getMission)

  const userProgress = useProgressStore(state => state.userProgress)

  const selectedTopic = useMemo(() => {
    const topic = getTopic(topicId)
    const translatedTopic = translateData(topic)
    const topicProgress = getTopicProgress(topicId)

    const missionsAndSeries = topic?.missionsAndSeries.map(mission => {
      const missionData = getMission(mission.id)
      const translatedMission = translateData(missionData)
      const missionProgressFromTopic = topicProgress?.missionsAndSeries.find(
        ({ id }) => id === mission.id
      )

      return {
        topicId: topic.id,
        ...translatedMission,
        ...missionProgressFromTopic,
      }
    })

    return {
      ...translatedTopic,
      ...topicProgress,
      missionsAndSeries,
    }
  }, [getMission, getTopic, getTopicProgress, topicId, userProgress, translateData])

  return selectedTopic
}
