import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export function AButton({
  children,
  small,
  className,
  outline,
  micro,
  disabled,
  large,
  onClick,
  dontRound,
  isLoading,
  ...props
}) {
  const cls = classNames(
    'text-sm transition-all focus:outline-none focus:ring-1 focus:ring-green-dark',
    small ? 'py-2 px-4' : 'py-2 px-8',
    outline && !isLoading
      ? 'border bg-transparent border-codGray'
      : 'text-white bg-green-dark focus:ring-offset-1 focus:text-white focus:bg-green-dark',
    disabled
      ? 'opacity-50 cursor-default'
      : 'opacity-100 md:hover:text-white md:hover:bg-green-dark cursor-pointer',
    dontRound ? 'rounded-[10px]' : 'rounded-full',
    className
  )

  return (
    <button className={cls} {...props} onClick={onClick} disabled={disabled || isLoading}>
      {isLoading ? 'Loading...' : children}
    </button>
  )
}

AButton.propTypes = {
  small: PropTypes.bool,
  className: PropTypes.string,
  outline: PropTypes.bool,
  micro: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  onClick: PropTypes.func,
  dontRound: PropTypes.bool,
}
