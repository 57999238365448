import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

export const MInnerZoomFullscreenPortal = ({ children }) => {
  const [portal] = useState(() => {
    const el = document.createElement('div')

    el.classList.add('fixed inset-0 z-[10000]')

    return el
  })

  useEffect(() => {
    document.body.appendChild(portal)

    return () => document.body.removeChild(portal)
  }, [portal])

  return createPortal(children, portal)
}

MInnerZoomFullscreenPortal.propTypes = {
  children: PropTypes.element,
}
