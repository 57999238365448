import React, { useState, useCallback, useMemo, useEffect } from 'react'
import classnames from 'classnames'

import AnimateHeight from 'react-animate-height'
import ReactMarkdown from 'react-markdown'
import { ChevronDown } from 'react-feather'
import { AAnimatedText } from '../atoms/AAnimatedText'

export const MObjectives = ({ objectives }) => {
  const [height, setHeight] = useState(36)
  const [isObjectiveHistoryOpen, setIsObjectiveHistoryOpen] = useState(false)

  const lastObjective = useMemo(() => {
    return objectives?.[objectives.length - 1]
  }, [objectives])

  const objectivesWithoutLastObjective = useMemo(() => {
    return objectives.slice(0, objectives.length - 1).reverse()
  }, [objectives])

  const isMoreThanOneObjective = useMemo(() => {
    return objectives.length > 1
  }, [objectives])

  const toggleObjectiveHistory = useCallback(() => {
    setIsObjectiveHistoryOpen(prev => !prev)
  }, [])

  useEffect(() => {
    if (isObjectiveHistoryOpen) {
      setHeight('auto')
    } else {
      setHeight(36)
    }
  }, [isObjectiveHistoryOpen])

  if (objectives.length === 0) {
    return null
  }

  return (
    <AnimateHeight
      duration={300}
      height={height}
      onClick={toggleObjectiveHistory}
      className={classnames(
        'fixed top-[52px] inset-x-0 animate-fade-in opacity-0 lg:hidden z-50',
        isMoreThanOneObjective && 'cursor-pointer'
      )}
    >
      <div className="flex items-center h-[36px] px-2.5 bg-green">
        <div className="flex flex-col w-full h-full text-sm truncate">
          <AAnimatedText text={lastObjective?.label} />
        </div>
        {isMoreThanOneObjective && (
          <div
            className={classnames(
              'absolute bottom-0 right-0 ml-2 py-2 px-2 animate-fade-in opacity-0 transition-transform duration-300',
              isObjectiveHistoryOpen && 'rotate-180'
            )}
          >
            <ChevronDown size={18} />
          </div>
        )}
      </div>
      <div>
        <div className="bg-green border-t border-gray-300">
          {objectivesWithoutLastObjective.map((objective, index) => {
            return (
              <div
                key={objective?.id || index}
                className={classnames(
                  'py-2 px-2.5 border-b border-gray-300 text-sm',
                  index === objectives.length - 1 && 'border-b-0'
                )}
              >
                <ReactMarkdown
                  components={{
                    p: ({ node, ...props }) => <p {...props} className="truncate" />,
                  }}
                >
                  {objective.label}
                </ReactMarkdown>
              </div>
            )
          })}
        </div>
      </div>
    </AnimateHeight>
  )
}
